// tasks, steps, entries
export const handleTaskNameChange = (id, evt, user, setUser) => {
  const newTasks = user.jobTasks.map((task) => {
    if (id !== task.ID) return task;
    return { ...task, jobTasksTask: evt.target.value };
  });
  setUser({ ...user, jobTasks: newTasks });
};

export const handleTaskPropChange = (id, type, evt, user, setUser) => {
  if (evt) {
    const newTasks = user.jobTasks.map((task) => {
      if (id !== task.ID) return task;
      return {
        ...task,
        [type]:
          type == "jobTasksEffort"
            ? evt.target.value
            : evt.target.value === "true",
      };
    });
    setUser({ ...user, jobTasks: newTasks });
  }
};

export const handleAddTask = (user, setUser) => {
  var res = Math.min.apply(
    Math,
    user.jobTasks.map((task) => {
      return task.ID;
    })
  );
  setUser({
    ...user,
    jobTasks: user.jobTasks.concat([
      { jobTasksTask: "", user_id: user.ID, ID: Math.min(-1, res - 1) },
    ]),
  });
};

export const handleRemoveTask = (id, user, setUser) => {
  const newTasks = user.jobTasks.map((task) => {
    if (id !== task.ID) return task;
    return { ...task, deleted: true };
  });
  setUser({ ...user, jobTasks: newTasks });
};

// Steps
// TODO: Raise and lower steps
export const handleStepNameChange = (id, evt, user, setUser) => {
  const newSteps = user.reqSteps.map((step) => {
    if (id !== step.ID) return step;
    return { ...step, reqStepsStep: evt.target.value };
  });
  setUser({ ...user, reqSteps: newSteps });
};

export const handleStepPropChange = (id, type, evt, user, setUser) => {
  if (evt) {
    const newSteps = user.reqSteps.map((step) => {
      if (id !== step.ID) return step;
      return {
        ...step,
        [type]: evt.target.value,
      };
    });
    setUser({ ...user, reqSteps: newSteps });
  }
};

export const handleAddStep = (user, setUser) => {
  var res = Math.min.apply(
    Math,
    user.reqSteps.map((step) => {
      return step.ID;
    })
  );
  setUser({
    ...user,
    reqSteps: user.reqSteps.concat([
      { reqStepsStep: "", user_id: user.ID, ID: Math.min(-1, res - 1) },
    ]),
  });
};

export const handleRemoveStep = (id, user, setUser) => {
  const newSteps = user.reqSteps.map((step) => {
    if (id !== step.ID) return step;
    return { ...step, deleted: true };
  });
  setUser({ ...user, reqSteps: newSteps });
};

// Entries
export const handleEntryNameChange = (id, evt, user, setUser) => {
  const newEntries = user.entries.map((step) => {
    if (id !== step.ID) return step;
    return { ...step, entriesName: evt.target.value };
  });
  setUser({ ...user, entries: newEntries });
};

export const handleEntryPropChange = (id, type, evt, user, setUser) => {
  if (evt) {
    const newEntries = user.entries.map((step) => {
      if (id !== step.ID) return step;
      return {
        ...step,
        [type]: evt.target.value,
      };
    });
    setUser({ ...user, entries: newEntries });
  }
};

// Negative numbers mean new entries = POST
export const handleAddEntry = (user, setUser) => {
  // get the smallest ID
  var res = Math.min.apply(
    Math,
    user.entries.map((step) => {
      return step.ID;
    })
  );
  setUser({
    ...user,
    entries: user.entries.concat([
      // set new id to -1, -2, -3 etc
      {
        entriesName: "",
        entriesDescription: "",
        entriesDate: new Date().toISOString().substring(0, 10),
        user_id: user.ID,
        ID: Math.min(-1, res - 1),
      },
    ]),
  });
  return Math.min(-1, res - 1);
};

export const handleRemoveEntry = (id, user, setUser) => {
  const newEntries = user.entries.map((step) => {
    if (id !== step.ID) return step;
    return { ...step, deleted: true };
  });
  setUser({ ...user, entries: newEntries });
};

// Responses
export const handleResponse = ({ target: { value, name } }, user, setUser) => {
  if (value === "true" || value === "false") {
    setUser({
      ...user,
      responses: { ...user.responses, [name]: value === "true" },
    });
  } else {
    setUser({ ...user, responses: { ...user.responses, [name]: value } });
  }
};
