import { Navigate } from "react-router-dom";

function SignOut({ setToken, setUser }) {
  setToken();
  localStorage.removeItem("token");
  setUser();
  return <Navigate to="/" />;
}

export default SignOut;
