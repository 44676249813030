import { Link } from "react-router-dom";

function Learn() {
  return (
    <div>
      <h2>Section 11</h2>
      <h3>What if my request is denied?</h3>
      <p>
        The reasonable accommodation process is intended to be interactive, with
        both the employee and employer working together to select the best
        solution for both parties. However, sometimes an employer will deny an
        accommodation request.
      </p>
      <p>
        When a request is denied, the first step is to ask for an explanation
        for why it was denied. Employers are not required to provide a written
        explanation, but it doesn’t hurt to ask since having it in writing can
        be important documentation. If the employer does not provide the reason
        in writing, take down notes, if you can, of what they tell you when
        explaining why it was denied. This information will help you decide
        about further steps.
      </p>
      <p>
        If you think a request was denied because the employer didn't have all
        the necessary information, appeal (make a request that the decision be
        reconsidered) the denial and offer additional information supporting
        your request. Some companies have a formal appeal process, while in
        others you will need to work informally through HR or your supervisor to
        find out how to appeal.
      </p>
      <p>An employer may deny a request for several reasons:</p>
      <ol>
        <li>
          The employer may not believe you are a ‘qualified individual with a
          disability.’ For example:
          <ol type="a">
            <li>
              The employer may not believe you have the medical condition or
              impairment you claim.
            </li>
            <li>
              The employer may not believe you are able to complete your
              essential duties even with an accommodation.
            </li>
          </ol>
        </li>
        <li>
          The employer may not see the relationship between the accommodation
          you requested and the impairment you have.
        </li>
        <li>
          The employer may believe that providing the necessary accommodation
          for you is an ‘undue burden.’ Undue burden is a legal term that means
          providing the accommodation would result in significant difficulty or
          expense.
        </li>
      </ol>
      <p>
        In each of these cases, you will need to consider whether these concerns
        reflect an actual disagreement or a simple lack of information. If there
        is an actual disagreement, you will have to decide whether you want to
        try to negotiate for a different accommodation or fight for the
        accommodation you requested. This is not an easy decision, and it may be
        useful to revisit{" "}
        <Link to="/before/section6/learn">
          Section 6: Personal risk tolerance.
        </Link>
      </p>
      <p>
        If you decide you want to fight, you may want to find support from a
        legal or advocacy organization. Every state has a Protection & Advocacy
        (P&A) organization that can provide legal information. You can search
        for yours on the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://askjan.org/concerns/Local-Protection-and-Advocacy-P-A-and-Client-Assist-Programs-CAP.cfm"
        >
          JAN website
        </a>
        . Your state P&A maybe be able to help you figure out the best path
        forward.
      </p>
      <p>
        Finally, if none of the above works, you may decide to file an
        employment discrimination complaint. Employment discrimination
        complaints under the ADA are filed with the U.S. Equal Employment
        Opportunity Commission (EEOC). The EEOC issues, enforces, and provides
        technical assistance on the ADA Title I employment regulations. You can
        find more information at the link below.
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.eeoc.gov/filing-charge-discrimination"
          >
            Filing a charge of discrimination with the EEOC
          </a>
        </li>
      </ul>
      <p>
        It is important to know what you are getting into when submitting a
        complaint. It could take months to get a response, and it is possible
        that the EEOC will decline to investigate. Often people are no longer in
        the job where they had the conflict when they finally reach resolution.
        However, even complaints that do not get resolved in your (an
        employee’s) favor alert an employer to issues in the workplace. Some
        people decide to pursue filing a complaint in order to potentially
        improve conditions for future employees with disabilities, even if
        conditions do not improve for themselves.
      </p>
    </div>
  );
}

export default Learn;
