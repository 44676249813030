import React from "react";
import { Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

const SignUpAlert = () => {
  return (
    <Alert style={{ marginTop: "10px" }} variant="secondary">
      <Alert.Heading>You're not signed in!</Alert.Heading>
      <p>
        <Link to="/signup">Sign Up</Link> or <Link to="/signin">Sign In</Link>{" "}
        to complete this activity!
      </p>
      <hr />
      <div className="d-flex justify-content-end">
        <Link to="/signup">
          <Button variant="outline-secondary">Sign Up</Button>
        </Link>
        <Link to="/signin">
          <Button variant="outline-secondary" style={{ marginLeft: "5px" }}>
            Sign In
          </Button>
        </Link>
      </div>
    </Alert>
  );
};

export default SignUpAlert;
