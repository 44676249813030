import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";

function SignIn({ setToken, setUser }) {
  const [values, setValues] = useState({ email: "", password: "" });
  const [feedback, setFeedback] = useState();
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const onInput = ({ target: { value, name } }) =>
    setValues((oldValues) => ({ ...oldValues, [name]: value }));

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);
    e.preventDefault();

    const sendData = {
      email: values.email,
      password: values.password,
    };

    let formData = new FormData();
    for (var key in sendData) {
      formData.append(key, sendData[key]);
    }

    const response = await fetch(
      "https://depts.washington.edu/adakt/ra-tool/url.php/login",
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.status >= 300) {
      const error = await response.json();
      console.log(error);
      setFeedback({
        heading: "Error signing in!",
        text:
          "Code " +
          error.code +
          ": " +
          error.message +
          (error.code == 1012 &&
            ". Make sure your email address and password are correct."),
        status: error.code,
      });
      return;
    }
    const res = await response;
    const user = await res.json();
    if (user.error) {
      console.log(user.error);
      setFeedback({
        heading: "Error signing in!",
        text: user.error,
        status: 200,
      });
      return;
    }
    setToken(user.apikey);
    setUser({ ...user, apikey: user.apikey });

    const handleData = async (endpoint) => {
      const response = await fetch(
        "https://depts.washington.edu/adakt/ra-tool/api.php" + endpoint,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "X-Api-Key": user.apikey,
          }),
        }
      );
      if (response.status >= 300) {
        const error = await response.json();
        console.log(error);
        setFeedback({
          heading: "Error signing in!",
          text: "Code " + error.code + ": " + error.message,
          status: error.code,
        });
        return;
      }
      const values = await response.json();
      return values;
    };

    let responses = await handleData("/records/responses");
    let tasks = await handleData("/records/jobTasks");
    let steps = await handleData("/records/reqSteps");
    let ents = await handleData("/records/entries");

    const userData = {
      ...user,
      responses: responses.records[0],
      jobTasks: tasks.records,
      reqSteps: steps.records,
      entries: ents.records,
    };
    setUser(userData);
    navigate("/");
  };

  return (
    <React.Fragment>
      <h1>Sign In</h1>
      <Form
        style={{ margin: "0 auto", maxWidth: "30em" }}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            required
            type="email"
            name="email"
            placeholder="Enter email"
            onChange={(e) => onInput(e)}
            value={values.email}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid email address.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            name="password"
            onChange={(e) => onInput(e)}
            value={values.password}
            placeholder="Password"
          />
          <Form.Control.Feedback type="invalid">
            Please enter a password.
          </Form.Control.Feedback>
        </Form.Group>
        <Button variant="primary" type="submit">
          Sign In
        </Button>
        <p style={{ paddingTop: "16px", fontSize: "0.8em" }}>
          <Link to="/pwreset">Forgot Password</Link>
        </p>
      </Form>

      {feedback && (
        <Alert
          style={{ marginTop: "1em" }}
          variant={feedback.status >= 300 ? "danger" : "success"}
          onClose={() => setFeedback(null)}
          dismissible
        >
          <Alert.Heading>{feedback.heading}</Alert.Heading>
          <p>{feedback.text}</p>
        </Alert>
      )}
    </React.Fragment>
  );
}

export default SignIn;
