import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document
      .querySelector(".root-layout > div > div:last-child")
      .scrollTo(0, 0);
  }, [pathname]);
}
