import MyFunctionsPDF from "./PDFs/MyFunctionsPDF.js";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Button from "react-bootstrap/Button";

const MyFunctions = ({ user }) => {
  return (
    <div>
      <p>When we asked what functions you perform at your job, you said:</p>
      <ul>
        {user.jobTasks.map((task) => {
          return (
            <li>
              {task.jobTasksTask}. You said this is{" "}
              {!(
                task.jobTasksTime === true ||
                task.jobTasksOthers === true ||
                task.jobTasksExpertise === true
              ) && "not "}
              an essential function of your job.
            </li>
          );
        })}
      </ul>
      <p>
        When we asked if you met the prerequisites of your job, you said that
        you did{!user.responses.b3aPrerequisites && " not"}.
      </p>
      <p>
        When we asked if you are able to perform the essential functions of you
        job with or without reasonable accommodations, you said that you could
        {!user.responses.b3aPerform && " not"}.
      </p>

      <PDFDownloadLink
        document={<MyFunctionsPDF user={user} />}
        fileName="MyFunctions.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            <Button variant="outline-primary" disabled>
              Loading PDF...
            </Button>
          ) : (
            <Button variant="outline-primary">Download PDF</Button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export default MyFunctions;
