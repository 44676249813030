import React from "react";
import NextButton from "./modules/NextButton";
import { Link } from "react-router-dom";

const Conclusion = () => {
  return (
    <React.Fragment>
      <h3>Conclusion: Next Steps</h3>
      <p>
        You have reached the end of the reasonable accommodation decision
        support aid, we hope it has helped you prepare a strong request for
        reasonable accommodation.
      </p>
      <p>
        In the future, you may move to another job and need to make a request
        for accommodation in a different employment setting. You can always
        return to this decision aid for support. If you visit the{" "}
        <Link to="/settings">Settings</Link> page you can reset the tool so you
        can start again with new information.
      </p>
      <p>
        Remember that you can always visit the{" "}
        <Link to="/reports">Reports</Link> page to view and print the
        information you entered into the decision aid.
      </p>
      <NextButton text="Return to Home Page" to="/" />
    </React.Fragment>
  );
};

export default Conclusion;
