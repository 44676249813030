import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import getValues from "../../GetValues.js";
import sendValues from "../../SendValues.js";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import SignUpAlert from "../../SignUpAlert.js";

function Reflect({ authToken, user, setUser, setFeedback }) {
  const handleTaskPropChange = (id, type, evt) => {
    if (evt) {
      const newTasks = user.jobTasks.map((task) => {
        if (id !== task.ID) return task;
        return { ...task, [type]: evt.target.value };
      });
      setUser({ ...user, jobTasks: newTasks });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(user.jobTasks, authToken, "/records/jobTasks")
    );
    const nTasks = await getValues(authToken, "/records/jobTasks");
    setUser({
      ...user,
      jobTasks: nTasks,
    });
  };

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h3>Reflect: Which reasonable accommodation(s) would be best for me?</h3>
      <p>
        Now that you have learned more about possible accommodations you can
        refine your ideas about what types of accommodations would work best for
        you in completing your job tasks. Below are the tasks you identified as
        difficult to complete without accommodation. Open each dropdown to
        describe challenges completing this task due to disability and to
        identify types of accommodations that could make the task easier. Be as
        specific as you can in identifying what the challenging elements of each
        task are due to having a disability. Then, brainstorm broadly about what
        accommodations would enable you to perform that task more efficiently or
        easily. Write out any other considerations you want to keep in mind,
        such as if you anticipate needing to adjust or change accommodations
        over time. This is information that is only for you, so be as thorough
        and honest as you can!
      </p>
      {!user ? (
        <SignUpAlert />
      ) : !user.jobTasks[0] ? (
        <p>
          Complete <Link to="/before/section3/reflect">Section 3 Reflect</Link>{" "}
          to fill out this section
        </p>
      ) : (
        <Form
          style={{ margin: "0 auto", maxWidth: "30em" }}
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-3">
            <Accordion flush>
              {user.jobTasks.map((t, i) => {
                return (
                  <Accordion.Item
                    key={t.jobTasksTask + i}
                    eventKey={t.jobTasksTask + i}
                  >
                    <Accordion.Header>{t.jobTasksTask}</Accordion.Header>
                    <Accordion.Body>
                      <Form.Label>
                        Challenges completing this task due to disability:
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name={t.jobTasksTask + "challenges"}
                        onChange={(evt) => {
                          handleTaskPropChange(t.ID, "jobTasksChallenges", evt);
                        }}
                        value={t.jobTasksChallenges ?? ""}
                      />
                      <br />
                      <Form.Label>
                        Brainstorm broadly about accommodations that would
                        enable you to perform this task more easily.
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name={t.jobTasksTask + "easier"}
                        onChange={(evt) => {
                          handleTaskPropChange(t.ID, "jobTasksEasier", evt);
                        }}
                        value={t.jobTasksEasier ?? ""}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </Form.Group>
        </Form>
      )}
    </div>
  );
}

export default Reflect;
