import Navbar from "react-bootstrap/Navbar";
import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineQuestionCircle, AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <AiOutlineUser
      aria-labelledby="Your Profile"
      style={{
        color: "white",
        fontSize: "max(4.5vh, 35px)",
        marginRight: "5px",
        cursor: "pointer",
      }}
    />
  </a>
));

const Header = ({ user, toggleSidebar }) => {
  return (
    <div>
      <Navbar
        bg="dark"
        expand="xs"
        variant="dark"
        aria-labelledby="Main Navigation Bar"
      >
        <div>
          <GiHamburgerMenu
            aria-labelledby="Toggle Sidebar"
            style={{
              color: "white",
              fontSize: "max(5.7vh, 40px)",
              paddingLeft: "10px",
              marginRight: "5px",
              cursor: "pointer",
            }}
            onClick={() => toggleSidebar()}
          />
          <Navbar.Brand
            as={Link}
            to="/"
            style={{
              fontSize: "min(max(2vh, 15px), calc(5.1vw - 0.8vh))",
              verticalAlign: "middle",
            }}
          >
            Reasonable Accommodation Requests
          </Navbar.Brand>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifySelf: "end",
            alignItems: "center",
            marginRight: "2px",
          }}
        >
          <Link to="/help">
            <AiOutlineQuestionCircle
              aria-labelledby="Help"
              style={{ color: "white", fontSize: "max(4.5vh, 35px)" }}
            />
          </Link>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              Custom toggle
            </Dropdown.Toggle>

            <Dropdown.Menu align="end">
              {user ? (
                <>
                  <Dropdown.Header>{user.email}</Dropdown.Header>
                  <Dropdown.Item as={Link} to="/reports" eventKey="1">
                    Reports
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/settings" eventKey="1">
                    Settings
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/signout" eventKey="1">
                    Sign Out
                  </Dropdown.Item>
                </>
              ) : (
                <>
                  <Dropdown.Header>
                    Sign in or register to save your progress!
                  </Dropdown.Header>
                  <Dropdown.Item as={Link} to="/signup" eventKey="1">
                    Sign Up
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/signin" eventKey="1">
                    Sign In
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
