import { styles, ListItem } from "./StylesPDF.js";
import {
  Document,
  Page,
  View,
  Text,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";

const MyDiaryPDF = ({ user }) => (
  <Document>
    <Page style={styles.body} size="LETTER">
      <Text style={styles.title}>My Journal</Text>
      {user.entries
        .filter((entryd) => {
          return entryd.deleted != true;
        })
        .sort((a, b) => {
          return new Date(a.entriesDate) > new Date(b.entriesDate);
        })
        .map((e) => {
          return (
            <View>
              <Text style={styles.h3}>{e.entriesName}</Text>
              <Text style={styles.h4}>{e.entriesDate}</Text>
              <Text style={styles.text}>{e.entriesDescription}</Text>
            </View>
          );
        })}
    </Page>
  </Document>
);

export default MyDiaryPDF;
