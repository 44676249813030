/*import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React from "react";
import sendValues from "../../SendValues.js";*/
import { Link } from "react-router-dom";

function Apply({ authToken, user, setUser }) {
  /*const onInput = ({ target: { value, name } }) => {
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendValues(user, authToken, "b1r");
  };*/

  return (
    <div>
      <h3>Apply: How can I request a different RA?</h3>
      <p>
        If you have determined that you need a different or additional
        accommodation in your workplace, you will basically need to follow the
        same steps you did to request your first accommodation. Refer to{" "}
        <Link to="/before/section4/learn/">Section 4</Link> of the tool where
        you can begin thinking about what changes you need to your
        accommodations. Note, that you can choose to{" "}
        <Link to="/settings/">reset this tool</Link> and start over. However,
        it’s a good idea to download all your current reports before you do
        that.
      </p>
      {!user ? <h2>Sign in to complete this activity!</h2> : null}
    </div>
  );
}

export default Apply;
