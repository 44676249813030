import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

function Learn() {
  return (
    <div>
      <h1>Before the request</h1>
      <h2>Section 1</h2>
      <h3>Learn: What is disability?</h3>
      <p>
        As we noted in the introduction, the ADA requires that you disclose
        disability when you request a reasonable accommodation. Disability might
        be a new concept to you, or it may be something you are very comfortable
        with. This section helps you think about your assumptions and beliefs
        about disability.
      </p>
      <p>
        Disability can be defined in many ways. There are cultural definitions
        of disability and there are legal definitions of disability. Different
        definitions exist because there are groups who need to define disability
        for a specific purpose, including government agencies whose task is to
        provide employment support, health care officials who track public
        health trends, and civil rights groups focused on protecting people from
        discrimination. For example, to qualify for Social Security disability
        benefits you must be unable to work. Under the Americans with
        Disabilities Act (ADA), however, disability is defined more broadly. The
        ADA takes into consideration activities, including work that a person
        can do with and without accommodations, and recognizes that people with
        disabilities often face <strong>discrimination</strong> due to negative
        stereotypes and assumptions about being disabled.
      </p>
      <Card className="popout">
        <Card.Body>
          <Card.Text>
            <strong>Discrimination</strong> is the act of making unjustified
            distinctions between people based on the groups to which they belong
            or are perceived to belong.
          </Card.Text>
        </Card.Body>
      </Card>
      <p>
        People with disabilities under the ADA include individuals with a broad
        range of conditions, diagnoses, impairments, and identities, including
        people who use assistive devices like wheelchairs, people with chronic
        illnesses such as Crohn’s Disease or Multiple Sclerosis, people
        diagnosed as Autistic or neurodivergent, and those who identify as
        members of the American Sign Language linguistic group such as those in
        the Deaf community.
      </p>
      <Card className="popout">
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h4>Learn more about disability identity</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Identity formation has to do with how we make sense of the world
                and how we represent ourselves. Everyone has multiple identities
                that form around things like our culture, jobs, ethnicity,
                religion, gender, and age. Disability can be part of your
                identity, too. Disability identities are a way of making sense
                of the different ways your body or mind works and the stigma
                that society may attach to those differences. It can be helpful
                to think concretely about how you view yourself and how you
                identify around your disability.
              </p>
              <p>
                Disability identity can take many forms and there is not one
                ‘right way’ to think about disability. Some people may not
                identify with their disability at all, feeling mostly unhappy
                about their physical or mental state of being. They may feel
                that their disability has only negative impacts on their life.
              </p>
              <p>
                Some people acknowledge their condition but try to downplay it
                as just one element of who they are—but not the primary one.
                They may try to fit in and to minimize the effect of the
                disability—to be or appear as ‘normal’ as possible. They may
                view disability as just a small part of who they are.
              </p>
              <p>
                Others may think of their disability as playing a primary role
                in who they are. Their disability may be a big part of how they
                develop their social network, political activism, and cultural
                connections. They may develop a ‘disability pride’ perspective
                where they push against society’s negative views about
                disability and argue that disability is an important, positive
                part of social diversity rather than a negative limitation. They
                may point out that people living with disabilities are often
                innovative, adaptable, and find pride in having a unique
                perspective on the world. People who hold this view contrast a
                diversity perspective of disability with a deficit perspective
                of disability.
              </p>
              <p>
                How you develop a disability identity may differ based on
                several factors. One factor is whether your disability is
                immediately apparent or not to others, often referred to as
                ‘visible’ versus ‘invisible’ disability. People with invisible
                disabilities must choose to disclose their disability, while
                those with visible disabilities do not. How your identity
                develops may also depend on how old you were at the age you
                acquired the disability. People who have disability that
                develops early in life may have developed a positive identity
                related to disability, while for people who develop disability
                later in life, it may be difficult to integrate disability into
                how they think of themselves. Your disability identity might
                also differ based on your access to disability community. Living
                with disability can be challenging for a variety of reasons, and
                often connecting to others who understand your situation can be
                very useful. These individuals can serve as role models and
                social supports as you learn more about the experience of living
                with disability in our society. This may be especially true in
                the world of work. For example, people who have already been
                granted an accommodation to bring a service dog to work may have
                good tips on what information to share with colleagues about how
                to appropriately interact with a service animal; people who have
                a chronic illness may be able to suggest workplace
                accommodations that help them manage their fatigue during the
                workday. There are many groups formed around disability-specific
                issues as well as groups who focus more broadly on the
                experience of being a disabled worker today.
              </p>
              <p>
                All the above identities are valid and understandable, but it is
                important to note a few things. First, researchers have found
                that people who develop a more positive disability identity may
                have better quality of life. They may have lower depression and
                anxiety, a stronger sense of self-worth, acceptance and pride,
                and a greater sense of belonging. Second, identity changes over
                time. You may move toward embracing a disability identity or
                move away depending on the situation in which you find yourself.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
    </div>
  );
}

export default Learn;
