function Learn() {
  return (
    <div>
      <h2>Section 10</h2>
      <h3>
        Learn: What happens if I am offered a different RA than requested?
      </h3>
      <p>
        The reasonable accommodation process is intended to be interactive, with
        both the employee and employer working together to select the best
        solution for both parties. An employer who provides an accommodation has
        the right to choose what specific accommodation (e.g., schedule change,
        assistive device, software, etc.) they provide to meet the employee’s
        needs. It is important that an employee make a good faith effort to use
        the accommodation provided by the employer. Give yourself time and space
        to learn how to use the accommodation in your work. Seek out other
        resources, such as searching online for tutorials, discussions, or
        information about how others use the device or technology.
      </p>
      <p>
        However, if after a few months you find that the accommodation provided
        is not helping you to do your work more easily or effectively, this is
        important – and legitimate - feedback to provide to your HR manager or
        employer. If this is the case, you may want to start a follow up
        conversation with your employer and ask that they re-consider your
        request for a specific accommodation; or that they provide another,
        similar accommodation.
      </p>
    </div>
  );
}

export default Learn;
