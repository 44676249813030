import NextButton from "./modules/NextButton.js";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineQuestionCircle, AiOutlineUser } from "react-icons/ai";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";

function Home() {
  return (
    <div>
      <h1>Requesting Reasonable Accommodations in Employment</h1>
      <h2>A Decision Support Aid</h2>
      <h3>What does this decision support aid do?</h3>
      <p>
        This decision support aid will guide you through the process of
        requesting a reasonable accommodation when applying, interviewing, or
        continuing in employment. This aid helps you make the following
        decision:{" "}
        <strong>
          When and how should I request a reasonable accommodation in
          employment?
        </strong>
      </p>
      <Card className="popout">
        <Card.Body>
          <Card.Title>What is a reasonable accommodation?</Card.Title>
          <Card.Text>
            A reasonable accommodation is any change to the application or
            hiring process, to the job, to the way the job is done, or the work
            environment that allows a person with a disability who is qualified
            for the job to perform the essential functions of that job and enjoy
            equal employment opportunities.{" "}
            <em>
              <a
                href="https://adata.org/factsheet/reasonable-accommodations-workplace"
                target="_blank"
              >
                ADA National Network
              </a>
            </em>
          </Card.Text>
        </Card.Body>
      </Card>
      <Card className="examples">
        <Card.Body>
          <Card.Title>Get Started</Card.Title>
          <Card.Text>
            <Container fluid>
              <Row>
                <Col>
                  <p>
                    Visit the Help Section to learn more about this decision aid
                    by using the question mark in the main navigation bar.
                  </p>
                </Col>
                <Col sm={2} className="justify-content-center text-center">
                  <Link to="/help">
                    <AiOutlineQuestionCircle
                      style={{
                        color: "white",
                        backgroundColor: "#212529",
                        fontSize: "60px",
                      }}
                    />
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    Create an account by using the Your Profile icon in the main
                    navigation bar. We recommend you do this before starting the
                    decision support aid so you can complete the activities that
                    accompany some sections.
                  </p>
                </Col>
                <Col sm={2} className="justify-content-center text-center">
                  <Link to="/signup">
                    <AiOutlineUser
                      style={{
                        color: "white",
                        backgroundColor: "#212529",
                        fontSize: "60px",
                      }}
                    />
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    Activate the navigation bar and browse the decision aid by
                    using the ‘hamburger’ icon above.
                  </p>
                </Col>
                <Col sm={2} className="justify-content-center text-center">
                  <GiHamburgerMenu
                    style={{
                      color: "white",
                      backgroundColor: "#212529",
                      fontSize: "60px",
                    }}
                  />
                </Col>
              </Row>
              <p>Or just select "Next: Intro" below to start right away.</p>
            </Container>
          </Card.Text>
        </Card.Body>
      </Card>
      <NextButton text="Next: Intro" to="/intro" />
    </div>
  );
}

export default Home;
