import React from "react";
import EntryEditor from "../../EntryEditor.js";
import SignUpAlert from "../../SignUpAlert.js";

function Reflect({ user, setUser }) {
  return (
    <div>
      <h3>Reflect: Do I think I might need something different?</h3>
      <p>
        If you begin to suspect that you could be more productive with a
        different, or an additional accommodation, it is time to start
        documenting your experience with your current accommodation again and
        decide if you wish to request a different or additional workplace
        accommodation.
      </p>
      <p>
        As you did when you were first provided an accommodation in this work
        place, begin keeping a Workplace Journal for 2-4 weeks reflecting on how
        well the accommodation is working for you. Select a couple of days each
        week in which you spend most of your time doing typical tasks required
        of your job. On those days, jot down reflections like:
      </p>
      <ul>
        <li>
          Which of my work tasks are easier to complete (i.e., able to complete
          more quickly; able to complete with less pain, stress, worry, or
          exertion of effort; able to complete to my satisfaction) with the
          accommodation than without it?
        </li>
        <li>
          Which tasks are more difficult to complete with the accommodation?
        </li>
        <li>
          How have my abilities to complete my job tasks changed since I was
          first provided this accommodation?
        </li>
      </ul>
      <p>
        Use the Workplace Journal to help assess whether you would benefit from
        a different or additional accommodation.
      </p>
      {!user ? <SignUpAlert /> : <EntryEditor user={user} setUser={setUser} />}
    </div>
  );
}

export default Reflect;
