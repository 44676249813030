import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";

const Help = () => {
  return (
    <React.Fragment>
      <h2>Help</h2>
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>What is a decision aid?</Accordion.Header>
          <Accordion.Body>
            <p>
              Decision aids are tools that guide you through a set of options,
              provide information about the pros and cons of each option, ask
              about your preferences, and help you reach a decision. They guide
              you through complicated choices and are intended to help you make
              value-based tradeoffs between the benefits and harms of a
              decision.
            </p>
            <p>There is good evidence that people who use decision aids:</p>
            <ul>
              <li>Improve their knowledge of options.</li>
              <li>Are better informed.</li>
              <li>Have more clarity about their own values.</li>
              <li>
                Have expectations about benefits and harms that are more
                accurate.
              </li>
            </ul>
            <p>
              People appreciate decision aids both for their educational and
              informational content, and because they support greater personal
              involvement in decision-making.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Why would I want to use this decision aid?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              This decision aid helps you make the following decision:{" "}
              <strong>
                When and how should I request reasonable accommodations in
                employment?
              </strong>{" "}
              It will guide you through a series of steps to help you make
              decisions that are most appropriate for you and your circumstances
              in requesting a reasonable accommodation. It applies to
              pre-employment, (the application or interview stages), and during
              employment.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Who is this decision aid for?</Accordion.Header>
          <Accordion.Body>
            <p>
              This aid is for anyone trying to decide whether and how to make a
              request to an employer for a reasonable accommodation. It does not
              assume you have any background knowledge. But if you do, you can
              skip over sections.
            </p>
            <p>
              It may also be useful to professionals, like vocational
              counselors, who support people with disabilities in requesting
              workplace accommodations, or Human Resources specialists, who
              design and implement company policies regarding accommodation
              request processes.
            </p>
            <p>
              Finally, it may be useful to family or friends of someone with a
              disability who would like support in making a decision about
              requesting a reasonable accommodation.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            How is this decision aid organized?
          </Accordion.Header>
          <Accordion.Body>
            <p>This aid is organized around three stages:</p>
            <ol>
              <li>
                <strong>Before</strong> the reasonable accomodation request.
              </li>
              <li>
                <strong>During</strong> the reasonable accomodation request.
              </li>
              <li>
                <strong>After</strong> the reasonable accomodation request.
              </li>
            </ol>
            <p>In each stage, you will find sections where you can:</p>
            <ul>
              <li>
                <strong>Learn</strong> about the reasonable accommodation
                process.
              </li>
              <li>
                <strong>Reflect</strong> on your own unique situation.
              </li>
              <li>
                <strong>Apply</strong> what you have discovered to reach a
                decision.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>How do I navigate this website?</Accordion.Header>
          <Accordion.Body>
            <p>
              The navigation bar can be activated by clicking the ‘hamburger’
              icon in the upper right corner. Click the hamburger icon again to
              hide the navigation bar
            </p>
            <p>
              Each of the menu items is expandable (except Home and
              Introduction). Click the menu item to see a list of sections.
              Click the section to see the Learn, Reflect, Apply sections.
            </p>
            <p>
              You can use the navigation bar to move through the aid in order or
              to jump around to sections of interest. Each section also has a
              ‘Next’ button at the bottom of the page if you want to move
              through the aid in order.
            </p>
            <GiHamburgerMenu
              style={{
                color: "white",
                backgroundColor: "#212529",
                fontSize: "max(5.7vh, 40px)",
              }}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>How do I create an account?</Accordion.Header>
          <Accordion.Body>
            <p>
              Click the icon of a person at the top left. A drop down will
              provide options to ‘Sign Up’ or ‘Sign In.’
            </p>
            <Link to="/signup">
              <AiOutlineUser
                style={{
                  color: "white",
                  backgroundColor: "#212529",
                  fontSize: "max(4.5vh, 35px)",
                }}
              />
            </Link>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Do I need to create an account?</Accordion.Header>
          <Accordion.Body>
            <p>
              You are not required to create an account and you can use all the
              educational materials without creating an account. However, you
              need an account if you want to use the decision aid to record and
              save information about your disability, your job, and your
              accommodations and then create reports that can help you make a
              request for reasonable accommodation. Your account is password
              protected and all passwords are saved via non-reversable
              encryption.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            Is the information I enter in this decision aid kept confidential?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              This decision aid asks you to reflect and write about issues that
              may be sensitive and private. All information gathered within this
              tool is private and confidential and will not be shared with
              others unless you specifically grant access.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>
            Who developed this decision aid and how do I reference it?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              This decision aid was developed under a grant from the National
              Institute on Disability, Independent Living, and Rehabilitation
              Research (NIDILRR grant number 90RTEM0001). The grant funded the
              Rehabilitation Research and Training Center (RRTC) at the Shirley
              Ryan Ability Lab in Chicago. The RRTC included a partnership with
              researchers at the University of Washington where the decision aid
              was created.
            </p>
            <h4>Recommended Citation</h4>
            <p>
              Harniss, M., Evans, H., & Batalov, P. (2023). Requesting
              reasonable accommodations in employment: A decision support aid.{" "}
              <Link to="/">http://pavelbat.ds.lib.uw.edu/</Link>
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </React.Fragment>
  );
};

export default Help;
