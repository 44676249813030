import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";

const Learn = ({ setFeedback }) => {
  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h2>Section 5</h2>
      <h3>
        Learn: What are risks of disability disclosure and how can I assess the
        risks within my workplace?
      </h3>
      <p>
        Unfortunately, there could be negative consequences to telling an
        employer that you would be more productive with a reasonable
        accommodation. Individual supervisors may have never thought about
        workplace accommodations and may be uncertain how to have a conversation
        about reasonable accommodations with an employee. Such a conversation
        may raise questions about your overall ability to do your job.
      </p>
      <p>
        Additionally, some individuals hold negative stereotypes or may make
        false assumptions about a person just for using the term “disability”
        when describing their health. Research shows that many people with
        disabilities continue to face discrimination and stigma in the
        workplace. It is important to point out that an individual may hold
        negative views of people with disabilities on either (or both) the
        conscious or unconscious levels (these are referred to as explicit and
        implicit bias).
      </p>
      <p>
        In some cases, disclosing a disability may expose you to stigma and
        discrimination that can lead to negative experiences ranging from
        frustration and irritation with co-workers or supervisors’ behavior to
        being unfairly rejected from a candidate pool for a job you want or
        fired from a job that you already have. Although it is illegal to fire
        an individual (or behave in a discriminatory manner at any stage of
        employment from application to promotion), it costs both time and energy
        to file a complaint with the Equal Employment Opportunity Commission
        (EEOC) or to hire a private attorney to combat disability
        discrimination. Steps for responding to workplace disability-based
        discrimination is discussed later in this tool at [link to After the
        Request module on complaints.]
      </p>
      <Card className="popout">
        <Card.Body>
          <strong>Stigma</strong>: is the disapproval of or set of negative
          assumptions about a person who belongs to a group thought to be
          inherently inferior compared to others.
        </Card.Body>
      </Card>
      <Card className="popout">
        <Card.Body>
          The U.S. Equal Employment Opportunity Commission (EEOC) is responsible
          for enforcing federal laws that make it illegal to discriminate
          against a job applicant or an employee because of the person's race,
          color, religion, sex (including pregnancy, transgender status, and
          sexual orientation), national origin, age (40 or older), disability or
          genetic information. To learn more about the EEOC, visit their{" "}
          <a target="_blank" rel="noreferrer" href="https://www.eeoc.gov/">
            website
          </a>
          .
        </Card.Body>
      </Card>
      <p>
        Understanding the context of your work environment can help guide the
        way you approach having a conversation about reasonable accommodations
        with your employer. While you do not have control over the prejudices
        other individuals may hold, there are some ways to help figure out if
        your workplace has policies in place to make it easy to request an
        accommodation or that intentionally minimize disability discrimination
        and stigma in the company.
      </p>
      <p>
        Human Resource professionals often talk about the “workplace climate” at
        a particular job. Basically, workplace climate refers to how employees
        feel about their workplace—is it safe, is it fair, is it respectful, and
        so on.
        <a
          target="_blank"
          rel="noreferrer"
          href="https://adata.org/research_brief/research-brief-workplace-culture-and-ada"
        >
          {" "}
          [Click here to read more on what experts have learned about work
          climate]
        </a>
      </p>
      <p>
        If you are already working in a job, it is wise to try get a sense of
        how “disability friendly” your workplace already is. Pay attention to
        things like:
      </p>
      <ul>
        <li>
          Other employees who are clearly using disability accommodations.
        </li>
        <li>
          Anecdotes or stories from work colleagues about their own experiences
          negotiating accommodations in this workplace.
        </li>
        <li>
          Presence of company-based affinity groups, or employee resource groups
          that bring together employees with similar backgrounds or interests,
          based on disability or health.
        </li>
        <li>
          Company postings (on bulletin boards, company website, or pamphlets
          provided to everyone) with a clear company statement outlining how to
          request a reasonable accommodation (such as, who specifically to talk
          to, what information you should provide, a timeline or set of steps
          for the process).
        </li>
      </ul>
      <p>
        If you have not yet been hired, assessing the workplace climate is more
        challenging. However, you can still do an online search for materials
        about the company that may reflect the workplace climate, including
        browsing company newsletters, reading worker testimonials, or searching
        for policies on reasonable accommodations posted on a company website.
        If none of these materials are available, it is not unusual to request
        to speak with a couple of current employees, especially those that do
        the same or a similar job to which you will be doing, to get of sense of
        the workplace culture.
      </p>
      <Card className="popout">
        <Card.Body>
          <Card.Title>Examples From Proactive Employers:</Card.Title>
          <Card.Text>
            <p>
              <q>
                We heavily advertise that if you need some help doing your job
                to please reach out to us… and then we start the process. So, we
                ask four or five questions, and we ask them to go consult with
                their doctor, and then come back to us and then we have the
                conversation.
              </q>
              <p>– Nancy, HR Specialist</p>
            </p>
            <p>
              <q>
                We have a post-offer health assessment form that candidates fill
                out, and on there is a specific question [about] requesting an
                accommodation… Part of my role for on-boarding assessment is to
                review that and if they answer yes to accommodation requests,
                then that opens up further dialogue as to what their needs are
                and how it fits with the essential functions of the job and
                further dialogue.
              </q>
              <p>– Mary, HR Specialist</p>
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Learn;
