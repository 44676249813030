function Learn() {
  return (
    <div>
      <h2>Section 12</h2>
      <h3>Learn: What happens if later I need a different or additional RA?</h3>
      <p>
        Everyone experiences changes in their work abilities and capacities over
        time. People living with disabilities often have changes in abilities
        that are directly related to changes in their disability. Thus we
        encourage you to periodically reassess if the accommodation currently in
        place is meeting your needs.
      </p>
      <p>
        There is no legal restriction on or limit to the number of
        accommodations an employee can request or that an employer has to
        consider providing to an otherwise qualified employee under the ADA.
        However, keep in mind that all accommodations must be “reasonable” and
        cannot cause “undue hardship” on the employer. In many cases, several
        small adjustments to a workplace or work schedule can have a large
        impact on a person’s productivity, so communicating changing needs due
        to disability to your employer is a legitimate and important practice.
      </p>
      <p>
        It can be easier to discuss changing needs if you have a regular
        ‘check-in’ about your accommodation scheduled with your HR manager,
        supervisor, or employer. For example, incorporating discussion of your
        accommodation into periodic meetings such as during annual performance
        reviews is a great way to have ongoing conversations about how well your
        accommodation is (or is not) working. Such meetings are also an
        opportunity to potentially raise the issue of other or different
        accommodation needs, should they arise.
      </p>
      <p>
        In general, preparing for performance review (if regularly conducted) is
        an excellent time to re-evaluate your accommodation needs in the
        workplace!
      </p>
    </div>
  );
}

export default Learn;
