import MyAccommodationsPDF from "./PDFs/MyAccommodationsPDF.js";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Button from "react-bootstrap/Button";

const MyAccommodations = ({ user }) => {
  return (
    <div>
      <p>
        When we asked what accommodations you could use, you said:
        <ul>
          {user.jobTasks.map((task) => {
            return (
              (task.jobTasksSolution && task.jobTasksSolution != "" && (
                <li>{task.jobTasksTask + ": " + task.jobTasksSolution}</li>
              )) ||
              (task.jobTasksEasier && task.jobTasksEasier != "" && (
                <li>{task.jobTasksTask + ": " + task.jobTasksEasier}</li>
              ))
            );
          })}
        </ul>
      </p>
      <PDFDownloadLink
        document={<MyAccommodationsPDF user={user} />}
        fileName="MyAccommodations.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            <Button variant="outline-primary" disabled>
              Loading PDF...
            </Button>
          ) : (
            <Button variant="outline-primary">Download PDF</Button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export default MyAccommodations;
