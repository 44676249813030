import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import sendValues from "../../SendValues.js";
import SignUpAlert from "../../SignUpAlert.js";

function Reflect({ authToken, user, setUser, setFeedback }) {
  const onInput = ({ target: { value, name } }) => {
    if (name.includes("Notes")) {
      setUser({ ...user, responses: { ...user.responses, [name]: value } });
    } else {
      setUser({
        ...user,
        responses: { ...user.responses, [name]: Number(value) },
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
  };

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  const questions = [
    "I have the resources to deal with any financial risk.",
    "I have the social support resources to help me through negative times.",
    "I have personal characteristics that make me resilient and able to deal with negative interactions and outcomes.",
  ];

  const names = ["Finances", "Support", "Tolerance"];

  const SurveyNode = (text, num) => {
    return (
      <React.Fragment key={num}>
        <Form.Group className="mb-3">
          <Form.Label>{text}</Form.Label>
          <Form.Check
            type="radio"
            name={"b6r" + names[num]}
            id={"b6r" + names[num] + "1"}
            onChange={(e) => onInput(e)}
            value={1}
            label="Strongly disagree"
            checked={user.responses["b6r" + names[num]] == 1}
          />
          <Form.Check
            type="radio"
            name={"b6r" + names[num]}
            id={"b6r" + names[num] + "2"}
            onChange={(e) => onInput(e)}
            value={2}
            label="Disagree"
            checked={user.responses["b6r" + names[num]] == 2}
          />
          <Form.Check
            type="radio"
            name={"b6r" + names[num]}
            id={"b6r" + names[num] + "3"}
            onChange={(e) => onInput(e)}
            value={3}
            label="Neutral"
            checked={user.responses["b6r" + names[num]] == 3}
          />
          <Form.Check
            type="radio"
            name={"b6r" + names[num]}
            id={"b6r" + names[num] + "4"}
            onChange={(e) => onInput(e)}
            value={4}
            label="Agree"
            checked={user.responses["b6r" + names[num]] == 4}
          />
          <Form.Check
            type="radio"
            name={"b6r" + names[num]}
            id={"b6r" + names[num] + "5"}
            onChange={(e) => onInput(e)}
            value={5}
            label="Strongly agree"
            checked={user.responses["b6r" + names[num]] == 5}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Notes or reflections:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name={"b6r" + names[num] + "Notes"}
            onChange={(e) => onInput(e)}
            value={user.responses["b6r" + names[num] + "Notes"]}
          />
        </Form.Group>
      </React.Fragment>
    );
  };

  return (
    <div>
      <h3>Reflect: Understanding your personal risk tolerance</h3>
      <p>
        In this section, spend some time reflecting on whether you are ready to
        take a risk by disclosing your disability and requesting a reasonable
        accommodation.
      </p>
      <ol>
        <li>
          <strong>Your financial resources</strong>—how much of a financial
          buffer do you have? Could you deal with a job loss, job transition, or
          extended leave while you file a discrimination claim?
        </li>
        <li>
          <strong>Your social support resources</strong>—do you have friends and
          family who provide you with good support? People who will support you
          and share your challenges? Would they understand issues related to
          disability discrimination you may face?
        </li>
        <li>
          <strong>Your personal risk tolerance</strong>—are you a confident,
          assertive person? Are you willing to take a stand and make your case?
          Do you feel strongly that you have a right to request a reasonable
          accommodation as a person with a disability? Do you think you can deal
          with potential negative comments or behaviors from your supervisor and
          coworkers without letting it bother you too much?
        </li>
      </ol>
      {!user ? (
        <SignUpAlert />
      ) : (
        <Form
          style={{ margin: "0 auto", maxWidth: "30em" }}
          onSubmit={handleSubmit}
        >
          {questions.map((q, i) => {
            return SurveyNode(q, i);
          })}
        </Form>
      )}
    </div>
  );
}

export default Reflect;
