import { Link } from "react-router-dom";
import ACL from "./img/acl.png";
import NIDILRR from "./img/nidilrr.png";
import Image from "react-bootstrap/Image";

function Footer() {
  return (
    <div
      style={{
        backgroundColor: "#E9DED8",
        paddingTop: "10px",
        paddingBottom: "13px",
        paddingInline: "5px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ paddingTop: "20px" }}>
        <Link to="/grant">Grant Disclaimer</Link> |{" "}
        <Link to="/accessibility">Accessibility</Link>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          float: "right",
        }}
      >
        <a href="https://acl.gov/" target="_blank">
          <Image
            style={{ height: "29px", marginTop: "17px", paddingRight: "5px" }}
            src={ACL}
          />
        </a>
        <a
          href="https://acl.gov/about-acl/about-national-institute-disability-independent-living-and-rehabilitation-research"
          target="_blank"
        >
          <Image style={{ height: "52px" }} src={NIDILRR} />
        </a>
      </div>
    </div>
  );
}

export default Footer;
