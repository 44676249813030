/*import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React from "react";
import sendValues from "../../SendValues.js";*/
import SignUpAlert from "../../SignUpAlert.js";
import EntryEditor from "../../EntryEditor.js";

function Reflect({ authToken, user, setUser }) {
  /*const onInput = ({ target: { value, name } }) => {
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendValues(user, authToken, "b1r");
  };*/

  return (
    <div>
      <h3>
        Reflect: Do I expect any of those challenges to occur in my workplace?
      </h3>
      <p>
        Regardless of your employer’s decision regarding your workplace
        accommodation request, you may feel like you are being treated
        differently by co-workers, colleagues, or supervisors after making an
        accommodation request.
      </p>
      <p>
        If someone changes how they regard you based on finding out information
        that is new to them, changes in the way they behave around you can be
        very subtle. It is also important to notice if you find yourself feeling
        self-conscious about your work performance or personal image after
        negotiating for a workplace accommodation. Feeling self-conscious or
        anxious can lead you to misinterpret comments or actions that were not
        intended to criticize you or your work performance.
      </p>
      <p>
        It can be helpful to keep a work diary or journal both during and after
        having made an accommodation request. You can keep your notes online in
        this decision aid using the diary you will find at the bottom of this
        page and in the Reports section of the aid, or you can just use a
        notebook you carry with you. Use whatever will help you gather the
        information consistently so it is available for later review. If you
        start to feel like you are being treated differently, it is especially
        important to try to document or keep a record of any conversations,
        comments, or behaviors from co-workers or a supervisor that seem odd,
        unusual, or suddenly different from previous interactions with that
        person. Jot down notes about when (date and rough time of day), where
        (specific location, such as “outside the second floor men’s bathroom”),
        and what took place (“John D. made the comment to me: “I thought you’d
        want your own bathroom now.”) Try to be as specific and detailed as you
        can.{" "}
      </p>
      <p>
        Writing down such instances will help you look back over a period of
        time to determine if you are starting to see a pattern of behavior that
        unnecessarily references or calls out your disability that makes you
        uncomfortable. Determine if these instances include offensive jokes,
        slurs, epithets or name calling, physical assaults or threats,
        intimidation, ridicule or mockery, insults or put-downs, offensive
        objects or pictures, and interference with work performance.{" "}
      </p>
      {!user ? <SignUpAlert /> : <EntryEditor user={user} setUser={setUser} />}
    </div>
  );
}

export default Reflect;
