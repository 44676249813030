import { Link } from "react-router-dom";

function Learn() {
  return (
    <div>
      <h2>Section 9</h2>
      <h3>
        Learn: What if my request and specific accommodation are approved – is
        there anything else I should do?
      </h3>
      <p>
        If an employer approves and provides the specific accommodation you
        suggested, it may still take some time for you to become accustomed to
        and comfortable with the accommodation. Set aside the necessary time to
        familiarize yourself with the accommodation, particularly if it is an
        assistive device, software, or other technology. If the specific
        accommodation was recommended by your healthcare professional, such as
        an occupational therapist, work with that professional to learn how to
        use the device to its fullest extent. Seek out other resources, such as
        searching online for tutorials, discussions, or information about how
        others use the device or technology. Give yourself time and space to
        adjust to incorporating the accommodation into your work routine.
      </p>
      <p>
        There are times, however, when a particular accommodation turns out not
        to work as well as you had hoped. It is important to periodically assess
        the effectiveness of a particular accommodation. If you find after a
        month or two that this accommodation is not working for you, you may
        want to consider approaching your employer about trying a different
        accommodation. If you find yourself in this position, read through the
        module of this tool called{" "}
        <Link to="/">
          “What happens if later I need a different or additional RA?”
        </Link>
      </p>
    </div>
  );
}

export default Learn;
