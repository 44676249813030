// Sends a get request to the backend for the values for a given view (given page)
const getValues = (token, endpoint) => {
  const handleData = async (e) => {
    const response = await fetch(
      "https://depts.washington.edu/adakt/ra-tool/api.php" + endpoint,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "X-Api-Key": token,
        }),
      }
    );
    if (response.status >= 300) {
      const error = await response.text();
      console.log(error);
      return;
    }
    const values = await response.json();
    return values.records;
  };

  return handleData();
};

export default getValues;
