import { styles, ListItem } from "./StylesPDF.js";
import { Document, Page, Text, Font, StyleSheet } from "@react-pdf/renderer";

const MyFunctionsPDF = ({ user }) => (
  <Document>
    <Page style={styles.body} size="LETTER">
      <Text style={styles.title}>My Essential Functions</Text>
      <Text style={styles.subtitle}>
        When we asked what functions you perform at your job, you said:
      </Text>
      {user.jobTasks.map((task) => {
        return (
          <ListItem>
            {task.jobTasksTask}. You said this is{" "}
            {!(
              task.jobTasksTime === true ||
              task.jobTasksOthers === true ||
              task.jobTasksExpertise === true
            ) && "not "}
            an essential function of your job.
          </ListItem>
        );
      })}
      <Text style={styles.text}>
        When we asked what functions you perform at your job, you said:
      </Text>
      {user.jobTasks.map((task) => {
        return (
          <ListItem>
            {task.jobTasksTask}. You said this is{" "}
            {!(
              task.jobTasksTime === true ||
              task.jobTasksOthers === true ||
              task.jobTasksExpertise === true
            ) && "not "}
            an essential function of your job.
          </ListItem>
        );
      })}
      <Text style={styles.text}>
        When we asked if you met the prerequisites of your job, you said that
        you did{!user.responses.b3aPrerequisites && " not"}.
      </Text>
      <Text style={styles.text}>
        When we asked if you are able to perform the essential functions of you
        job with or without reasonable accommodations, you said that you could
        {!user.responses.b3aPerform && " not"}.
      </Text>
    </Page>
  </Document>
);

export default MyFunctionsPDF;
