import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

function Learn() {
  return (
    <div>
      <h1>During the Request</h1>
      <h2>Section 7</h2>
      <h3>Learn: How can I make the strongest request for accommodations?</h3>
      <Accordion flush>
        <Accordion.Item eventKey="Identify">
          <Accordion.Header>
            <h4 style={{ paddingBottom: "0px" }}>Identify who to approach</h4>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Some companies have formal policies for requesting an
              accommodation. You are likely to find such policies on the company
              website, an employee handbook, or among materials provided to all
              new employees. However, not all companies have developed formal
              policies about accommodations. If you cannot easily find a written
              policy, you will need to approach a human resources representative
              at the company. If you must talk to human resources personnel or a
              supervisor to get this information, we encourage you to prepare
              your accommodation request before initiating that conversation.
            </p>
            <p>
              If you work in a medium-sized or large company, you will submit an
              accommodation request to the human resources personnel who handle
              the “administrative” parts of your job. This is likely to be the
              same person you would talk to about insurance coverage, company
              policies, sick leave, vacation pay or similar issues.
            </p>
            <p>
              If it is not clear who you should talk to at your company, your
              direct supervisor should be able to tell you who to speak with. If
              you must ask around to find the right person, you do not need to
              indicate that you want to discuss workplace accommodations;
              instead, you can ask who to talk to about your ‘employment
              contract and personnel file.’ If there are multiple people who
              play this role, you may want to reflect on who to approach.
            </p>
            <p>
              If your company does not have a clear policy or set of procedures
              available to all employees for requesting an accommodation, that
              may signal that the employer is not very familiar with or does not
              have experience in implementing reasonable accommodations. If that
              is the case, keep in mind this may be a new process for them too!
              This means you will both need to work together to navigate the
              process. Your employer (for example, the human resources personnel
              or supervisor) may mistakenly request information from you that is
              not necessary, and that you are not legally required to provide.
              Be prepared to be a strong advocate for yourself!
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="Develop">
          <Accordion.Header>
            <h4 style={{ paddingBottom: "0px" }}>Develop a good request</h4>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              If your employer has established procedures for requesting
              accommodations, review those and try to adhere to their steps and
              timeline as closely as possible. Even if your employer does not
              have specific guidelines, the key components of a good
              accommodation request include the following elements: 1) a clear
              statement that you need a change or adjustment at work to
              accommodate an impairment, and 2) a request to engage in a
              discussion with your employer to explore accommodation options.
            </p>
            <p>
              Prepare a clear request to your employer that you need a change or
              adjustment at work because of an impairment related to a medical
              condition or disability. If you have not yet read it,{" "}
              <Link to="/before/section4/learn">Before the Request</Link>,
              Section 4 of this decision aid can help you frame that request.
            </p>
            <p>
              <p>
                The request can be in ‘plain language’. It does not need to use
                the words ‘reasonable accommodation’ or refer to the ADA, to
                count as a request for reasonable accommodation, but it can be
                helpful if you use those words. It does not need to be in
                writing. The request can be made during a conversation. You may
                feel more comfortable writing it down, or your employer may
                follow up to ask you to complete a form, but the initial request
                can be spoken.
              </p>
              <p>
                The request should clearly and concisely describe the impairment
                to be accommodated (e.g., limited mobility, difficulty standing
                for periods longer than one-hour, frequent rest breaks to
                administer medication). The request should also describe how the
                impairment affects specific tasks and explains whether those
                tasks are “essential functions” of the job. You can explore
                examples of sufficient and nonsufficient requests on the Equal
                Opportunity Employment Commission (EEOC) webpage. Some{" "}
                <a
                  target="_blank"
                  href="https://www.eeoc.gov/laws/guidance/enforcement-guidance-reasonable-accommodation-and-undue-hardship-under-ada"
                >
                  examples
                </a>{" "}
                include:
              </p>
              <p>
                <strong>Example A</strong>: An employee tells her supervisor,
                "I'm having trouble getting to work at my scheduled starting
                time because of medical treatments I'm undergoing." This is a
                request for a reasonable accommodation.
              </p>
              <p>
                <strong>Example B</strong>: An employee tells his supervisor, "I
                need six weeks off to get treatment for a back problem." This is
                a request for a reasonable accommodation.
              </p>
              <p>
                <strong>Example C</strong>: A new employee, who uses a
                wheelchair, informs the employer that her wheelchair cannot fit
                under the desk in her office. This is a request for reasonable
                accommodation.
              </p>
              <p>
                <strong>Example D</strong>: An employee tells his supervisor
                that he would like a new chair because his present one is
                uncomfortable. Although this is a request for a change at work,
                his statement is insufficient to put the employer on notice that
                he is requesting reasonable accommodation. He does not link his
                need for the new chair with a medical condition.
              </p>
            </p>
            <p>
              <strong>
                Ask to have an interactive discussion with your employer where
                you clarify what you need, and you work together to identify an
                appropriate accommodation.{" "}
              </strong>
              <p>
                During the discussion, you can clarify what you need and your
                employer can ask for additional information to help make an
                informed decision.
              </p>
              <p>
                You do not have to identify a specific accommodation in advance,
                but it can be useful to bring to this discussion examples of
                potential accommodations that you think might be effective. The
                examples that you suggest serve as a recommendation. If your
                disability is something the employer can see, then it may be
                obvious what types of accommodations would be appropriate. If
                not, your employer is allowed to ask you questions about your
                disability and how it affects you. We discuss this more in the
                following section. The employer ultimately decides what
                accommodation to offer you so long as the accommodation is
                effective. This means you may not get exactly what you want. The
                employer could choose the cheaper or easier of two options so
                long as they both are effective in removing the barriers you
                face. However, determining an appropriate accommodation is
                supposed to be an interactive process, so providing suggestions
                or examples of accommodations at the start of the discussion
                will help set the tone of a collaborative discussion.
              </p>
              <p>
                Employers are supposed to respond as quickly as possible to your
                request. This means, they should respond to your request, engage
                in the interactive discussion, and provide the accommodation
                within a reasonable amount of time. You can ask for a rough
                timeline when you engage in the interactive discussion.
              </p>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="Understand">
          <Accordion.Header>
            <h4 style={{ paddingBottom: "0px" }}>
              Understand what information you are required to provide your
              employer
            </h4>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Employers can only provide accommodations for impairments that
              they know about, so it is important to be clear and specific about
              what your impairment is and how it affects specific tasks of your
              job. Employers may request, and have the legal right to ask for,
              documentation of the impairment, such as a health care
              professional’s letter or note from a vocational rehabilitation
              counselor. The documentation request must be ‘reasonable”. In
              other words, it must be the information the employer needs to know
              to determine that you have a disability covered by the ADA. An
              employer cannot ask for information that is not related to
              understanding the disability and the necessary accommodation.
            </p>
            <p>
              To obtain a reasonable accommodation, you must provide information
              about the <strong>specific impairment only</strong> that impacts
              particular job tasks; you do not need to provide any other health
              information, including what the impairment stems from (i.e., a
              disease, an injury, etc.) or what treatment you are receiving.
            </p>
            <Card className="popout">
              <Card.Body>
                <strong>Example:</strong> a woman with Multiple Sclerosis has
                limited ability to stand for long periods of time and cannot
                walk long distances. She does not use any assistive device such
                as a wheelchair or cane, so her mobility impairment is not
                obvious to others. She works as a grocery cashier and finds
                standing all day so exhausting that she cannot do anything else
                after work, including basic living activities such as
                housecleaning, cooking, or spending time with her children. She
                requests a reasonable accommodation from her employer. To do so,
                she provides her employer with a letter from her doctor stating
                that she has a mobility impairment and cannot stand for more
                than two hours at a time. She suggests to the employer that
                having a high stool she can sit on while checking out customers
                would be ideal. She does not share with her employer that she
                has Multiple Sclerosis, how long she has lived with MS, or any
                other health information. The employer grants her request.
              </Card.Body>
            </Card>
            <p>
              An employer can require that documentation comes from a reputable
              source like a medical doctor or specialist.
            </p>
            <p>
              An employer can require that documentation comes from a reputable
              source like a medical doctor or specialist. Documentation includes
              information that “describes the impairment, the nature, severity,
              and duration of the impairment, the activity or activities that
              the impairment limits; and the extent to which the impairment
              limits the employee’s ability to perform the activity or
              activities. It can be a good idea to be ready for this request by
              asking for a letter beforehand from a licensed health professional
              confirming the impairment exists and outlining limitations
              associated with the impairment, especially if you have an
              impairment that is not obvious to others (such as use of an
              assistive device, etc.)
            </p>
            <p>
              Although an employer is allowed to request documentation, they are
              not required to do so. They could simply discuss the nature of
              your disability and the needed accommodations with you and make a
              decision. However, an employer cannot ask for information when:
              (1) both the disability and the need for reasonable accommodation
              are obvious, or (2) the individual has already provided the
              employer with sufficient information to substantiate that s/he has
              an ADA disability and needs the reasonable accommodation
              requested. For additional information, guidance, or examples of
              reasonable documentation, see the{" "}
              <a
                target="_blank"
                href="https://askjan.org/articles/Requests-For-Medical-Documentation-and-the-ADA.cfm"
              >
                Job Accommodation Network (JAN)
              </a>
            </p>
            <p>
              As we discussed above, the reasonable accommodation process is
              meant to be an interactive discussion between an employer and
              employee; to that end, the employer should also ask for your
              suggestions on what types of accommodations you think would work
              best. Documentation of a specific impairment and suggestions for
              types of accommodations are the only pieces of information that
              you are required to provide to an employer to obtain a reasonable
              accommodation.
            </p>
            <p>
              By law, a reasonable accommodation request and the supporting
              documentation provided should never go into your employment
              personnel file, the information should be kept strictly
              confidential and not be shared with any of your co-workers.
              Co-workers who may need to adjust or change the way they do things
              as part of your accommodation may be told of the change required,
              but not the reason why the change was made.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="Prepared">
          <Accordion.Header>
            <h4 style={{ paddingBottom: "0px" }}>
              Be prepared to deal with questions
            </h4>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Providing all the legally required information up front should
              ease the request process. You should be prepared for co-workers,
              HR personnel or supervisors to express genuine concern (and
              curiosity) about you when you request an accommodation and to ask
              follow-up questions about your situation. Since you are not
              required to provide any additional information beyond how the
              specific impairment impacts specific job tasks, you should feel
              empowered to politely thank them for their concern and redirect
              the conversation by asking what the next steps are in processing
              your accommodation request.
            </p>
            <p>
              It is important to be thoughtful and selective about what
              information you share with an employer. As previously discussed,
              disability stigma – or negative assumptions and stereotypes
              associated with individuals with disabilities – is pervasive in
              our society and many people are largely unaware of the bias they
              carry against disability. People may also have misinformation or
              false beliefs about the causes of a condition or impairment, how
              those conditions do or do not progress, or have strong opinions
              about how to cure impairments. People may make false assumptions
              or judgments about a person’s life choices or character based on
              injuries sustained from accidents or assaults. None of that
              information is relevant for you to do good work at your current
              job! More importantly, how you became impaired, the expected
              long-term outcome of the impairment, and if or how you are
              treating it is not information generally required by the law to
              obtain a workplace accommodation.
            </p>
            <p>
              It is helpful to think through a few scenarios of how the initial
              accommodations conversation might go and have a plan for how you
              want to handle each scenario. Use the{" "}
              <a target="_blank" href="https://google.com">
                Accommodations Conversation Scenario Worksheet
              </a>{" "}
              to help you. Keep in mind that this is a conversation and that you
              can choose to stop it at any time and start it again at any time.
              The important thing is to keep the conversation focused on your
              reasonable request for workplace accommodations.
            </p>
            <p>
              Even if the conversation goes exactly as you hoped, make sure and
              take notes! Jot down notes of the date, time, who participated and
              a general summary of each conversation you have regarding the
              accommodation request process. Having a detailed record of these
              items will help you and your employer keep track of the process.
            </p>
            <p>
              If you find yourself asked a lot of unnecessary questions or asked
              about the accommodation process itself, we recommend that you
              refer your employer to a resource such as{" "}
              <a target="_blank" href="https://adata.org/">
                the ADA National Network
              </a>{" "}
              or even share the link to this decision aid.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default Learn;
