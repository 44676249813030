import Accordion from "react-bootstrap/Accordion";
import RARequest from "./RARequest.js";
import MyNotes from "./MyNotes.js";
import MyFunctions from "./MyFunctions.js";
import MyAccommodations from "./MyAccommodations.js";
import MySteps from "./MySteps.js";
import EntryEditor from "../modules/EntryEditor.js";

const Reports = ({ user, setUser }) => {
  return (
    <div>
      <h2>My Reports</h2>
      <p>Below you can view your responses as reports.</p>
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>My Notes</Accordion.Header>
          <Accordion.Body>
            <MyNotes user={user} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>My Essential Functions</Accordion.Header>
          <Accordion.Body>
            <MyFunctions user={user} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>My Accommodations</Accordion.Header>
          <Accordion.Body>
            <MyAccommodations user={user} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>My RA Request</Accordion.Header>
          <Accordion.Body>
            <RARequest user={user} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>My RA Steps</Accordion.Header>
          <Accordion.Body>
            <MySteps user={user} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>My Diary</Accordion.Header>
          <Accordion.Body>
            <EntryEditor user={user} setUser={setUser} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Reports;
