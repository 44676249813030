import MyStepsPDF from "./PDFs/MyStepsPDF.js";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Button from "react-bootstrap/Button";

const MySteps = ({ user }) => {
  return (
    <div>
      <p>
        When we asked what steps you plan to take to make your reasonable
        accommodation request, you said:
      </p>
      <ol>
        {user.reqSteps.map((step) => {
          if (step.reqStepsStep == "") {
            return;
          }
          return (
            <li>
              {step.reqStepsStep}. {step.reqStepsStepDescription}
            </li>
          );
        })}
      </ol>

      <PDFDownloadLink
        document={<MyStepsPDF user={user} />}
        fileName="MySteps.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            <Button variant="outline-primary" disabled>
              Loading PDF...
            </Button>
          ) : (
            <Button variant="outline-primary">Download PDF</Button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export default MySteps;
