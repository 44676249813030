import { Link } from "react-router-dom";

// Sends a request to alter the user's responses to reflect and apply data
const sendValues = async (sendData, authToken, apiEndpoint) => {
  if (
    apiEndpoint == "/records/jobTasks" ||
    apiEndpoint == "/records/reqSteps" ||
    apiEndpoint == "/records/entries"
  ) {
    const a = await deleteTasks(
      apiEndpoint,
      sendData.filter((task) => {
        return task.deleted == true && task.ID > 0;
      }),
      authToken
    );
    const tasks = sendData.filter((task) => {
      return task.deleted == null || task.deleted == false;
    });
    const b = await addTasks(
      apiEndpoint,
      tasks
        .filter((task) => {
          return task.ID < 0;
        })
        .map(({ ID, ...taskAttrs }) => taskAttrs),
      authToken
    );
    const c = await modifyTasks(
      apiEndpoint,
      tasks.filter((task) => {
        return task.ID > 0;
      }),
      authToken
    );
    if (a && a.status >= 200) {
      return a;
    } else if (b && b.status >= 200) {
      return b;
    } else if (c && c.status >= 200) {
      return c;
    } else if (a) {
      return a;
    } else if (b) {
      return b;
    } else {
      return c;
    }
  } else {
    // responses
    const handleData = async () => {
      const response = await fetch(
        "https://depts.washington.edu/adakt/ra-tool/api.php" + apiEndpoint,
        {
          method: "PUT",
          body: JSON.stringify(sendData),
          headers: new Headers({
            "Content-Type": "application/json",
            "X-Api-Key": authToken,
          }),
        }
      );
      if (response.status >= 300) {
        const error = await response.text();
        console.log(error);
        return {
          status: response.status,
          text: error,
          heading: "Error " + response.status + ": " + response.statusText,
        };
      }
      return {
        status: response.status,
        text: (
          <>
            Your responses have been submitted. You can find these responses in
            the <Link to="/reports">Reports</Link> section of this decision aid.
          </>
        ),
        heading: "Success!",
      };
    };
    return handleData();
  }
};

const deleteTasks = async (endpoint, tasks, authToken) => {
  if (tasks != null && tasks.length != 0) {
    const ids = tasks.map((task) => {
      return task.ID;
    });
    const response = await fetch(
      "https://depts.washington.edu/adakt/ra-tool/api.php" +
        endpoint +
        "/" +
        ids.toString(),
      {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          "X-Api-Key": authToken,
        }),
        body: JSON.stringify(tasks),
      }
    );
    if (response.status >= 300) {
      const error = await response.text();
      console.log(error);
      return {
        status: response.status,
        text: error,
        heading: "Error " + response.status + ": " + response.statusText,
      };
    }
    return {
      status: response.status,
      text: (
        <>
          Your responses have been submitted. You can find these responses in
          the <Link to="/reports">Reports</Link> section of this decision aid.
        </>
      ),
      heading: "Success!",
    };
  }
};

const addTasks = async (endpoint, tasks, authToken) => {
  if (tasks != null && tasks.length != 0) {
    const response = await fetch(
      "https://depts.washington.edu/adakt/ra-tool/api.php" + endpoint,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "X-Api-Key": authToken,
        }),
        body: JSON.stringify(tasks),
      }
    );
    if (response.status >= 300) {
      const error = await response.text();
      console.log(error);
      return {
        status: response.status,
        text: error,
        heading: "Error " + response.status + ": " + response.statusText,
      };
    }
    return {
      status: response.status,
      text: (
        <>
          Your responses have been submitted. You can find these responses in
          the <Link to="/reports">Reports</Link> section of this decision aid.
        </>
      ),
      heading: "Success!",
    };
  }
};

const modifyTasks = async (endpoint, tasks, authToken) => {
  if (tasks != null && tasks.length != 0) {
    const ids = tasks.map((task) => {
      return task.ID;
    });
    const response = await fetch(
      "https://depts.washington.edu/adakt/ra-tool/api.php" +
        endpoint +
        "/" +
        ids.toString(),
      {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          "X-Api-Key": authToken,
        }),
        body: JSON.stringify(tasks),
      }
    );
    if (response.status >= 300) {
      const error = await response.text();
      console.log(error);
      return {
        status: response.status,
        text: error,
        heading: "Error " + response.status + ": " + response.statusText,
      };
    }
    return {
      status: response.status,
      text: (
        <>
          Your responses have been submitted. You can find these responses in
          the <Link to="/reports">Reports</Link> section of this decision aid.
        </>
      ),
      heading: "Success!",
    };
  }
};

export default sendValues;
