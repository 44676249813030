import Accordion from "react-bootstrap/Accordion";
import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";

const Learn = ({ setFeedback }) => {
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h2>Section 2</h2>
      <h3>
        Learn: How is Disability Defined in the Americans With Disabilities Act?
      </h3>
      <p>
        In the United States, there are different ways of defining disability,
        both culturally and in our laws. Many organizations and government
        agencies have different definitions of disability that people must meet
        before receiving services, financial assistance, or disability benefits.
        The Americans with Disabilities Act (ADA) is a federal law that protects
        the civil rights of people with disabilities. The ADA does not provide
        benefits or services. Instead, the ADA protects people with disabilities
        in the United States from discrimination. To be protected under the ADA,
        a person (including children) must meet its legal definition of
        disability and be “qualified”. Many state laws provide additional rights
        beyond the ADA.
      </p>
      <p>
        The ADA has a specific definition of the term "disability" that
        establishes the requirements a person must meet to be considered "an
        individual with a disability under the ADA." Determining whether you are
        a person with a disability under the ADA is not always simple.
      </p>
      <p>
        The sections below explain in detail how the ADA defines "disability" to
        help you understand how an individual may be protected from
        discrimination under the law.
      </p>
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            What is a Disability Under the ADA?
          </Accordion.Header>
          <Accordion.Body>
            <p>The ADA defines a person with a disability as someone who:</p>
            <ul>
              <li>
                Has a physical or mental impairment that substantially limits
                one or more major life activities, or
              </li>
              <li>
                Has a history or <em>record</em> of an impairment (such as
                cancer that is in remission), or
              </li>
              <li>
                Is <em>regarded</em> as having such an impairment by others even
                if the individual does not actually have a disability (such as a
                person who has scars from a severe burn that does not limit any
                major life activity).
              </li>
            </ul>
            <p>
              This is a legal definition—not a medical or social justice-related
              definition. In addition, there is not a registry or a list of
              medical conditions or disabilities that are protected by the ADA.
              The intent of the United States Congress was to establish broad
              protection from discrimination against individuals who may have
              any medical condition or disability that substantially limits one
              or more major life activities. Thus, if your condition meets the
              criteria stated in the ADA definition, your civil rights are
              generally protected by the ADA.
            </p>
            <p>
              To know if you are considered a person with a disability under the
              ADA, you need to understand what is meant by “a physical or mental
              impairment,” “substantially limits,” and “major life activities.”
              In the following sections, we discuss the meaning of these key
              terms so you can consider whether they apply to you.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            What is a physical or mental impairment under the ADA?
          </Accordion.Header>
          <Accordion.Body>
            <p>The ADA defines a physical or mental impairment this way:</p>
            <ol type="i">
              <li>
                Any physiological disorder or condition, cosmetic disfigurement,
                or anatomical loss affecting one or more body systems, such as:
                neurological, musculoskeletal, special sense organs, respiratory
                (including speech organs), cardiovascular, reproductive,
                digestive, genitourinary, immune, circulatory, hemic, lymphatic,
                skin, and endocrine; or
              </li>
              <li>
                Any mental or psychological disorder such as intellectual
                disability, organic brain syndrome, emotional or mental illness,
                and specific learning disability.
              </li>
            </ol>
            <p>
              The ADA regulations give examples of impairments that are
              considered disabilities, but it is very clear that "the definition
              of ‘disability’ shall be construed broadly in favor of expansive
              coverage." This means that the examples do not include all
              possible disabilities. You should always evaluate whether a
              condition or impairment fits under the ADA definition.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>What is a Major Life Activity?</Accordion.Header>
          <Accordion.Body>
            <p>
              Under the ADA, the definition of a disability requires that
              impairments substantially limit the ability to perform major life
              activities.{" "}
              <strong>
                Major life activities are basic activities that most people can
                perform with little or no difficulty.
              </strong>{" "}
              Some examples of major life activities include:
            </p>
            <ul>
              <li>Actions like eating, sleeping, speaking, and breathing.</li>
              <li>Movements like walking, standing, lifting, and bending.</li>
              <li>Cognitive functions like thinking and concentrating.</li>
              <li>Sensory functions like seeing and hearing.</li>
              <li>Tasks like working, reading, learning, and communicating.</li>
              <li>
                The operation of major bodily functions like circulation and
                reproduction.
              </li>
              <li>
                The function of individual organs like the heart, lungs, or
                pancreas.{" "}
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            What does it mean to substantially limit Major Life Activities?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Once you decide that a person’s impairment affects major life
              activities, the next step is to assess how much it affects those
              major life activities. The ADA regulations include rules to help
              you identify whether there is a substantial limitation of a major
              life activity. These rules include the following:
            </p>
            <ul>
              <li>
                An impairment does not have to limit a major life activity
                completely or severely for it to be considered substantially
                limiting. In other words, the limitation can be less than
                complete or severe.
              </li>
              <li>
                Congress intended that the words “substantially limit” be
                interpreted broadly to result in expansive coverage of people
                with disabilities under the law.
              </li>
              <li>
                Determining whether an impairment substantially limits a major
                life activity requires an individualized assessment. What may
                not be substantially limiting for one person in one situation
                may be substantially limiting for another person in another
                context.
              </li>
              <li>
                Deciding whether a disability substantially limits a major life
                activity does not have to be complicated or involve a lot of
                documentation. A common-sense assessment comparing a person’s
                ability to complete specific major life activities to the
                ability of most people is all that is required. For example,
                most people can walk short distances without assistance or can
                hear without the use of hearing aids. An impairment that limited
                either of these abilities beyond what most people can do would
                be considered “substantial.”
              </li>
            </ul>
            <Card className="examples">
              <Card.Body>
                <p>
                  <strong>Example: Two People With Low Vision</strong>
                </p>
                <ul>
                  <li>
                    Tamra is in his thirties. He has difficulty seeing without
                    glasses and must wear them to see clearly and complete daily
                    tasks. Since wearing glasses or contacts to correct vision
                    impairments is common in the general adult population, Tamra
                    is not considered a person with a disability.
                  </li>
                  <li>
                    Jean is also in her thirties and has low vision. She wears
                    glasses, but still cannot see clearly and requires assistive
                    technology to use the computer and her smart phone. Jean's
                    amount of visual difficulty is not typical for her age
                    group. She is considered a person with a disability under
                    the ADA.
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            How do you assess the impact of an impairment on Major Life
            Activities?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              People with disabilities often take action to eliminate or reduce
              the effects of their disabilities. These actions are called
              "mitigating measures." Mitigating measures include medications,
              treatments, assistive technology, hearing aids, wheelchairs,
              therapies, and much more. A person's impairment should be assessed
              on how it impacts major life activities and body systems{" "}
              <em>without</em> using mitigating measures. (There are a few
              exceptions, such as eyeglasses and contact lenses.)
            </p>
            <Card className="examples">
              <Card.Body>
                <p>
                  <strong>Example: Mitigating Measures</strong>
                </p>
                <ul>
                  <li>
                    Bill has type 2 diabetes, and insulin is his "mitigating
                    measure." Bill has no limitations on his major life
                    activities when he monitors his blood sugar and uses
                    insulin. However, he uses insulin because his endocrine
                    system is substantially impaired. Without insulin, the
                    impact on his major life activities and overall body
                    functions would be severe. Bill is covered by the ADA as a
                    person with a disability.
                  </li>
                </ul>
              </Card.Body>
            </Card>
            <p>
              Some impairments have symptoms that are not always present. These
              types of impairments are called episodic. Some impairments resolve
              permanently while others go into remission and may return. Whether
              or not you have a disability under the ADA, is based on the impact
              of an impairment on major life activities and body functions when
              symptoms are active.{" "}
              <strong>
                Even if you do not have active symptoms, you are protected by
                the ADA if your symptoms meet the definition of disability.
              </strong>
            </p>
            <p>
              This applies to impairments that may go into remission, such as
              cancer. Other examples of impairments that may be episodic or have
              periods of remission include multiple sclerosis, mood disorders,
              asthma, seizure disorders, severe migraines, and autoimmune
              disorders.
            </p>
            <Card className="examples">
              <Card.Body>
                <p>
                  <strong>Examples: Episodic Conditions and Remission</strong>
                </p>
                <ul>
                  <li>
                    Marla has multiple sclerosis and uses both a wheelchair and
                    a cane for mobility. Typically, she uses the wheelchair for
                    long distances and the cane for short distances. But when
                    her condition is in remission, she only uses the cane. Some
                    of Marla's co-workers think she shouldn't receive
                    accommodations because she doesn't always use a wheelchair.
                    However, Marla does not have to use a wheelchair daily to be
                    a person with a disability under the ADA and to receive
                    accommodations related to using a wheelchair.
                  </li>
                  <li>
                    Linn has Seasonal Affective Disorder. His symptoms of
                    depression only affect major life activities at certain
                    times of the year. Because Linn's condition is long-term and
                    impacts major life activities when present, it is considered
                    a disability under the ADA, even though the condition is not
                    always present.
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            What does it mean to have a record of an impairment?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Some impairments may be resolved while others may go into
              remission. However, people may still experience discrimination
              because they had these impairments in the past. Sometimes they are
              not offered certain kinds of work because the employer is
              concerned that the condition may return. Other times, they are not
              hired or retained because employers are concerned that their
              healthcare costs will be too high or that they will have poor
              attendance in the future.{" "}
              <strong>
                The ADA covers people who had an impairment in the past.
              </strong>
            </p>
            <Card className="examples">
              <Card.Body>
                <p>
                  <strong>Example: Record of Impairment</strong>
                </p>
                <ul>
                  <li>
                    Tim has been cancer-free for five years and doctors believe
                    he is in complete remission. His employer does not promote
                    him as expected because the employer says the "cancer may
                    come back." Under the ADA, Tim is protected from
                    discrimination that is based on his history of having a
                    disability.
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            What does it mean to be 'regarded as' having an impairment?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Sometimes people do not have a disability, but people believe that
              they do. If they are discriminated against based on that belief
              (for example, if they are not hired, or not allowed to participate
              in a program), they may be able to file a complaint under the ADA.
            </p>
            <Card className="examples">
              <Card.Body>
                <p>
                  <strong>Examples: Regarded as Having an Impairment</strong>
                </p>
                <ul>
                  <li>
                    Jenna has noticeable burn scars on her face. Her scars have
                    no impact on major life activities. However, Jenna is turned
                    away from a job interview because of her facial scars. The
                    employer believes her “condition will make her unable to
                    work with customers." Under the ADA, Jenna would be
                    protected from discrimination because her employer regarded
                    her scars as a disabling condition.
                  </li>
                  <li>
                    Sarah asked to be reassigned to a different warehouse job
                    because of shoulder pain. The company terminated her and
                    told her there were no other jobs available, although one
                    was posted soon after. Under the ADA, Sarah may have a
                    legitimate complaint
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Learn;
