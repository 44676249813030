import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import * as ft from "../modules/functions/formTracking.js";
import sendValues from "./SendValues.js";
import getValues from "./GetValues.js";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import MyDiaryPDF from "../reports/PDFs/MyDiaryPDF.js";

const EntryEditor = ({ user, setUser }) => {
  // kinda broken but im not gonna touch it
  const fEntries = user.entries.filter((entryd) => {
    return entryd.deleted != true;
  });

  const [editable, setEditable] = useState(false);

  const [targetID, setTargetID] = useState(
    fEntries.length == 0
      ? ft.handleAddEntry(user, setUser)
      : fEntries.sort((a, b) => {
          return new Date(a.entriesDate) > new Date(b.entriesDate);
        })[fEntries.length - 1].ID
  );

  return (
    <div>
      <h3>My Journal</h3>
      <Card body>
        {fEntries.length == 0 ? (
          <></>
        ) : editable ? (
          <EditEntry
            user={user}
            setUser={setUser}
            setEditable={setEditable}
            targetID={targetID}
            setTargetID={setTargetID}
          />
        ) : (
          <ViewEntry
            user={user}
            setUser={setUser}
            setEditable={setEditable}
            targetID={targetID}
            setTargetID={setTargetID}
          />
        )}
        <DropdownButton
          id="change-entry"
          title="Select Entry"
          style={{
            marginTop: "10px",
            display: "inline-block",
          }}
          variant="primary"
        >
          {fEntries
            .sort((a, b) => {
              return new Date(a.entriesDate) > new Date(b.entriesDate);
            })
            .map((e) => {
              return (
                <Dropdown.Item onClick={() => setTargetID(e.ID)}>
                  {e.entriesDate + " | " + e.entriesName}
                </Dropdown.Item>
              );
            })}
        </DropdownButton>

        <PDFDownloadLink
          document={<MyDiaryPDF user={user} />}
          fileName="MyJournal.pdf"
          className="float-end"
          style={{ marginTop: "10px" }}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <Button variant="outline-primary" disabled>
                Loading PDF...
              </Button>
            ) : (
              <Button variant="outline-primary">Download Journal</Button>
            )
          }
        </PDFDownloadLink>
      </Card>
    </div>
  );
};

const calcEntryNext = (user, targetID, mod) => {
  const entryMap = user.entries
    .filter((entryd) => {
      return entryd.deleted != true;
    })
    .sort((a, b) => {
      return new Date(a.entriesDate) > new Date(b.entriesDate);
    });
  const entryNext =
    entryMap[
      entryMap.findIndex((e) => {
        return e.ID == targetID;
      }) + mod
    ];
  return entryNext && entryNext.ID;
};

const findEntry = (user, id) => {
  return user.entries.find((e) => {
    return e.ID == id;
  });
};

const EditEntry = ({ user, setUser, setEditable, targetID, setTargetID }) => {
  return (
    <>
      <Card.Title>
        <Form.Control
          type="text"
          placeholder="Entry Name"
          name="entriesName"
          onChange={(evt) => {
            ft.handleEntryNameChange(targetID, evt, user, setUser);
          }}
          value={findEntry(user, targetID).entriesName}
        />
      </Card.Title>
      <Card.Subtitle className="mb-2 text-muted">
        Entry Date
        <Form.Control
          type="date"
          name="entriesDate"
          onChange={(evt) => {
            ft.handleEntryPropChange(
              targetID,
              "entriesDate",
              evt,
              user,
              setUser
            );
          }}
          value={findEntry(user, targetID).entriesDate}
        />
      </Card.Subtitle>
      <Card.Text>
        <Form.Control
          type="text"
          as="textarea"
          name="entriesDescription"
          placeholder="Entry Description"
          onChange={(evt) => {
            ft.handleEntryPropChange(
              targetID,
              "entriesDescription",
              evt,
              user,
              setUser
            );
          }}
          value={findEntry(user, targetID).entriesDescription}
        />
      </Card.Text>
      <ButtonGroup style={{ width: "100%" }} aria-label="Entry editor">
        <Button
          variant="secondary"
          disabled={!calcEntryNext(user, targetID, -1)}
          onClick={() => setTargetID(calcEntryNext(user, targetID, -1))}
        >
          Back
        </Button>
        <Button
          variant="success"
          onClick={async () => {
            setEditable(false);
            await sendValues(user.entries, user.apikey, "/records/entries");
            const nEntries = await getValues(user.apikey, "/records/entries");
            setTargetID(
              nEntries.find((e) => {
                return e.entriesName == findEntry(user, targetID).entriesName;
              }).ID
            );
            setUser({
              ...user,
              entries: nEntries,
            });
          }}
        >
          Save
        </Button>
        <Button
          variant="secondary"
          disabled={!calcEntryNext(user, targetID, 1)}
          onClick={() => setTargetID(calcEntryNext(user, targetID, 1))}
        >
          Next
        </Button>
      </ButtonGroup>
    </>
  );
};

const ViewEntry = ({ user, setUser, setEditable, targetID, setTargetID }) => {
  return (
    <>
      <Card.Title>{findEntry(user, targetID).entriesName}</Card.Title>
      <Card.Subtitle className="mb-2 text-muted">
        {findEntry(user, targetID).entriesDate}
      </Card.Subtitle>
      <Card.Text style={{ whiteSpace: "pre-wrap" }}>
        {findEntry(user, targetID).entriesDescription}
      </Card.Text>
      <ButtonGroup style={{ width: "100%" }} aria-label="Entry editor">
        <Button
          variant="secondary"
          disabled={!calcEntryNext(user, targetID, -1)}
          onClick={() => setTargetID(calcEntryNext(user, targetID, -1))}
        >
          Back
        </Button>
        <Button variant="secondary" onClick={() => setEditable(true)}>
          Edit
        </Button>
        <Button
          variant="secondary"
          disabled={!calcEntryNext(user, targetID, 1)}
          onClick={() => setTargetID(calcEntryNext(user, targetID, 1))}
        >
          Next
        </Button>
      </ButtonGroup>
      <ButtonGroup style={{ width: "100%", marginTop: "10px" }}>
        <Button
          variant="danger"
          onClick={() => {
            setTargetID(
              calcEntryNext(user, targetID, -1) ||
                calcEntryNext(user, targetID, 1) ||
                ft.handleAddEntry(user, setUser)
            );
            ft.handleRemoveEntry(targetID, user, setUser);
          }}
        >
          Delete Entry
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setEditable(true);
            setTargetID(ft.handleAddEntry(user, setUser));
          }}
        >
          Add New Entry
        </Button>
      </ButtonGroup>
    </>
  );
};

export default EntryEditor;
