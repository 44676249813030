import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import sendValues from "../../SendValues.js";
import SignUpAlert from "../../SignUpAlert.js";

function Reflect({ authToken, user, setUser, setFeedback }) {
  const onInput = ({ target: { value, name } }) => {
    setUser({
      ...user,
      responses: { ...user.responses, [name]: Number(value) },
    });
  };

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  // Show Scoring
  const [score, setScore] = useState([0, 0]);

  const questions = [
    "I feel comfortable talking about the ways my disability affects me.",
    "I am confident that I can plainly explain how my disability affects my ability to do specific tasks.",
    "My disability does not significantly limit my social life.", //new
    "I believe that living with a disability provides me with insight that can supplement the perspectives of and is useful to my non-disabled colleagues.",
    "My disability does not prevent me from doing high quality work", //new
    "I have found support in and share information with a group of others who live with disability.",
    "I see commonalities between my experiences of stigma and the experiences of stigma facing others with disabilities that have different health conditions from my own.",
    "When requesting disability related access or accommodations, I primarily see myself as exercising my civil rights.",
    "My disability is an important part of who I am.", //new
    "My disability enriches my life.", //new
    "In general, I am satisfied with the quality of my life.", //new
  ];

  const names = [
    "Comfort",
    "Explain",
    "Social", //new
    "Insight",
    "Quality", //new
    "Support",
    "Common",
    "Rights",
    "Important", //new
    "Enriches", //new
    "Satisfied", //new
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
    setScore([
      names
        .map((name) => {
          return user.responses["b1r" + name];
        })
        .filter((num) => {
          return num;
        })
        .reduce((a, b) => {
          return a + b;
        }),
      names
        .map((name) => {
          return user.responses["b1r" + name];
        })
        .filter((num) => {
          return num;
        }).length,
    ]);
  };

  // converts and returns score to be piped into scoreFeedback
  const convert = (score) => {
    const fraction = score[0] / (score[1] * 5);
    if (fraction <= 22 / 55) {
      return scoreFeedback[0];
    } else if (fraction > 36 / 55) {
      return scoreFeedback[2];
    } else {
      return scoreFeedback[1];
    }
  };

  const scoreFeedback = [
    [
      "Overall, you do not see positive impacts of living with disability and do not feel confident in successfully asserting disability rights under the law.",
      "You may not identify with the concept of ‘disability identity’, and that’s ok! But if you want to request an accommodation, you will need to describe your condition and explain how it affects you. It may be useful to talk with others who have a similar experience to see how they approached this.",
    ],
    [
      "Overall, you regard living with a disability in a very neutral manner.",
      "You may not have friends or a social group also living with disability that serves to help integrate a positive, empowered experience of disability into your personal identity. And that’s okay! If you decide to request an accommodation, you will need to describe your condition and explain how it affects you. It may be useful to talk with others who have a similar experience to see how they approached this.",
    ],
    [
      "Overall, you see some positive impacts of living with disability and feel relatively confident in successfully asserting disability rights under the law.",
      "You may have developed a strong identification with the concept of ‘disability identity,’ and that’s great! You might consider reaching out to others or joining a group of people living with disability to help support their journey to understanding disability as part of the diversity of human experience.",
    ],
  ];

  const SurveyNode = (text, num) => {
    return (
      <React.Fragment key={num}>
        <Form.Group className="mb-3">
          <Form.Label>{text}</Form.Label>
          <Form.Check
            type="radio"
            name={"b1r" + names[num]}
            id={"b1r" + names[num] + 1}
            onChange={(e) => onInput(e)}
            value={1}
            label="Strongly disagree"
            checked={user.responses["b1r" + names[num]] == 1}
          />
          <Form.Check
            type="radio"
            name={"b1r" + names[num]}
            id={"b1r" + names[num] + 2}
            onChange={(e) => onInput(e)}
            value={2}
            label="Disagree"
            checked={user.responses["b1r" + names[num]] == 2}
          />
          <Form.Check
            type="radio"
            name={"b1r" + names[num]}
            id={"b1r" + names[num] + 3}
            onChange={(e) => onInput(e)}
            value={3}
            label="Neutral"
            checked={user.responses["b1r" + names[num]] == 3}
          />
          <Form.Check
            type="radio"
            name={"b1r" + names[num]}
            id={"b1r" + names[num] + 4}
            onChange={(e) => onInput(e)}
            value={4}
            label="Agree"
            checked={user.responses["b1r" + names[num]] == 4}
          />
          <Form.Check
            type="radio"
            name={"b1r" + names[num]}
            id={"b1r" + names[num] + 5}
            onChange={(e) => onInput(e)}
            value={5}
            label="Strongly agree"
            checked={user.responses["b1r" + names[num]] == 5}
          />
        </Form.Group>
      </React.Fragment>
    );
  };

  return (
    <div>
      <h3>Reflect: What are my assumptions and beliefs about disability?</h3>
      <p>
        Take a moment to reflect on your personal relationship to disability.
        Understanding how you feel about having this label applied to you (or to
        others) will help you decide whether you are ready to talk with your
        employer about your experiences using terms that directly connect to the
        legal language that guides reasonable accommodations. Use the
        questionnaire below to engage in some honest reflection about your own
        beliefs and assumptions on what it means to be disabled or to have a
        functional impairment.
      </p>
      {!user ? (
        <SignUpAlert />
      ) : (
        <div>
          <Form
            style={{ margin: "0 auto", maxWidth: "30em" }}
            onSubmit={handleSubmit}
          >
            {questions.map((q, i) => {
              return SurveyNode(q, i);
            })}

            <Button variant="outline-dark" type="submit">
              Get Score
            </Button>
          </Form>
          {score[0] > 0 && (
            <div>
              <h4>
                Your score: {score[0] + "/" + score[1] * 5}. {convert(score)[0]}
              </h4>
              {score[1] < 11 && (
                <p>
                  It looks like you only answered {score[1]}/11 questions. The
                  more you answer, the more accurate your results will be.
                </p>
              )}
              <p>{convert(score)[1]}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Reflect;
