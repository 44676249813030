import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import sendValues from "../../SendValues.js";
import SignUpAlert from "../../SignUpAlert.js";
import Card from "react-bootstrap/Card";

const Decision = ({ authToken, user, setUser, setFeedback }) => {
  const grades = ["an Unknown", "an A", "a B", "a C", "a D", "an F"];

  const onInput = ({ target: { value, name } }) => {
    setUser({
      ...user,
      responses: { ...user.responses, [name]: Number(value) },
    });
  };

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendValues(
      user.responses,
      authToken,
      "/records/responses/" + user.responses.ID
    );
  };

  return (
    <div>
      <h2>Make a Decision: Do I want to request a reasonable accommodation?</h2>
      <p>
        In this section, we will ask you to consider your assessment of your
        current situation and decide whether you are ready to request a
        reasonable accommodation.
      </p>
      <p>
        This section uses your responses from other sections to help you make a
        decision. If you haven't completed them yet, please do them first.
      </p>
      {!user ? (
        <SignUpAlert />
      ) : (
        <>
          <p>
            In previous sections you said:
            <ul>
              {user.responses.b2rImpair != null && (
                <>
                  <li>
                    You {user.responses.b2rImpair !== true && "do not"} have an
                    impairment that limits a major life activity or body system
                    functions
                  </li>
                  {user.responses.b2rSubstantial != null && (
                    <li>
                      This impairment{" "}
                      {user.responses.b2rSubstantial !== true
                        ? "does not have "
                        : "has "}
                      a substantial impact on major life activities or body
                      system functions.
                    </li>
                  )}
                </>
              )}
              {user.responses.b3aPrerequisites != null && (
                <li>
                  You {user.responses.b2rSubstantial !== true && "do not"} have
                  the required prerequisites for the job.
                </li>
              )}
              {user.responses.b3aPerform != null && (
                <li>
                  You are {user.responses.b3aPerform !== true && "not"} able to
                  perform the essential functions of the job with and without
                  reasonable accommodation.
                </li>
              )}
            </ul>
          </p>
          <Card className="popout">
            <Card.Body>
              {user.responses.b2rImpair === true &&
              user.responses.b2rSubstantial === true &&
              user.responses.b2rSubstantial === true &&
              user.responses.b3aPerform === true ? (
                <Card.Text>
                  It looks like you are a qualified person with a disability
                  under the ADA and have the right to request reasonable
                  accommodation.
                </Card.Text>
              ) : user.responses.b2rImpair === false ||
                user.responses.b2rSubstantial === false ||
                user.responses.b2rSubstantial === false ||
                user.responses.b3aPerform === false ? (
                <Card.Text>
                  It looks like you are not a qualified person with a disability
                  under the ADA and do not have the right to request reasonable
                  accommodation. If you need assistance understanding how the
                  ADA defines disability, please contact the ADA National
                  Network for free assistance from a support specialist.
                </Card.Text>
              ) : (
                <Card.Text>
                  It looks like you have not filled out the required sections to
                  determine if you are a qualified person with a disability
                  under the ADA. Please be sure to complete the previous
                  sections before this one.
                </Card.Text>
              )}
            </Card.Body>
          </Card>
          {user.responses.b2rImpair === true &&
            user.responses.b2rSubstantial === true &&
            user.responses.b2rSubstantial === true &&
            user.responses.b3aPerform === true && (
              <>
                <p>
                  You identified the following reasonable accommodations that
                  you think will help you perform your job:
                  <ol>
                    {user.jobTasks &&
                      user.jobTasks.map((t) => {
                        return (
                          t.jobTasksSolution &&
                          t.jobTasksDifficult && (
                            <li>
                              An accommodation such as {t.jobTasksSolution}{" "}
                              could support my ability to {t.jobTasksDifficult}
                            </li>
                          )
                        );
                      })}
                  </ol>
                </p>
                {user.responses.b5aGrade && (
                  <p>
                    You gave your workplace climate{" "}
                    {grades[Number(user.responses.b5aGrade) + 1]} grade.
                  </p>
                )}
                {user.responses.b6aRisks && (
                  <>
                    <p>
                      You wrote the following about your personal risk
                      tolerance:
                      <ul>
                        <li>{user.responses.b6aRisks}</li>
                      </ul>
                    </p>
                  </>
                )}
                <p>
                  Based on your answers to the notes above choose a statement
                  below that best reflects your current situation and feelings.
                </p>
                <Form
                  style={{ margin: "0 auto", maxWidth: "30em" }}
                  onSubmit={handleSubmit}
                >
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="radio"
                      name={"bDecide"}
                      id={"bDecide1"}
                      onChange={(e) => onInput(e)}
                      value={1}
                      label="I know that I need an accommodation and I feel good about moving forward to make a request."
                      checked={user.responses["bDecide"] == 1}
                    />
                    <Form.Check
                      type="radio"
                      name={"bDecide"}
                      id={"bDecide2"}
                      onChange={(e) => onInput(e)}
                      value={2}
                      label="I know that I need an accommodation, but I have lots of concerns about what may happen if I ask for one."
                      checked={user.responses["bDecide"] == 2}
                    />
                    <Form.Check
                      type="radio"
                      name={"bDecide"}
                      id={"bDecide3"}
                      onChange={(e) => onInput(e)}
                      value={3}
                      label="I know that I need an accommodation, but I’m not sure I have the energy to do the additional work involved."
                      checked={user.responses["bDecide"] == 3}
                    />
                    <Form.Check
                      type="radio"
                      name={"bDecide"}
                      id={"bDecide4"}
                      onChange={(e) => onInput(e)}
                      value={4}
                      label="I don’t think requesting an accommodation is the approach I want to take right now."
                      checked={user.responses["bDecide"] == 4}
                    />
                  </Form.Group>
                </Form>

                {user.responses.bDecide == 1 ? (
                  <Card className="popout">
                    <Card.Body>
                      Great! You are in the right place. If you continue through
                      this decision aid, you will learn more about the process
                      and at the end will have prepared a written request for
                      accommodation.
                    </Card.Body>
                  </Card>
                ) : user.responses.bDecide == 2 ? (
                  <Card className="popout">
                    <Card.Body>
                      Completely understandable. The next couple of sections of
                      this decision aid provide more information about
                      disability rights, accommodations, and potential ways to
                      address workplace discrimination. This information may
                      help you make your decision about whether to move forward
                      to make an accommodation request
                    </Card.Body>
                  </Card>
                ) : user.responses.bDecide == 3 ? (
                  <Card className="popout">
                    <Card.Body>
                      Completely understandable. This may be a good time to
                      think about your support network. Is there anyone who
                      could help you with the process—like a vocational
                      counselor, a trusted friend, or a family member? Sometimes
                      just having someone to talk with can help as you think
                      through the process
                    </Card.Body>
                  </Card>
                ) : (
                  user.responses.bDecide == 4 && (
                    <Card className="popout">
                      <Card.Body>
                        This is a completely valid decision. You must do what is
                        right for you right now. You can always change your mind
                        and come back later if you decide to move forward.
                      </Card.Body>
                    </Card>
                  )
                )}
              </>
            )}
        </>
      )}
    </div>
  );
};

export default Decision;
