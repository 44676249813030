import { Link } from "react-router-dom";
import React, { useState } from "react";
import CloseButton from "react-bootstrap/CloseButton";

import { BsChevronRight } from "react-icons/bs";

const SidebarContent = ({ toggleSidebar }) => {
  const lra = (set, sec) => {
    return (
      <ul
        className={
          vis[set + sec] == true
            ? "showBar sb inner"
            : "showBar hideBar sb inner"
        }
      >
        <Link to={"/" + set + "/section" + sec + "/learn"}>
          <li>Learn</li>
        </Link>
        <Link to={"/" + set + "/section" + sec + "/reflect"}>
          <li>Reflect</li>
        </Link>
        <Link to={"/" + set + "/section" + sec + "/apply"}>
          <li>Apply</li>
        </Link>
      </ul>
    );
  };

  const [vis, setVis] = useState({ before1: false });

  const handleVis = (id, e) => {
    console.log(e.target.nodeName);
    if (e.target.nodeName != "LI") {
      const oldVal = vis[id];
      setVis((oldVis) => ({ ...oldVis, [id]: !oldVal }));
    }
  };

  return (
    <nav role="navigation" className="sideNav" aria-labelledby="Sidebar">
      <ul className="sb">
        <li
          style={{
            backgroundColor: "#322d30",
            fontSize: "24px",
            paddingBlock: "5px",
            paddingLeft: "0px",
          }}
          onClick={toggleSidebar}
        >
          <CloseButton style={{ verticalAlign: "middle" }} variant="white" />
          <span style={{ paddingLeft: "5px", verticalAlign: "middle" }}>
            My RA Request
          </span>
        </li>
        <Link to="/">
          <li className="l1">Home</li>
        </Link>
        <Link to="/intro">
          <li className="l1">Introduction</li>
        </Link>
        <li>
          <div
            className="l1"
            id="before"
            onClick={(e) => handleVis("before", e)}
          >
            Before the Request
            <BsChevronRight
              className={
                vis.before == true ? "chevron chevDown" : "chevron chevRight"
              }
            />
          </div>
          <ul
            className={vis.before == true ? "showBar sb" : "showBar hideBar sb"}
          >
            <li
              className="l2"
              id="before1"
              onClick={(e) => handleVis("before1", e)}
            >
              <span style={{ alignItems: "space-between", display: "flex" }}>
                <span>What is disability?</span>
                <span>
                  <BsChevronRight
                    className={
                      vis["before1"] == true
                        ? "chevron2 chevDown2"
                        : "chevron2 chevRight2"
                    }
                  />
                </span>
              </span>
              {lra("before", 1)}
            </li>
            <li
              className="l2"
              id="before2"
              onClick={(e) => handleVis("before2", e)}
            >
              <span style={{ alignItems: "space-between", display: "flex" }}>
                <span>How is disability defined under the ADA?</span>
                <span>
                  <BsChevronRight
                    className={
                      vis["before2"] == true
                        ? "chevron2 chevDown2"
                        : "chevron2 chevRight2"
                    }
                  />
                </span>
              </span>
              {lra("before", 2)}
            </li>
            <li
              className="l2"
              id="before3"
              onClick={(e) => handleVis("before3", e)}
            >
              <span style={{ alignItems: "space-between", display: "flex" }}>
                <span>Am I a qualified individual with a disability?</span>
                <span>
                  <BsChevronRight
                    className={
                      vis["before3"] == true
                        ? "chevron2 chevDown2"
                        : "chevron2 chevRight2"
                    }
                  />
                </span>
              </span>
              {lra("before", 3)}
            </li>
            <li
              className="l2"
              id="before4"
              onClick={(e) => handleVis("before4", e)}
            >
              <span style={{ alignItems: "space-between", display: "flex" }}>
                <span>What are reasonable accomodations under the ADA?</span>
                <span>
                  <BsChevronRight
                    className={
                      vis["before4"] == true
                        ? "chevron2 chevDown2"
                        : "chevron2 chevRight2"
                    }
                  />
                </span>
              </span>
              {lra("before", 4)}
            </li>
            <li
              className="l2"
              id="before5"
              onClick={(e) => handleVis("before5", e)}
            >
              <span style={{ alignItems: "space-between", display: "flex" }}>
                <span>What are risks of disability disclosure?</span>
                <span>
                  <BsChevronRight
                    className={
                      vis["before5"] == true
                        ? "chevron2 chevDown2"
                        : "chevron2 chevRight2"
                    }
                  />
                </span>
              </span>
              {lra("before", 5)}
            </li>
            <li
              className="l2"
              id="before6"
              onClick={(e) => handleVis("before6", e)}
            >
              <span style={{ alignItems: "space-between", display: "flex" }}>
                <span>Understanding personal risk tolerance</span>
                <span>
                  <BsChevronRight
                    className={
                      vis["before6"] == true
                        ? "chevron2 chevDown2"
                        : "chevron2 chevRight2"
                    }
                  />
                </span>
              </span>
              {lra("before", 6)}
            </li>
            <Link to="/before/section7/decide">
              <li className="l2">Make a Decision</li>
            </Link>
          </ul>
        </li>
        <li>
          <div
            className="l1"
            id="during"
            onClick={(e) => handleVis("during", e)}
          >
            Making the Request
            <BsChevronRight
              className={
                vis.during == true ? "chevron chevDown" : "chevron chevRight"
              }
            />
          </div>
          <ul
            className={vis.during == true ? "showBar sb" : "showBar hideBar sb"}
          >
            <li
              className="l2"
              id="during1"
              onClick={(e) => handleVis("during1", e)}
            >
              <span style={{ alignItems: "space-between", display: "flex" }}>
                <span>
                  How can I make the strongest request for accommodations?
                </span>
                <span>
                  <BsChevronRight
                    className={
                      vis["during1"] == true
                        ? "chevron2 chevDown2"
                        : "chevron2 chevRight2"
                    }
                  />
                </span>
              </span>
              {lra("during", 1)}
            </li>
          </ul>
        </li>
        <li>
          <div className="l1" id="after" onClick={(e) => handleVis("after", e)}>
            After the Request
            <BsChevronRight
              className={
                vis.after == true ? "chevron chevDown" : "chevron chevRight"
              }
            />
          </div>
          <ul
            className={vis.after == true ? "showBar sb" : "showBar hideBar sb"}
          >
            <li
              className="l2"
              id="after1"
              onClick={(e) => handleVis("after1", e)}
            >
              <span style={{ alignItems: "space-between", display: "flex" }}>
                <span>What challenges can occur?</span>
                <span>
                  <BsChevronRight
                    className={
                      vis["after1"] == true
                        ? "chevron2 chevDown2"
                        : "chevron2 chevRight2"
                    }
                  />
                </span>
              </span>
              {lra("after", 1)}
            </li>
            <li
              className="l2"
              id="after2"
              onClick={(e) => handleVis("after2", e)}
            >
              <span style={{ alignItems: "space-between", display: "flex" }}>
                <span>What else should I do if my request is approved?</span>
                <span>
                  <BsChevronRight
                    className={
                      vis["after2"] == true
                        ? "chevron2 chevDown2"
                        : "chevron2 chevRight2"
                    }
                  />
                </span>
              </span>
              {lra("after", 2)}
            </li>
            <li
              className="l2"
              id="after3"
              onClick={(e) => handleVis("after3", e)}
            >
              <span style={{ alignItems: "space-between", display: "flex" }}>
                <span>What if I’m offered a different RA than requested?</span>
                <span>
                  <BsChevronRight
                    className={
                      vis["after3"] == true
                        ? "chevron2 chevDown2"
                        : "chevron2 chevRight2"
                    }
                  />
                </span>
              </span>
              {lra("after", 3)}
            </li>
            <li
              className="l2"
              id="after4"
              onClick={(e) => handleVis("after4", e)}
            >
              <span style={{ alignItems: "space-between", display: "flex" }}>
                <span>What if my request is denied?</span>
                <span>
                  <BsChevronRight
                    className={
                      vis["after4"] == true
                        ? "chevron2 chevDown2"
                        : "chevron2 chevRight2"
                    }
                  />
                </span>
              </span>
              {lra("after", 4)}
            </li>
            <li
              className="l2"
              id="after5"
              onClick={(e) => handleVis("after5", e)}
            >
              <span style={{ alignItems: "space-between", display: "flex" }}>
                <span>
                  What happens later if I need a different or additional RA?
                </span>
                <span>
                  <BsChevronRight
                    className={
                      vis["after5"] == true
                        ? "chevron2 chevDown2"
                        : "chevron2 chevRight2"
                    }
                  />
                </span>
              </span>
              {lra("after", 5)}
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default SidebarContent;
