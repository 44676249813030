import Accordion from "react-bootstrap/Accordion";
import React, { useEffect } from "react";

const Learn = ({ setFeedback }) => {
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h2>Section 3</h2>
      <h3>Learn: Am I a qualified individual with a disability?</h3>
      <p>
        Under Title I of the ADA, an employer must provide{" "}
        <strong>
          <em>reasonable accommodation</em>
        </strong>{" "}
        to{" "}
        <strong>
          <em>qualified individuals with disabilities</em>
        </strong>{" "}
        who are{" "}
        <strong>
          <em>employees or applicants for employment</em>
        </strong>
        , unless to do so would cause{" "}
        <strong>
          <em>undue hardship</em>
        </strong>
        .
      </p>
      <p>
        It is important to remember that the ADA is an equal opportunity law.
        The ADA does not promise you a job, but if you have the skills and
        capacity to perform the ‘essential functions’ of a job—in other words,
        you are ‘qualified’—then the ADA mandates that employers provide
        reasonable accommodations to remove barriers that interfere with your
        ability to perform the work.
      </p>
      <h4>
        What does it mean to be ‘a qualified individual with a disability’ in
        employment?
      </h4>
      <p>
        The ADA regulations define a qualified individual with a disability as a
        person with a disability who: "satisfies the requisite skill,
        experience, education and other job-related requirements of the
        employment position…and who, with or without reasonable accommodation,
        can perform the essential functions of such position." To summarize, a
        qualified individual:
      </p>
      <ol>
        <li>Meets prerequisites for the job.</li>
        <li>
          Can perform the essential functions of the job with reasonable
          accommodations.
        </li>
      </ol>
      <Accordion flush alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5>What are the prerequisites for my job?</h5>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Every job has required competencies. These include educational
              level, years of work experience, specific training, skills,
              licenses, and certificates. Some job requirements are based on the
              evaluation of an interviewer or employer, like good judgement,
              ability to work with other people, capacity to multitask, and so
              on. Prerequisites for a job should be in the hiring statement or
              position description.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <h5>What are the “essential functions” of my job?</h5>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              The ADA defines essential functions of a job as the{" "}
              <strong>fundamental</strong> duties. There may be other ‘marginal’
              duties that are asked of an employee, but these do not count as
              essential. Duties may be considered essential when:
            </p>
            <ul>
              <li>
                The job was created to perform that function, for example, a
                position is created for a floating supervisor who will
                substitute for other supervisors across shifts—moving across
                shifts is considered essential.
              </li>
              <li>
                Only a few employees are available who can do that job function,
                for example, a small company has only three employees, so they
                all must be able to answer the phone and respond to emails.
              </li>
              <li>
                The function is highly specialized, and the employee was
                specifically hired to perform that function, for example, a
                salesperson is hired who must fluently speak Japanese to expand
                a company’s market in Japan.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Learn;
