import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import sendValues from "../../SendValues.js";
import SignUpAlert from "../../SignUpAlert.js";

function Reflect({ authToken, user, setUser, setFeedback }) {
  const onInput = ({ target: { value, name } }) => {
    if (value === "true" || value === "false") {
      setUser({
        ...user,
        responses: { ...user.responses, [name]: value === "true" },
      });
    } else {
      setUser({ ...user, responses: { ...user.responses, [name]: value } });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
  };

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h3>Reflect: How should I request an RA in my specific workplace?</h3>
      <p>
        In this section, you will do the research necessary to prepare your
        request. Below are some activities that will help you prepare your
        request.
      </p>
      {!user ? (
        <SignUpAlert />
      ) : (
        <Form
          style={{ margin: "0 auto", maxWidth: "30em" }}
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-3">
            <Form.Group className="mb-3">
              <Form.Label>
                Locate and review your company policy, if there is one,
                regarding procedures for requesting accommodations. Does a
                company policy exist?
              </Form.Label>
              <Form.Check
                type="radio"
                name="d1rPolicy"
                id="d1rPolicyTrue"
                onChange={(e) => onInput(e)}
                value={true}
                label="Yes"
                checked={user.responses.d1rPolicy}
              />
              <Form.Check
                type="radio"
                name="d1rPolicy"
                id="d1rPolicyFalse"
                onChange={(e) => onInput(e)}
                value={false}
                label="No"
                checked={user.responses.d1rPolicy === false}
              />
            </Form.Group>

            {user.responses.d1rPolicy === true && (
              <Form.Group>
                <Form.Label>
                  Write out the steps in the company policy.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="d1rSteps"
                  onChange={(e) => onInput(e)}
                  value={user.responses.d1rSteps}
                />
              </Form.Group>
            )}
            {user.responses.d1rPolicy === false && (
              <Form.Group className="mb-3">
                <Form.Label>
                  Who do you want to approach to learn about the process of
                  requesting an accommodation?
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="d1rApproach"
                  onChange={(e) => onInput(e)}
                  value={user.responses.d1rApproach}
                />
              </Form.Group>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Think about how you want to make the request. Do you want to make
              it formally or informally? Do you want to talk with your direct
              supervisor, or would you prefer to talk with someone in Human
              Resources?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="d1rTalk"
              onChange={(e) => onInput(e)}
              value={user.responses.d1rTalk}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Think about your disability. Do you think you will need medical
              documentation?
            </Form.Label>
            <Form.Check
              type="radio"
              name="d1rMedical"
              id="d1rMedicalTrue"
              onChange={(e) => onInput(e)}
              value={true}
              label="Yes"
              checked={user.responses.d1rMedical}
            />
            <Form.Check
              type="radio"
              name="d1rMedical"
              id="d1rMedicalFalse"
              onChange={(e) => onInput(e)}
              value={false}
              label="No"
              checked={user.responses.d1rMedical === false}
            />
          </Form.Group>
          {user.responses.d1rMedical === true && (
            <React.Fragment>
              <Form.Group className="mb-3">
                <Form.Label>What documentation do you need?</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="d1rDocumentation"
                  onChange={(e) => onInput(e)}
                  value={user.responses.d1rDocumentation}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Where will you go to get the documentation?
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="d1rGo"
                  onChange={(e) => onInput(e)}
                  value={user.responses.d1rGo}
                />
              </Form.Group>
            </React.Fragment>
          )}
          <Form.Label>
            Think about what to do if the conversation goes off track.
          </Form.Label>
          <Form.Group className="mb-3">
            <Form.Label>How will you respond to your supervisor?</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="d1rSupervisor"
              onChange={(e) => onInput(e)}
              value={user.responses.d1rSupervisor}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>How will you respond to your coworkers?</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="d1rCoworkers"
              onChange={(e) => onInput(e)}
              value={user.responses.d1rCoworkers}
            />
          </Form.Group>
        </Form>
      )}
    </div>
  );
}

export default Reflect;
