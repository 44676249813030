const Grant = () => {
  return (
    <div>
      <h2>Grant Disclaimer</h2>
      <p>
        The content of this website was developed under a grant from the
        National Institute on Disability, Independent Living, and Rehabilitation
        Research (NIDILRR grant number 90RTEM0001). NIDILRR is a Center within
        the Administration for Community Living (ACL), Department of Health and
        Human Services (HHS). The contents of this website do not necessarily
        represent the policy of NIDILRR, ACL, HHS, and you should not assume
        endorsement by the Federal Government.
      </p>
    </div>
  );
};

export default Grant;
