import "./App.css";
import Home from "./Home.js";
import Error from "./Error.js";
import Step from "./modules/Step.js";
import SignUp from "./auth/SignUp.js";
import SignIn from "./auth/SignIn.js";
import SignOut from "./auth/SignOut.js";
import Intro from "./Intro.js";
import Help from "./Help.js";
import Grant from "./Grant.js";
import Accessibility from "./Accessibility.js";
import Reports from "./reports/Reports.js";
import Conclusion from "./Conclusion.js";
import Settings from "./Settings.js";
import RequestPasswordReset from "./auth/RequestPasswordReset.js";
import PasswordReset from "./auth/PasswordReset.js";

import useToken from "./useToken.js";

import RootLayout from "./layouts/RootLayout.js";

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import React, { useState, useEffect } from "react";

function App() {
  // Full user data
  const [user, setUser] = useState();

  // Full user data
  const { token, setToken } = useToken();

  // Waits to get user if necessary before loading the page
  const [loading, setLoading] = useState(true);

  // HTTP request to get the user given the PHP Session
  const getUser = async () => {
    const handleData = async (endpoint) => {
      const response = await fetch(
        "https://depts.washington.edu/adakt/ra-tool/api.php" + endpoint,
        {
          method: "GET",
          headers: new Headers({
            "X-Api-Key": token,
          }),
        }
      );
      if (response.status >= 300) {
        const error = await response.text();
        console.log(error);
        return;
      }
      const values = await response.json();
      return values;
    };
    let user = await handleData("/records/users");
    let responses = await handleData("/records/responses");
    let tasks = await handleData("/records/jobTasks");
    let steps = await handleData("/records/reqSteps");
    let ents = await handleData("/records/entries");

    if (!user || !responses || !tasks || !steps || !ents) {
      return null;
    }

    return {
      ...user.records[0],
      responses: responses.records[0] ? responses.records[0] : {},
      jobTasks: tasks.records,
      reqSteps: steps.records,
      entries: ents.records,
    };
  };

  // Searches for Cookie on startup and sets the user, fetches data.
  useEffect(() => {
    /*async function fetchData() {
      let hasCookie = document.cookie.split(";").some((cookie) => {
        return cookie.split("=")[0].trim() == "PHPSESSID";
      });
      if (hasCookie && 1 == 0) {
        setUser(await getUser());
      }
      setLoading(false);
    }*/
    async function fetchData() {
      if (token) {
        const userNew = await getUser();
        setUser(userNew);
        if (!userNew) {
          setToken(null);
          alert("You have been signed out due to inactivity!");
        }
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  // pages
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout user={user} setUser={setUser} />}>
        <Route index element={<Home />} />
        <Route exact path="/intro/" element={<Intro />} />
        <Route exact path="/conclusion" element={<Conclusion />} />
        <Route exact path="/help/" element={<Help />} />
        <Route exact path="/grant/" element={<Grant />} />
        <Route exact path="/accessibility/" element={<Accessibility />} />
        <Route exact path="/pwreset/" element={<RequestPasswordReset />} />
        <Route
          exact
          path="/pwreset/:key"
          element={<PasswordReset setToken={setToken} setUser={setUser} />}
        />
        <Route
          exact
          path="/settings/"
          element={
            <Settings
              user={user}
              setUser={setUser}
              token={token}
              setToken={setToken}
            />
          }
        />
        <Route
          exact
          path="/:module/:section/:step/"
          element={<Step user={user} authToken={token} setUser={setUser} />}
        />
        <Route
          exact
          path="/signup/"
          element={<SignUp setToken={setToken} setUser={setUser} />}
        />
        <Route
          exact
          path="/signin/"
          element={<SignIn setToken={setToken} setUser={setUser} />}
        />
        <Route
          exact
          path="/reports/"
          element={<Reports user={user} setUser={setUser} />}
        />
        <Route
          exact
          path="/signout/"
          element={<SignOut setToken={setToken} setUser={setUser} />}
        />
        <Route path="*" element={<Error />} />
      </Route>
    )
  );

  return (
    <React.Fragment>
      {!loading && <RouterProvider router={router} />}
    </React.Fragment>
  );
}

export default App;
