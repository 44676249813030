import { Outlet, ScrollRestoration } from "react-router-dom";
import Sidebar from "react-sidebar";
import SidebarContent from "../SidebarContent.js";
import Header from "../Header.js";
import Footer from "../Footer.js";
import React, { useState, useEffect } from "react";
import ScrollToTop from "../modules/ScrollToTop.js";

export default function RootLayout({ user }) {
  // Screen width
  const [winWidth, detectW] = useState(window.innerWidth);

  const detectWidth = () => {
    detectW(window.innerWidth);
    if (winWidth <= 250 + 600 && docked) {
      setDocked(false);
      setOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", detectWidth);

    return () => {
      window.removeEventListener("resize", detectWidth);
    };
  }, [winWidth]);

  // Sidebar
  const [open, setOpen] = useState(false);
  const [docked, setDocked] = useState(false);

  const toggleSidebar = () => {
    if (docked) {
      setDocked(!docked);
    } else if (open) {
      setOpen(!open);
    } else if (winWidth > 600) {
      setDocked(!docked);
    } else {
      setOpen(!open);
    }
  };
  return (
    <div className="root-layout">
      <Sidebar
        sidebar={<SidebarContent toggleSidebar={toggleSidebar} />}
        open={open}
        onSetOpen={setOpen}
        docked={docked}
        styles={{
          sidebar: {
            background: "white",
            width: "250px",
            scrollbarWidth: "none",
            overflowStyle: "none",
            MsOverflowStyle: "none",
          },
          root: {
            overflow: "visible",
          },
        }}
      >
        <div>
          <header
            style={{
              position: "sticky",
              top: "0",
              width: "100%",
            }}
          >
            <Header user={user} toggleSidebar={toggleSidebar} />
          </header>
          <body className="bodys">
            <main
              style={{
                margin: "0 auto",
                maxWidth: "35em",
                paddingInline: "1em",
                //paddingTop: "max(calc(7vh + 10px), 60px)",
              }}
            >
              <ScrollToTop />
              <Outlet />
            </main>
          </body>
          <footer>
            <Footer />
          </footer>
        </div>
      </Sidebar>
    </div>
  );
}
