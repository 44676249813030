import Form from "react-bootstrap/Form";
import React from "react";
import SignUpAlert from "../../SignUpAlert.js";
import * as ft from "../../functions/formTracking.js";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

function Apply({ authToken, user, setUser }) {
  const onInput = ({ target: { value, name } }) => {
    setUser({ ...user, [name]: value });
  };

  console.log(user);

  return (
    <div>
      <h3>Apply: How should I address those challenges in my workplace?</h3>
      <h4>Managing co-workers, colleagues or supervisors’ expectations</h4>
      <p>
        While it is not your responsibility to explain or justify needing or
        having a reasonable accommodation to co-workers, the reality is that
        once you have disclosed (intentionally or unintentionally) having a
        disability, others may wonder to what degree this will impact your work
        performance overall.
      </p>
      {!user ? (
        <SignUpAlert />
      ) : !user.jobTasks[0] ? (
        <div>
          <h3>
            Complete{" "}
            <Link to="/before/section3/reflect">your list of job tasks</Link> to
            finish this section!
          </h3>
        </div>
      ) : (
        <div>
          <p>
            If you anticipate that others will become aware of your
            accommodation (or request for accommodation) at work, it can be
            helpful to prepare a few talking points to guide discussions with
            colleagues and co-workers. Preparing for those conversations before
            they occur will allow you to decide what information, if any, you
            want to share with your colleagues and help you to manage their
            expectations about your continued performance at work.
          </p>
          <Card className="examples">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>
                    Example: office receptionist who has requested an
                    accommodation for chronic back pain
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <h4>Job Functions</h4>
                  <p>
                    <strong>
                      Be at front desk to answer company phone, greet clients
                      and be point person for deliveries and couriers
                    </strong>
                  </p>
                  <ul>
                    <li>
                      Cannot sit for long periods of time; require a standing
                      desk from which to greet clients
                    </li>
                    <li>
                      Require two short breaks (7mins) in mornings and
                      afternoons to perform physical therapy stretches
                    </li>
                  </ul>
                  <hr />
                  <p>
                    <strong>
                      Making copies of documents; keeping copy and mail room
                      stocked with paper, toner, mailing supplies
                    </strong>
                  </p>
                  <ul>
                    <li>
                      Cannot lift more than 20 lbs; require assistance moving
                      boxes of paper reams when delivered
                    </li>
                  </ul>
                  <hr />
                  <p>
                    <strong>Data entry into company computer system</strong>
                  </p>
                  <ul>
                    <li>
                      Cannot sit for long periods of time; require standing desk
                      for computer work
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card>
          <Form>
            <Form.Label></Form.Label>
          </Form>
        </div>
      )}
      <h4>Discriminatory practices or hostile work environments</h4>
      <p>
        Working in a hostile or discriminatory environment can be harmful to
        both physical and mental health. It is important to start by letting
        your HR representative or employer know that you are experiencing
        discomfort in the workplace and report discriminatory interactions to
        your HR manager or employer. If discussing these behaviors with your
        employer does not resolve the problem, you may decide to report the
        discrimination to an outside agency to seek help. For additional
        information on reporting disability discrimination in the work place,
        see the{" "}
        <a
          target="_blank"
          href="https://www.eeoc.gov/filing-charge-discrimination"
        >
          Equal Employment and Opportunity Commission website
        </a>
      </p>
    </div>
  );
}

export default Apply;
