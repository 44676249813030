import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import sendValues from "../../SendValues.js";
import SignUpAlert from "../../SignUpAlert.js";
import Accordion from "react-bootstrap/Accordion";
import * as ft from "../../functions/formTracking.js";

function Apply({ authToken, user, setUser, setFeedback }) {
  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h3>Apply: How do I prepare for my request?</h3>
      {!user ? (
        <SignUpAlert />
      ) : (
        <>
          <p>
            Now that you have thought about the process for requesting an
            accommodation in your workplace, it is time to finalize your plan.
            Here’s what you said on the previous page:
          </p>
          <ol>
            {user.responses.d1rPolicy === true ? (
              <li>
                My company does have a policy and the steps are as follows:
                <ol>
                  <li>{user.responses.d1rSteps || "No response given"}</li>
                </ol>
              </li>
            ) : (
              <li>
                My company does not have a policy and I will approach the
                following person to learn about the process:
                <ol>
                  <li>{user.responses.d1rApproach || "No response given"}</li>
                </ol>
              </li>
            )}
            <li>
              I decided I would make my request as follows:
              <ol>
                {user.responses.d1rMedical === true ? (
                  <li>
                    I will need medical documentation:
                    <ol>
                      <li>
                        {user.responses.d1rDocumentation || "No response given"}
                      </li>
                      <li>{user.responses.d1rGo || "No response given"}</li>
                    </ol>
                  </li>
                ) : (
                  <li>I will not need medical documentation.</li>
                )}
              </ol>
            </li>
            <li>
              If the conversation goes off track, I will respond to supervisors
              and coworkers:
              <ol>
                <li>{user.responses.d1rSupervisor || "No response given"}</li>
                <li>{user.responses.d1rCoworkers || "No response given"}</li>
              </ol>
            </li>
          </ol>
          <Form style={{ margin: "0 auto", maxWidth: "30em" }}>
            <Form.Group
              className="mb-3"
              style={{ margin: "0 auto", maxWidth: "30em" }}
            >
              <Form.Label style={{ marginBottom: "30px" }}>
                Thinking about your reflections, identify the steps you will
                take to request a reasonable accommodation in your workplace:
              </Form.Label>
              <Accordion flush>
                {user.reqSteps
                  .filter((stepd) => {
                    return stepd.deleted != true;
                  })
                  .map((step) => {
                    return (
                      <Accordion.Item key={step.ID} eventKey={step.ID}>
                        <Accordion.Header>
                          {step.reqStepsStep == ""
                            ? "New step"
                            : step.reqStepsStep}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Form.Label>Step name:</Form.Label>
                          <Form.Control
                            type="text"
                            name={step.ID}
                            onChange={(evt) => {
                              ft.handleStepNameChange(
                                step.ID,
                                evt,
                                user,
                                setUser
                              );
                            }}
                            value={step.reqStepsStep}
                            placeholder="Step name"
                          />
                          <Form.Label>Step Description:</Form.Label>
                          <Form.Control
                            type="text"
                            name={step.reqStepsStep + "Description"}
                            onChange={(evt) => {
                              ft.handleStepPropChange(
                                step.ID,
                                "reqStepsStepDescription",
                                evt,
                                user,
                                setUser
                              );
                            }}
                            value={
                              user.reqSteps.find((stepSaved) => {
                                return stepSaved.ID === step.ID;
                              })["reqStepsStepDescription"]
                            }
                          />
                          <Button
                            variant="outline-danger"
                            size="sm"
                            type="button"
                            onClick={() => {
                              ft.handleRemoveStep(step.ID, user, setUser);
                            }}
                          >
                            Remove step
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
              </Accordion>
              <Button
                type="button"
                onClick={() => {
                  ft.handleAddStep(user, setUser);
                }}
              >
                Add step
              </Button>
            </Form.Group>
          </Form>
        </>
      )}
    </div>
  );
}

export default Apply;
