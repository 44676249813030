import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useNavigate } from "react-router-dom";

const PasswordReset = ({ setToken, setUser }) => {
  let { key } = useParams();
  const [values, setValues] = useState({ email: "", password: "" });
  const [feedback, setFeedback] = useState();
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const onInput = ({ target: { value, name } }) => {
    setValues((oldValues) => ({ ...oldValues, [name]: value }));
    setValidated(false);
  };

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);
    e.preventDefault();

    const sendData = {
      resetkey: key,
      newpassword: values.password,
    };

    let formData = new FormData();
    for (var keySend in sendData) {
      formData.append(keySend, sendData[keySend]);
    }

    const response = await fetch(
      "https://depts.washington.edu/adakt/ra-tool/url.php/reset",
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.status >= 300) {
      const error = await response.json();
      console.log(error);
      setFeedback({
        heading: "Error updating password!",
        text: "Code " + error.code + ": " + error.message,
        status: error.code,
      });
      return;
    }
    const res = await response;

    const user = await res.json();

    if (!user.error) {
      setToken(user.apikey);

      const handleData = async (endpoint) => {
        const response = await fetch(
          "https://depts.washington.edu/adakt/ra-tool/api.php" + endpoint,
          {
            method: "GET",
            headers: new Headers({
              "Content-Type": "application/json",
              "X-Api-Key": user.apikey,
            }),
          }
        );
        if (response.status >= 300) {
          const error = await response.json();
          console.log(error);
          setFeedback({
            heading: "Error signing in!",
            text: "Code " + error.code + ": " + error.message,
            status: error.code,
          });
          return;
        }
        const values = await response.json();
        return values;
      };

      let responses = await handleData("/records/responses");
      let tasks = await handleData("/records/jobTasks");
      let steps = await handleData("/records/reqSteps");
      let ents = await handleData("/records/entries");

      const userData = {
        ...user,
        responses: responses.records[0],
        jobTasks: tasks.records,
        reqSteps: steps.records,
        entries: ents.records,
      };
      setUser(userData);
      navigate("/");
    }
  };

  return (
    <>
      <h1>Password reset</h1>
      <h3>Use this page to reset your password.</h3>
      <Form
        style={{ margin: "0 auto", maxWidth: "30em" }}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            name="password"
            onChange={(e) => onInput(e)}
            value={values.password}
            placeholder="Password"
          />
          <Form.Control.Feedback type="invalid">
            Please enter a new password.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Retype Password</Form.Label>
          <Form.Control
            required
            type="password"
            name="repeatPassword"
            onChange={(e) => onInput(e)}
            value={values.repeatPassword}
            placeholder="Retype password"
            pattern={values.password}
          />
          <Form.Control.Feedback type="invalid">
            Passwords must match
          </Form.Control.Feedback>
        </Form.Group>
        {!validated && (
          <Button style={{ marginTop: "20px" }} variant="primary" type="submit">
            Reset Password
          </Button>
        )}
      </Form>
      {feedback && (
        <Alert
          style={{ marginTop: "1em" }}
          variant={feedback.status >= 300 ? "danger" : "success"}
          onClose={() => setFeedback(null)}
          dismissible
        >
          <Alert.Heading>{feedback.heading}</Alert.Heading>
          <p>{feedback.text}</p>
        </Alert>
      )}
    </>
  );
};

export default PasswordReset;
