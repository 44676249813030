import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import getValues from "../../GetValues.js";
import sendValues from "../../SendValues.js";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import SignUpAlert from "../../SignUpAlert.js";

function Apply({ authToken, user, setUser, setFeedback }) {
  const handleTaskPropChange = (id, type, evt) => {
    if (evt) {
      const newTasks = user.jobTasks.map((task) => {
        if (id !== task.ID) return task;
        return { ...task, [type]: evt.target.value };
      });
      setUser({ ...user, jobTasks: newTasks });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(user.jobTasks, authToken, "/records/jobTasks")
    );
    const nTasks = await getValues(authToken, "/records/jobTasks");
    setUser({
      ...user,
      jobTasks: nTasks,
    });
  };

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h3>
        Apply: Identify the specific types of accommodations that would be best
        for you in your current situation.
      </h3>
      <p>
        <p>
          On the previous page you identified reasons why a task is difficult
          and brainstormed possible accommodations. On this page, you will
          decide about how you want to talk about the task difficulty with your
          employer and what specific accommodations you want to request. In the
          dropdowns below, you will see the comments you entered in the previous
          section.
        </p>
        <p>
          For the task difficulty sections, review them, and then rewrite or
          summarize them so they will be clear to your employer.
        </p>
        <p>
          For the accommodation sections, review the choices you entered and
          choose the accommodation or accommodations you want to request.
        </p>
      </p>
      {!user ? (
        <SignUpAlert />
      ) : !user.jobTasks[0] ? (
        <p>
          Complete <Link to="/before/section3/reflect">Section 3 Reflect</Link>{" "}
          to fill out this section
        </p>
      ) : (
        <Form
          style={{ margin: "0 auto", maxWidth: "30em" }}
          onSubmit={handleSubmit}
        >
          <Form.Label>
            Due to disability, one of my job tasks that is more difficult than
            it needs to be is:
          </Form.Label>
          <Accordion flush>
            {user.jobTasks.map((t) => {
              return (
                <Accordion.Item eventKey={t.jobTasksTask}>
                  <Accordion.Header>{t.jobTasksTask}</Accordion.Header>
                  <Accordion.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        This is {!t.jobTasksRequired && "not "}an essential
                        function of my job. The task is difficult because my
                        disability affects my ability to:
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name={t.jobTasksTask}
                        onChange={(evt) => {
                          handleTaskPropChange(t.ID, "jobTasksDifficult", evt);
                        }}
                        placeholder={t.jobTasksChallenges}
                        value={t.jobTasksDifficult ?? ""}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        I believe that an accommodation such as:{" "}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name={t.jobTasksTask}
                        onChange={(evt) => {
                          handleTaskPropChange(t.ID, "jobTasksSolution", evt);
                        }}
                        placeholder={t.jobTasksEasier}
                        value={t.jobTasksSolution ?? ""}
                      />
                      <Form.Label>
                        {" "}
                        would enable me to complete this task more efficiently.
                      </Form.Label>
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Form>
      )}
    </div>
  );
}

export default Apply;
