import { RiArrowLeftSLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const BackButton = ({ text, to }) => {
  return (
    <Link
      id="backLink"
      to={to}
      style={{
        fontWeight: "bold",
        fontSize: "1.6em",
        color: "black",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
      }}
    >
      <RiArrowLeftSLine
        id="backArrow"
        style={{
          fontSize: "4em",
          position: "relative",
          left: "-0.3em",
          marginRight: "-0.55em",
          transition: "0.2s ease-in",
        }}
      />
      <span style={{ transition: "0.2s ease-in" }}>{text}</span>
    </Link>
  );
};

export default BackButton;
