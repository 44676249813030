import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

const responses = [
  "strongly disagree",
  "disagree",
  "are neutral",
  "agree",
  "strongly agree",
];

const questions = [
  "I feel comfortable talking about the ways my disability affects me.",
  "I am confident that I can plainly explain how my disability affects my ability to do specific tasks.",
  "My disability does not significantly limit my social life.", //new
  "I believe that living with a disability provides me with insight that can supplement the perspectives of and is useful to my non-disabled colleagues.",
  "My disability does not prevent me from doing high quality work", //new
  "I have found support in and share information with a group of others who live with disability.",
  "I see commonalities between my experiences of stigma and the experiences of stigma facing others with disabilities that have different health conditions from my own.",
  "When requesting disability related access or accommodations, I primarily see myself as exercising my civil rights.",
  "My disability is an important part of who I am.", //new
  "My disability enriches my life.", //new
  "In general, I am satisfied with the quality of my life.", //new
];

const names = [
  "Comfort",
  "Explain",
  "Social", //new
  "Insight",
  "Quality", //new
  "Support",
  "Common",
  "Rights",
  "Important", //new
  "Enriches", //new
  "Satisfied", //new
];

const grades = ["an Unknown", "an A", "a B", "a C", "a D", "a F"];

const MyNotes = ({ user }) => {
  return (
    <Card className="popout">
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            What are my assumptions and beliefs about disability?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              When we asked you about your assumptions and beliefs about
              disability, you said the following:
              <ul>
                {questions.map((q, i) => {
                  return (
                    user.responses["b1r" + names[i]] && (
                      <li>
                        You {responses[user.responses["b1r" + names[i]] - 1]}{" "}
                        with the statement: {q}
                      </li>
                    )
                  );
                })}
              </ul>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            How do my assumptions and beliefs about disability affect my
            willingness to request a reasonable accommodation?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              When we asked how your assumptions and beliefs about disability
              affect your willingness to request a reasonable accommodation, you
              said the following:
            </p>
            {user.responses.b1aIdentity && (
              <div>
                <p>
                  <strong>
                    What does my disability mean to me? How do I identify?
                  </strong>
                </p>
                <p>{user.responses.b1aIdentity}</p>
              </div>
            )}
            {user.responses.b1aDisclosure && (
              <div>
                <p>
                  <strong>
                    Am I prepared to disclose my disability, at least for the
                    purpose or requesting accommodations?
                  </strong>
                </p>
                <p>{user.responses.b1aDisclosure}</p>
              </div>
            )}
            {user.responses.b1aComfort && (
              <div>
                <p>
                  <strong>
                    What do I need to do to make myself more comfortable with
                    the idea of disability—who can I talk with, what group or
                    groups could I connect with?
                  </strong>
                </p>
                <p>{user.responses.b1aComfort}</p>
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Do I have a disability as defined by the ADA?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              When we asked you to write out a statement describing your
              disability, you said the following:
            </p>
            {user.responses.b2rImpair !== null && (
              <div>
                <p>
                  You said{" "}
                  <strong>{user.responses.b2rImpair ? "yes" : "no"}</strong>{" "}
                  when we asked: “Do you have an impairment that limits a major
                  life activity or body system functions?”
                </p>
                {user.responsesb2rImpairNotes && (
                  <div>
                    <p>You said you meet this definition because:</p>
                    <p>{user.responses.b2rImpairNotes}</p>
                  </div>
                )}
              </div>
            )}
            {user.responses.b2rRecord !== null && (
              <div>
                <p>
                  You said{" "}
                  <strong>{user.responses.b2rRecord ? "yes" : "no"}</strong>{" "}
                  when we asked: “Do you have a record of having an impairment?”
                </p>
                {user.responsesb2rRecordNotes && (
                  <div>
                    <p>You said you meet this definition because:</p>
                    <p>{user.responses.b2rRecordNotes}</p>
                  </div>
                )}
              </div>
            )}
            {user.responses.b2rRegarded !== null && (
              <div>
                <p>
                  You said{" "}
                  <strong>{user.responses.b2rRegarded ? "yes" : "no"}</strong>{" "}
                  when we asked: “Are you regarded as having an impairment?”
                </p>
                {user.responses.b2rRegardedNotes && (
                  <div>
                    <p>You said you meet this definition because:</p>
                    <p>{user.responses.b2rRegardedNotes}</p>
                  </div>
                )}
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            How do I qualify as a person with a disability under the ADA?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              When we asked you to write a statement defining how you qualify as
              a person with a disability under the ADA, you said:
            </p>
            <p>I have {user.responses.b2aImpairment}</p>
            <p>This limits my {user.responses.b2aLimits}</p>
            <p>
              This limitation is substantial as evidenced by{" "}
              {user.responses.b2aEvidence}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            What are the risks in my current or prospective workplace
            environment?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              When we asked if there are currently other employees who are
              clearly using disability accommodations, you said:
              <ul>
                <li>{user.responses.b5rOthers}</li>
              </ul>
            </p>
            <p>
              When we asked if you have heard any stories from work colleagues
              about their own experiences negotiating accommodations in this
              workplace and what you could gather from their experiences, you
              said:
              <ul>
                <li>{user.responses.b5rStories}</li>
              </ul>
            </p>
            <p>
              When we asked if there are any company-based affinity groups, or
              employee resource groups that bring together employees with
              similar backgrounds or interests, based on disability or health,
              you said:
              <ul>
                <li>{user.responses.b5rGroups}</li>
              </ul>
            </p>
            <p>
              When we asked if you could find any company postings with a clear
              company statement outlining how to request a reasonable
              accommodation, you said:
              <ul>
                <li>{user.responses.b5rPostings}</li>
              </ul>
            </p>
            <p>
              Overall, you gave your workplace{" "}
              {grades[user.responses.b5aGrade + 1]} grade. Your reasoning was:
              <ul>
                <li>{user.responses.b5aNotes}</li>
              </ul>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            Am I willing to take a risk and request an accommodation?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              When we asked if you have the resources to deal with any financial
              risk, you said you{" "}
              <strong>{responses[user.responses.b6rFinances - 1]}</strong>, and
              wrote:
              <ul>
                <li>{user.responses.b6rFinancesNotes}</li>
              </ul>
            </p>
            <p>
              When we asked if you have the social support resources to help you
              through negative times, you said you{" "}
              <strong>{responses[user.responses.b6rSupport - 1]}</strong>, and
              wrote:
              <ul>
                <li>{user.responses.b6rSupportNotes}</li>
              </ul>
            </p>
            <p>
              When we asked if you have personal characteristics that make me
              resilient and able to deal with negative interactions and
              outcomes, you said you{" "}
              <strong>{responses[user.responses.b6rTolerance - 1]}</strong>, and
              wrote:
              <ul>
                <li>{user.responses.b6rToleranceNotes}</li>
              </ul>
            </p>
            <p>
              Ultimately, you wrote the following about the risks that may exist
              when you request an accommodation:
              <ul>
                <li>{user.responses.b6aRisks}</li>
              </ul>
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Card>
  );
};

export default MyNotes;
