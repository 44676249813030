const Accessibility = () => {
  return (
    <div>
      <h2>Accessibility Statement</h2>
      <p>
        The developers of this tool are committed to ensuring digital
        accessibility for people with disabilities.
      </p>
      <p>
        The{" "}
        <a
          href="https://www.w3.org/WAI/standards-guidelines/wcag/"
          target="_blank"
          rel="noreferrer"
        >
          Web Content Accessibility Guidelines (WCAG)
        </a>{" "}
        defines requirements for designers and developers to improve
        accessibility for people with disabilities. This website aims to be
        conformant with WCAG 2.1 level AA. We have implemented internal and
        external reviews to achieve compliance but realize that continuously
        updated content and limitations within open-source platforms may cause
        these efforts to fall short.{" "}
      </p>
      <p>
        We take website accessibility seriously and would like to hear from
        users who feel their experience isn't up to the current standards, and
        to mitigate those circumstances in a timely manner whenever possible.
        Please let us know if you encounter accessibility barriers by emailing
        us at <a href="mailto:radecisions@uw.edu">radecisions@uw.edu</a>.{" "}
      </p>
    </div>
  );
};

export default Accessibility;
