import { styles, ListItem } from "./StylesPDF.js";
import { Document, Page, Text, Font, StyleSheet } from "@react-pdf/renderer";

const MyAccommodationsPDF = ({ user }) => (
  <Document>
    <Page style={styles.body} size="LETTER">
      <Text style={styles.title}>My Useful Accommodations</Text>
      <Text style={styles.subtitle}>
        Here are the accomodations you said you could use:
      </Text>
      {user.jobTasks.map((task) => {
        if (task.jobTasksTask == "") {
          return;
        }
        return (
          (task.jobTasksSolution && task.jobTasksSolution != "" && (
            <ListItem>
              {task.jobTasksTask + ": " + task.jobTasksSolution}
            </ListItem>
          )) ||
          (task.jobTasksEasier && task.jobTasksEasier != "" && (
            <ListItem>
              {task.jobTasksTask + ": " + task.jobTasksEasier}
            </ListItem>
          ))
        );
      })}
    </Page>
  </Document>
);

export default MyAccommodationsPDF;
