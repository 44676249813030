import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import { FiExternalLink } from "react-icons/fi";

const Learn = ({ setFeedback }) => {
  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h2>Section 4</h2>
      <h3>What are reasonable accommodations under the ADA?</h3>
      <p>
        As we noted earlier, reasonable accommodations are changes in the work
        environment that are intended to ‘even the playing field’ and provide
        equal opportunity for people with disabilities.
      </p>
      <p>Reasonable accommodations fall into three categories:</p>
      <ol>
        <li>
          Changes in the <strong>job application process</strong> when you are
          looking for work. These accommodations could include things like
          providing an alternate way to submit a job application if the online
          system is inaccessible or modifying the assessment techniques if
          nonessential aspects of a test are inaccessible (e.g., a test requires
          vision but measures mathematical skill).
        </li>

        <li>
          Changes in the <strong>work environment</strong> or{" "}
          <strong>the way a job is typically performed</strong> after you have
          been hired. These accommodations could include things like changes to
          the facility to make them accessible, job restructuring, modified work
          schedules, or providing qualified American Sign Language (ASL)
          interpreters.
        </li>

        <li>
          Changes that{" "}
          <strong>
            enable you to enjoy the benefits and privileges of the job
          </strong>{" "}
          like employer sponsored trainings, services (e.g., cafeterias,
          shuttles), company parties or social functions.
        </li>
      </ol>
      <p>
        The type of accommodation that will make sense for you is highly
        individualized. It depends on your disability, your type of work, your
        colleagues, and your work environment. You may already have ideas about
        the accommodation you need, but if not, there are some excellent
        resources available to help. The{" "}
        <a target="_blank" rel="noreferrer" href="https://askjan.org/">
          Job Accommodation Network (JAN)
        </a>{" "}
        has a{" "}
        <a target="_blank" rel="noreferrer" href="https://askjan.org/soar.cfm">
          searchable online accommodation resource (SOAR)
        </a>
        . This resource can be searched using terms for disabilities,
        impairments, work related functions and other topics.
      </p>
      <p>
        If you are unsure what accommodations might be possible, spend some time
        now researching accommodations.
      </p>
      <Button
        variant="outline-primary"
        target="_blank"
        href="https://askjan.org/soar.cfm"
        style={{ marginBottom: "1em" }}
      >
        Searchable Online Accommodation Resource
        <FiExternalLink style={{ marginLeft: "5px", marginBottom: "4px" }} />
      </Button>
      <p>
        If you don’t find an answer there, you can contact JAN directly using
        information on their{" "}
        <a
          href="https://askjan.org/contact-us.cfm"
          target="_blank"
          rel="noreferrer"
        >
          Contact Us
        </a>{" "}
        page. You can also contact your regional ADA Center using the
        information in the “
        <a
          href="https://adata.org/technical-assistance"
          target="_blank"
          rel="noreferrer"
        >
          Ask ADA Questions
        </a>
        ” page.
      </p>
    </div>
  );
};

export default Learn;
