import "./Step.css";
import { useParams } from "react-router-dom";
import Steps from "./Steps.js";
import Error from "../Error.js";
import React, { useState } from "react";
import NextButton from "./NextButton.js";
import BackButton from "./BackButton.js";
import Alert from "react-bootstrap/Alert";
import sendValues from "./SendValues.js";
import getValues from "./GetValues.js";

const Step = ({ authToken, user, setUser }) => {
  let { module, section, step } = useParams();
  const StepToRender = Steps[module + section + step];
  const [feedback, setFeedback] = useState(null);

  const saveData = async (e) => {
    setFeedback(
      await sendValues(user.jobTasks, authToken, "/records/jobTasks")
    );
    const nTasks = await getValues(authToken, "/records/jobTasks");
    setFeedback(
      await sendValues(user.reqSteps, authToken, "/records/reqSteps")
    );
    const nSteps = await getValues(authToken, "/records/reqSteps");
    setFeedback(await sendValues(user.entries, authToken, "/records/entries"));
    const nEntries = await getValues(authToken, "/records/entries");
    setUser({
      ...user,
      jobTasks: nTasks,
      reqSteps: nSteps,
      entries: nEntries,
    });
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
  };

  // Links the user to the next step/section in order
  const Next = () => {
    switch (step) {
      case "learn":
        return (
          <NextButton
            text="Next: Reflect"
            to={"/" + module + "/" + section + "/reflect"}
          />
        );
      case "reflect":
        return (
          <div
            onClick={() => {
              saveData();
            }}
          >
            <NextButton
              text="Next: Apply"
              to={"/" + module + "/" + section + "/apply"}
            />
          </div>
        );
      case "apply":
        if (module === "before" && section === "section6") {
          return (
            <div
              onClick={() => {
                saveData();
              }}
            >
              <NextButton
                text="Next: Make a Decision"
                to={"/before/section7/decide"}
              />
            </div>

            //<NextButton text="Next: During" to={"/during/section1/learn"} />
          );
        } else if (module === "during" && section === "section1") {
          return (
            <div
              onClick={() => {
                saveData();
              }}
            >
              <NextButton text="Next: After" to={"/after/section1/learn"} />
            </div>
          );
        } else if (module === "after" && section === "section5") {
          return (
            <div
              onClick={() => {
                saveData();
              }}
            >
              <NextButton text="Next: Conclusion" to={"/conclusion"} />
            </div>
          );
        } else {
          return (
            <div
              onClick={() => {
                saveData();
              }}
            >
              <NextButton
                text="Next Section"
                to={
                  "/" +
                  module +
                  "/section" +
                  (Number(section.charAt(section.length - 1)) + 1) +
                  "/learn"
                }
              />
            </div>
          );
        }
      case "decide":
        return (
          <div
            onClick={() => {
              saveData();
            }}
          >
            <NextButton
              text="Next: Making the Request"
              to={"/during/section1/learn"}
            />
          </div>

          //<NextButton text="Next: During" to={"/during/section1/learn"} />
        );
      default:
        return null;
    }
  };

  const Back = () => {
    switch (step) {
      case "reflect":
        return (
          <BackButton
            text="Back: Learn"
            to={"/" + module + "/" + section + "/learn"}
          />
        );
      case "apply":
        return (
          <div
            onClick={() => {
              saveData();
            }}
          >
            <BackButton
              text="Back: Reflect"
              to={"/" + module + "/" + section + "/reflect"}
            />
          </div>
        );
      case "learn":
        if (module === "before" && section === "section1") {
          return (
            <div
              onClick={() => {
                saveData();
              }}
            >
              <BackButton text="Back: Introduction" to={"/intro"} />
            </div>
          );
        } else if (module === "during" && section === "section1") {
          return (
            <div
              onClick={() => {
                saveData();
              }}
            >
              <BackButton text="Back: Before" to={"/before/section7/decide"} />
            </div>
          );
        } else if (module === "after" && section === "section1") {
          return (
            <div
              onClick={() => {
                saveData();
              }}
            >
              <BackButton
                text="Back: Making the Request"
                to={"/during/section1/apply"}
              />
            </div>
          );
        } else {
          return (
            <div
              onClick={() => {
                saveData();
              }}
            >
              <BackButton
                text="Back Section"
                to={
                  "/" +
                  module +
                  "/section" +
                  (Number(section.charAt(section.length - 1)) - 1) +
                  "/apply"
                }
              />
            </div>
          );
        }
      case "decide":
        return (
          <div
            onClick={() => {
              saveData();
            }}
          >
            <BackButton text="Back Section" to={"/before/section6/apply"} />
          </div>
        );
      default:
        return null;
    }
  };

  return StepToRender ? (
    <React.Fragment>
      {feedback &&
        feedback.status >= 300 && ( // Only show if error
          <Alert
            style={{ marginTop: "1em" }}
            variant={feedback.status >= 300 ? "danger" : "success"}
            onClose={() => setFeedback(null)}
            dismissible
          >
            <Alert.Heading>{feedback.heading}</Alert.Heading>
            <p>{feedback.text}</p>
          </Alert>
        )}
      <StepToRender
        authToken={authToken}
        user={user}
        setUser={setUser}
        setFeedback={setFeedback}
      />
      <div className="button-container">
        {Back()}
        {Next()}
      </div>
    </React.Fragment>
  ) : (
    <Error />
  );
};

export default Step;
