import { View, Text, Font, StyleSheet } from "@react-pdf/renderer";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

export const styles = StyleSheet.create({
  body: {
    paddingTop: 65,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Times-Roman",
    marginBottom: 20,
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Times-Roman",
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  h3: {
    fontSize: 18,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    marginTop: 25,
  },
  h4: {
    fontSize: 14,
    marginBottom: 0,
    color: "grey",
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  bullet: {
    height: "100%",
  },
  bulletText: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    marginVertical: 12,
  },
});

export const ListItem = ({ children }) => {
  return (
    <View style={styles.row}>
      <View style={styles.bullet}>
        <Text style={styles.text}>{"\u2022"}</Text>
      </View>
      <Text style={styles.bulletText}>{children}</Text>
    </View>
  );
};
