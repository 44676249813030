import Form from "react-bootstrap/Form";
import React, { useEffect } from "react";
import sendValues from "../../SendValues.js";
import * as ft from "../../functions/formTracking.js";

function Apply({ authToken, user, setUser, setFeedback }) {
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
  };

  const nextSteps = [
    "Accept the decision and either try to self-accommodate or look for a new job.",
    "Request an explanation for why my request was denied.",
    "Appeal the decision.",
    "Reach out for legal advice.",
    "Find an employment attorney.",
    "Make a complaint with the EEOC.",
  ];

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h3>Apply: What if my request is denied?</h3>

      {!authToken ? (
        <p>Sign in to complete this reflection!</p>
      ) : (
        <div>
          <p>
            In the Reflect section, you said that you thought your request was
            denied because:
          </p>
          <p style={{ paddingLeft: "2em" }}>
            {user.responses.a4rWhy || "No response given"}
          </p>
          <p>And you noted the following feelings about the situation:</p>
          <p style={{ paddingLeft: "2em" }}>
            {user.responses.a4rFeeling || "No response given"}
          </p>

          <Form
            style={{ margin: "0 auto", maxWidth: "30em" }}
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-3">
              <Form.Label>
                Based on those notes, identify what the next step would be for
                you:
              </Form.Label>
              {nextSteps.map((step, idx) => {
                return (
                  <Form.Check
                    type="radio"
                    name={"a4aNextStep"}
                    id={"a4aNextStep" + idx}
                    onChange={(e) => ft.handleResponse(e, user, setUser)}
                    value={idx + 1}
                    label={step}
                    checked={user.responses.a4aNextStep == idx + 1}
                  />
                );
              })}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Based on your choice above, write out the next steps in the text
                box below:
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="a4aSteps"
                onChange={(e) => ft.handleResponse(e, user, setUser)}
                value={user.responses.a4aSteps}
              />
            </Form.Group>
          </Form>
        </div>
      )}
    </div>
  );
}

export default Apply;
