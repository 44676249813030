import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

const RequestPasswordReset = () => {
  const [values, setValues] = useState({ email: "", password: "" });
  const [feedback, setFeedback] = useState();
  const [validated, setValidated] = useState(false);

  const onInput = ({ target: { value, name } }) => {
    setValues((oldValues) => ({ ...oldValues, [name]: value }));
    setValidated(false);
  };

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);
    e.preventDefault();

    const sendData = {
      email: values.email,
    };

    let formData = new FormData();
    for (var key in sendData) {
      formData.append(key, sendData[key]);
    }

    const response = await fetch(
      "https://depts.washington.edu/adakt/ra-tool/url.php/reset",
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.status >= 300) {
      const error = await response.json();
      console.log(error);
      setFeedback({
        heading: "Error requesting password!",
        text: "Code " + error.code + ": " + error.message,
        status: error.code,
      });
      return;
    }
    const res = await response;
    const resetKey = await res.json();
    if (!resetKey.error) {
      resetKey && alert(resetKey.message);
    }
  };

  return (
    <div>
      <h1>Password reset</h1>
      <h3>
        Use this page to send yourself an email with a link to reset your
        password.
      </h3>
      <Form
        style={{ margin: "0 auto", maxWidth: "30em" }}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            required
            type="email"
            name="email"
            placeholder="Enter email"
            onChange={(e) => onInput(e)}
            value={values.email}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid email address.
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid">
            If this email is in our database, you will receive a link to reset
            your password.
          </Form.Control.Feedback>
        </Form.Group>
        {!validated && (
          <Button variant="primary" type="submit">
            Request Password Reset
          </Button>
        )}
      </Form>
      {feedback && (
        <Alert
          style={{ marginTop: "1em" }}
          variant={feedback.status >= 300 ? "danger" : "success"}
          onClose={() => setFeedback(null)}
          dismissible
        >
          <Alert.Heading>{feedback.heading}</Alert.Heading>
          <p>{feedback.text}</p>
        </Alert>
      )}
    </div>
  );
};

export default RequestPasswordReset;
