import React from "react";
import EntryEditor from "../../EntryEditor.js";
import SignUpAlert from "../../SignUpAlert.js";

function Reflect({ user, setUser }) {
  return (
    <div>
      <h3>Reflect: Do I want to challenge the decision or not?</h3>
      <p>
        If you have been provided an accommodation different from what you
        suggested, first try to use the accommodation provided and decide if it
        meets your needs. Keep a Workplace Journal for a month or two tracking
        your use of the accommodation. Use this Workplace Journal to reflect, on
        a daily basis, on how well the accommodation is working for you and to
        identify any difficulties you have using the accommodation or if there
        are significant limitations of this accommodation. Generate a short list
        of ways in which the accommodation is intended to help you with work
        tasks. Try to consistently jot down reflections like:
      </p>
      <ul>
        <li>
          Are any of my work tasks easier to complete (i.e., able to complete
          more quickly; able to complete with less pain, stress, worry, or
          exertion of effort; able to complete to my satisfaction) with the
          accommodation than without it?
        </li>
        <li>
          Are there any ways this accommodation falls short of the specific
          accommodation I requested?
        </li>
        <li>
          Are any of my tasks more difficult to complete with this
          accommodation?
        </li>
      </ul>
      <p>
        Jot down any moments you find yourself frustrated with the accommodation
        or feeling surprised at how easy something was with the accommodation
        compared to without it. By documenting concrete examples of what works
        well and what doesn’t for a short time span, you will be able to more
        clearly examine the benefits and limitations of this accommodation.
      </p>
      <p>
        Next, use the Workplace Journal to assess how well the accommodation is
        working for you and if you feel challenging the accommodation decision
        is worthwhile.
      </p>
      {!user ? <SignUpAlert /> : <EntryEditor user={user} setUser={setUser} />}
    </div>
  );
}

export default Reflect;
