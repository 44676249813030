import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import sendValues from "../../SendValues.js";
import { useEffect } from "react";
import SignUpAlert from "../../SignUpAlert.js";

function Apply({ authToken, user, setUser, setFeedback }) {
  const onInput = ({ target: { value, name } }) => {
    setUser({ ...user, responses: { ...user.responses, [name]: value } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
  };

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h3>Apply: How disability friendly is my workplace?</h3>
      <p>
        Now that you have compiled information about the workplace climate of
        this job, what “grade” might you give this work environment in terms of
        being disability friendly? Use the Disability Friendly Workplace Climate
        Grading Scale Worksheet below to help summarize your impression of the
        workplace atmosphere when it comes to requesting accommodations. Note
        that this grading sheet is for your eyes only, so be honest!
      </p>
      <ul>
        <li>
          A: Workplace climate clearly signals that employer values a workplace
          with employees with different backgrounds, strengths, and abilities.
          Job site has clear guidelines on how to request a reasonable
          accommodation and who to talk to about the accommodation process.
        </li>
        <li>
          B: Workplace signals that employees with different backgrounds,
          strengths, and abilities are welcome. It may take some digging, but
          policies or guidelines on how to request a reasonable accommodation
          are available.
        </li>
        <li>
          C: Once you know who to ask, policies or guidelines on requesting a
          reasonable accommodation are available.
        </li>
        <li>
          D: There are no guidelines on how to request a reasonable
          accommodation in place. Co-workers, supervisors and/or managers are
          not immediately certain who to go to get information on this process.
          It is not clear that having a diverse workforce is important to this
          employer.
        </li>
        <li>
          F: There are no guidelines on how to request a reasonable
          accommodation in place. Co-workers, supervisors and/or managers are
          not immediately certain who to go to get information on this process.
          Past treatment of disabled employees indicates that having a diverse
          workforce is not important to this employer.
        </li>
        <li>Unknown: Unable to find any information</li>
      </ul>
      {!user ? (
        <SignUpAlert />
      ) : (
        <Form
          style={{ margin: "0 auto", maxWidth: "30em" }}
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-3">
            <Form.Label>The grade I would give my workplace is:</Form.Label>
            <Form.Select name="b5aGrade" onChange={(e) => onInput(e)}>
              <option selected={user.responses.b5aGrade === 0} value={0}>
                A
              </option>
              <option selected={user.responses.b5aGrade === 1} value={1}>
                B
              </option>
              <option selected={user.responses.b5aGrade === 2} value={2}>
                C
              </option>
              <option selected={user.responses.b5aGrade === 3} value={3}>
                D
              </option>
              <option selected={user.responses.b5aGrade === 4} value={4}>
                F
              </option>
              <option selected={user.responses.b5aGrade === -1} value={-1}>
                Unknown
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>My reason for this score is:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="b5aNotes"
              onChange={(e) => onInput(e)}
              value={user.responses.b5aNotes}
            />
          </Form.Group>
        </Form>
      )}
    </div>
  );
}

export default Apply;
