import { styles, ListItem } from "./StylesPDF.js";
import { Document, Page, Text, Font, StyleSheet } from "@react-pdf/renderer";

const MyFunctionsPDF = ({ user }) => (
  <Document>
    <Page style={styles.body} size="LETTER">
      <Text style={styles.title}>My Reasonable Accommodation Request</Text>
      <Text style={styles.subtitle}>
        I am a qualified person with a disability under the ADA.
      </Text>
      <Text style={styles.subtitle}>
        This impairment affects my ability to accomplish the following tasks:
      </Text>
      {user.jobTasks.map((task) => {
        if (task.jobTasksTask == "") {
          return;
        }
        return (
          <ListItem>
            {task.jobTasksTask}. This is{" "}
            {!(
              task.jobTasksTime === true ||
              task.jobTasksOthers === true ||
              task.jobTasksExpertise === true
            ) && "not "}
            an essential function of my job.
          </ListItem>
        );
      })}
      <Text style={styles.subtitle}>
        Here are the accomodations I am requesting:
      </Text>
      {user.jobTasks.map((task) => {
        return (
          (task.jobTasksSolution || task.jobTasksEasier) && (
            <ListItem>{task.jobTasksSolution || task.jobTasksEasier}</ListItem>
          )
        );
      })}
      <Text style={styles.subtitle}>
        I would like to set up a time to have an interactive discussion to
        clarify what I need and to work together to identify an appropriate
        accommodation.
      </Text>
    </Page>
  </Document>
);

export default MyFunctionsPDF;
