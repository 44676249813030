import MyRARequestPDF from "./PDFs/MyRARequestPDF.js";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Button from "react-bootstrap/Button";

const RARequest = ({ user }) => {
  return (
    <div>
      {user.responses.b2rImpair && (
        <div>
          <p>
            I want to request a reasonable accommodation in employment under the
            Americans with Disabilities Act.
          </p>
          <p>
            I am a qualified person with a disability under the ADA.
            <ol type="a">
              <li>{user.responses.b2rImpairNotes}</li>
              <li>
                This impairment affects my ability to accomplish the following
                tasks:
                <ol type="i">
                  {user.jobTasks.map((task) => {
                    return (
                      <li>
                        {task.jobTasksTask}. This is{" "}
                        {!(
                          task.jobTasksTime === true ||
                          task.jobTasksOthers === true ||
                          task.jobTasksExpertise === true
                        ) && "not "}
                        an essential function of my job.
                      </li>
                    );
                  })}
                </ol>
              </li>
            </ol>
          </p>

          <p>
            Accomodations I am requesting include:{" "}
            <ol type="a">
              {user.jobTasks.map((task) => {
                return (
                  (task.jobTasksSolution || task.jobTasksEasier) && (
                    <li>{task.jobTasksSolution || task.jobTasksEasier}</li>
                  )
                );
              })}
            </ol>
          </p>
          <p>
            I would like to set up a time to have an interactive discussion to
            clarify what I need and to work together to identify an appropriate
            accommodation.
          </p>
        </div>
      )}
      <PDFDownloadLink
        document={<MyRARequestPDF user={user} />}
        fileName="AccommodationsRequest.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            <Button variant="outline-primary" disabled>
              Loading PDF...
            </Button>
          ) : (
            <Button variant="outline-primary">Download PDF</Button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export default RARequest;
