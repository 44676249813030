import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import sendValues from "../../SendValues.js";
import SignUpAlert from "../../SignUpAlert.js";
import { Link } from "react-router-dom";

function Apply({ authToken, user, setUser, setFeedback }) {
  const onInput = ({ target: { value, name } }) => {
    setUser({ ...user, responses: { ...user.responses, [name]: value } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
  };

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  const starts = ["Do not have", "Do not have", "Might have", "Have", "Have"];

  return (
    <div>
      <h3>Apply: Am I willing to take a risk and request an accommodation?</h3>
      {!user ? (
        <SignUpAlert />
      ) : !user.responses.b6rFinances ||
        !user.responses.b6rSupport ||
        !user.responses.b6rTolerance ? (
        <h2>
          Finish <Link to="/before/section6/reflect">Before 6 Reflect</Link> to
          complete this activity!
        </h2>
      ) : (
        <React.Fragment>
          <p>You indicated that you:</p>
          <ul>
            <li>
              {starts[user.responses.b6rFinances - 1]} the resources to deal
              with any financial risk.
            </li>
            <li>
              {starts[user.responses.b6rSupport - 1]} the social support
              resources to help you through negative times.
            </li>
            <li>
              {starts[user.responses.b6rTolerance - 1]} personal characteristics
              that make you resilient and able to deal with negative
              interactions and outcomes.
            </li>
          </ul>
          <Form
            style={{ margin: "0 auto", maxWidth: "30em" }}
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-3">
              <Form.Label>
                In the space below, write a statement describing how you feel
                about the risks that may exist when you request an
                accommodation. Think about your workplace climate as well as
                your personal risk tolerance.
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="b6aRisks"
                onChange={(e) => onInput(e)}
                value={user.responses.b6aRisks}
              />
            </Form.Group>
          </Form>
        </React.Fragment>
      )}
    </div>
  );
}

export default Apply;
