import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import SignUpAlert from "../../SignUpAlert.js";
import * as ft from "../../functions/formTracking.js";

function Reflect({ authToken, user, setUser, setFeedback }) {
  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  const qs = [
    {
      name: "jobTasksOthers",
      q1: "Are there only a few other employees who can regularly perform ",
      q2: "this task?",
    },
    {
      name: "jobTasksExpertise",
      q1: "Is there a high degree of training or specialized knowledge to ",
      q2: "perform this task?",
    },
    {
      name: "jobTasksTime",
      q1: "Does ",
      q2: "this task take a large portion of your effort or time?",
    },
  ];

  return (
    <div>
      <h3>Reflect: Am I a ‘qualified’ individual with a disability</h3>
      {!user ? (
        <SignUpAlert />
      ) : (
        <Form>
          <h4>What are the prerequisites for my job?</h4>
          <p>
            The prerequisites of a job are often identified in a job posting or
            a position description. But sometimes they change over time when you
            have been in a position for a long time. Write out the prerequisites
            for your job. Think about educational level, years of work
            experience, specific training, skills, licenses, and certificates as
            well as any soft skills (e.g., customer service).
          </p>
          <Form.Group
            style={{ margin: "0 auto", maxWidth: "30em" }}
            className="mb-3"
          >
            {/*<Form.Label>Do you meet the prerequisites of your job?</Form.Label>
            <Form.Check
              type="radio"
              name={"b3rPrerequisites"}
              onChange={(e) => onInput(e)}
              value={true}
              checked={user.responses.b3rPrerequisites === true}
              label="Yes"
            />
            <Form.Check
              type="radio"
              name={"b3rPrerequisites"}
              onChange={(e) => onInput(e)}
              value={false}
              checked={user.responses.b3rPrerequisites === false}
              label="No"
            />*/}
            <Form.Label style={{ marginTop: "20px" }}>
              Write a statement describing the prerequisites for your job.
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="b3rPrerequisitesNotes"
              onChange={(evt) => ft.handleResponse(evt, user, setUser)}
              value={user.responses.b3rPrerequisitesNotes}
            />
          </Form.Group>
          <h4>What are the essential duties of my job?</h4>
          <p>
            You can request reasonable accommodations for any work task that is
            difficult for you to complete due to your disability. However, under
            the ADA, you must be able to complete all essential duties with or
            without accommodation. So, it is useful to differentiate what duties
            are essential.
          </p>
          <p>
            The essential job functions of any given work can sometimes be
            difficult to identify. Working from a written job description can be
            a good place to start. If you do not have access to a written job
            description, make a list of the tasks expected of the person who
            holds the position to do on a regular basis. Try to determine to
            what degree each task is prioritized in the job. One way to do this
            is to estimate the percent of effort or time each task is expected
            to take compared to all the other duties assigned. You might also
            consider the consequences of not doing that job function. You can
            think about the work experience of people who currently hold that
            job or who held it in the past. Use the form below to help identify
            these essential duties.
          </p>
          <Form.Group
            className="mb-3"
            style={{ margin: "0 auto", maxWidth: "30em" }}
          >
            <Form.Label style={{ marginBottom: "30px" }}>
              Write out the tasks that are difficult to complete without
              accommodation. For example, take lots of time, lots of energy, or
              planning:
            </Form.Label>
            <Accordion flush>
              {user.jobTasks
                .filter((taskd) => {
                  return taskd.deleted != true;
                })
                .map((task) => {
                  return (
                    <Accordion.Item key={task.ID} eventKey={task.ID}>
                      <Accordion.Header>
                        {task.jobTasksTask == ""
                          ? "New task"
                          : task.jobTasksTask}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Form.Label>Task name:</Form.Label>
                        <Form.Control
                          type="text"
                          name={task.ID}
                          onChange={(evt) => {
                            ft.handleTaskNameChange(
                              task.ID,
                              evt,
                              user,
                              setUser
                            );
                          }}
                          value={task.jobTasksTask}
                          placeholder="Task name"
                        />
                        <Form.Label>
                          Does your position exist to perform this task?
                        </Form.Label>
                        <Form.Check
                          type="radio"
                          name={"jobTasksRequired" + task.ID}
                          id={"jobTasksRequired" + task.ID + "True"}
                          onChange={(evt) => {
                            ft.handleTaskPropChange(
                              task.ID,
                              "jobTasksRequired",
                              evt,
                              user,
                              setUser
                            );
                          }}
                          value={true}
                          checked={
                            user.jobTasks.find((taskSaved) => {
                              return taskSaved.ID === task.ID;
                            }).jobTasksRequired === true
                          }
                          label="Yes"
                        />
                        <Form.Check
                          type="radio"
                          name={"jobTasksRequired" + task.ID}
                          id={"jobTasksRequired" + task.ID + "False"}
                          onChange={(evt) => {
                            ft.handleTaskPropChange(
                              task.ID,
                              "jobTasksRequired",
                              evt,
                              user,
                              setUser
                            );
                          }}
                          value={false}
                          checked={
                            user.jobTasks.find((taskSaved) => {
                              return taskSaved.ID === task.ID;
                            }).jobTasksRequired === false
                          }
                          label="No"
                        />
                        {task.jobTasksRequired === true ? (
                          <div>
                            <p>
                              This task may be an essential duty if one of the
                              following three things is true:
                            </p>
                            <div className="examples">
                              {qs.map((q) => {
                                return (
                                  <div id={q.name + task.ID}>
                                    <Form.Label>{q.q1 + q.q2}</Form.Label>
                                    <Form.Check
                                      type="radio"
                                      name={
                                        task.jobTasksTask + task.ID + q.name
                                      }
                                      id={
                                        task.jobTasksTask +
                                        task.ID +
                                        q.name +
                                        "True"
                                      }
                                      onChange={(evt) =>
                                        ft.handleTaskPropChange(
                                          task.ID,
                                          q.name,
                                          evt,
                                          user,
                                          setUser
                                        )
                                      }
                                      value={true}
                                      checked={
                                        user.jobTasks.find((taskSaved) => {
                                          return taskSaved.ID === task.ID;
                                        })[q.name] === true
                                      }
                                      label="Yes"
                                    />
                                    <Form.Check
                                      type="radio"
                                      name={
                                        task.jobTasksTask + task.ID + q.name
                                      }
                                      id={
                                        task.jobTasksTask +
                                        task.ID +
                                        q.name +
                                        "False"
                                      }
                                      onChange={(evt) =>
                                        ft.handleTaskPropChange(
                                          task.ID,
                                          q.name,
                                          evt,
                                          user,
                                          setUser
                                        )
                                      }
                                      value={false}
                                      checked={
                                        user.jobTasks.find((taskSaved) => {
                                          return taskSaved.ID === task.ID;
                                        })[q.name] === false
                                      }
                                      label="No"
                                    />
                                  </div>
                                );
                              })}
                              <Form.Label>
                                Percentage of time or effort spent on task:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={task.jobTasksTask + "effort"}
                                onChange={(evt) => {
                                  ft.handleTaskPropChange(
                                    task.ID,
                                    "jobTasksEffort",
                                    evt,
                                    user,
                                    setUser
                                  );
                                }}
                                value={
                                  user.jobTasks.find((taskSaved) => {
                                    return taskSaved.ID === task.ID;
                                  })["jobTasksEffort"]
                                }
                              />
                            </div>

                            {task.jobTasksTime === true ||
                            task.jobTasksOthers === true ||
                            task.jobTasksExpertise === true ? (
                              <div>
                                <h4>This task is likely an essential duty.</h4>
                              </div>
                            ) : (
                              <h4>This task is not an essential duty.</h4>
                            )}
                          </div>
                        ) : (
                          <h4>This task is not an essential duty.</h4>
                        )}
                        {/*<Form.Label>
                          Can you perform this task with accommodation?
                        </Form.Label>
                        <Form.Check
                          type="radio"
                          name={task.jobTasksTask + task.ID + "acom"}
                          onChange={(evt) =>
                            handleTaskPropChange(
                              task.ID,
                              "jobTasksAccommodation",
                              evt
                            )
                          }
                          value={true}
                          checked={
                            user.jobTasks.find((taskSaved) => {
                              return taskSaved.ID === task.ID;
                            }).jobTasksAccommodation === true
                          }
                          label="Yes"
                        />
                        <Form.Check
                          style={{ marginBottom: "20px" }}
                          type="radio"
                          name={task.jobTasksTask + task.ID + "acom"}
                          onChange={(evt) =>
                            handleTaskPropChange(
                              task.ID,
                              "jobTasksAccommodation",
                              evt
                            )
                          }
                          value={false}
                          checked={
                            user.jobTasks.find((taskSaved) => {
                              return taskSaved.ID === task.ID;
                            }).jobTasksAccommodation === false
                          }
                          label="No"
                        />
                        {user.jobTasks.find((taskSaved) => {
                          return taskSaved.ID === task.ID;
                        }).jobTasksAccommodation === true ? (
                          <p className="examples">
                            Great, in the next section we will think about what
                            types of accommodations would work best.
                          </p>
                        ) : (
                          <p className="examples">
                            Since this is an essential duty, you need to be able
                            to complete it with or without accommodations. If
                            you are not able to do that, then you could talk
                            with your supervisor to see whether it might be
                            possible to adjust your duties or change your job
                            description. The ADA does not require your
                            supervisor to make these changes.
                          </p>
                        )}*/}

                        <Button
                          variant="outline-danger"
                          size="sm"
                          type="button"
                          onClick={() => {
                            ft.handleRemoveTask(task.ID, user, setUser);
                          }}
                        >
                          Remove task
                        </Button>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
            </Accordion>
            <Button
              type="button"
              onClick={() => {
                ft.handleAddTask(user, setUser);
              }}
            >
              Add task
            </Button>
          </Form.Group>
        </Form>
      )}
    </div>
  );
}

export default Reflect;
