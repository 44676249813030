import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

function SignUp({ setToken, setUser }) {
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    repeatPassword: "",
    disability: false,
    relatives: false,
    adaCoordinator: false,
    employer: false,
    employee: false,
    serviceProvider: false,
    hrProfessional: false,
    legalAdvocate: false,
    government: false,
    educator: false,
    other: false,
    otherText: "",
    hispanic: null,
    amInd: false,
    asian: false,
    black: false,
    pacific: false,
    white: false,
    age: -1,
    gender: "",
    genderExtra: "",
    genderMinority: null,
  });

  const [feedback, setFeedback] = useState();
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const onInput = ({ target: { value, name } }) =>
    setValues((oldValues) => ({ ...oldValues, [name]: value }));

  const onInputCheck = ({ target: { checked, value } }) =>
    setValues((oldValues) => ({ ...oldValues, [value]: checked }));

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);
    e.preventDefault();

    // Send registration for authentication table in database
    const sendRegistrationData = {
      email: values.email,
      password: values.password,
    };

    let formData = new FormData();
    for (var key in sendRegistrationData) {
      formData.append(key, sendRegistrationData[key]);
    }

    const response = await fetch(
      "https://depts.washington.edu/adakt/ra-tool/url.php/register",
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.status >= 300) {
      const error = await response.json();
      console.log(error);
      setFeedback({
        heading: "Error signing up!",
        text: "Code " + error.code + ": " + error.message,
        status: error.code,
      });
      return;
    }
    const res = await response;
    const user = await res.json();
    if (user.error) {
      console.log(user.error);
      setFeedback({
        heading: "Error signing up!",
        text: user.error,
        status: 200,
      });
      return;
    }

    console.log(user);
    setToken(user.apikey);

    // create responses table
    const response2 = await fetch(
      "https://depts.washington.edu/adakt/ra-tool/api.php/records/responses",
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "X-Api-Key": user.apikey,
        }),
        body: JSON.stringify({
          user_id: user.id,
          username: values.name,
        }),
      }
    );
    if (response.status == 1012) {
      const error = await response.text();
      console.log(error);
      return;
    }

    if (response.status >= 300) {
      const error = await response.text();
      console.log(error);
      return;
    }

    // Get responses table id
    const response3 = await fetch(
      "https://depts.washington.edu/adakt/ra-tool/api.php/records/responses",
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "X-Api-Key": user.apikey,
        }),
      }
    );

    if (response.status >= 300) {
      const error = await response.json();
      console.log(error);
      setFeedback({
        heading: "Error signing up!",
        text: "Code " + error.code + ": " + error.message,
        status: error.code,
      });
      return;
    }

    const res3 = await response3.json();

    const resID = res3.records[0].ID;

    // send data for responses table in database
    const sendData = {
      user_id: user.ID,
      demDisability: values.disability,
      demRelatives: values.relatives,
      demADACoordinator: values.adaCoordinator,
      demEmployer: values.employer,
      demEmployee: values.employee,
      demCommunityProvider: values.serviceProvider,
      demHR: values.hrProfessional,
      demLegalAdvocate: values.legalAdvocate,
      demGovEmployee: values.government,
      demEducator: values.educator,
      demOther: values.otherText,
      ethHispanic: values.hispanic ? values.hispanic === "true" : null,
      raceAmInd: values.amInd,
      raceAsian: values.asian,
      raceBlack: values.black,
      racePacific: values.pacific,
      raceWhite: values.white,
      age: Number(values.age),
      genderDescription:
        values.gender === "Other" ? 2 : values.gender ? values.gender : null,
      genderMinority: values.genderMinority
        ? values.genderMinority === "true"
        : null,
    };

    const handleData = async (data) => {
      const response = await fetch(
        "https://depts.washington.edu/adakt/ra-tool/api.php/records/responses/" +
          resID,
        {
          method: "PUT",
          headers: new Headers({
            "Content-Type": "application/json",
            "X-Api-Key": user.apikey,
          }),
          body: data,
        }
      );
      if (response.status >= 300) {
        const error = await response.json();
        console.log(error);
        setFeedback({
          heading: "Error signing up!",
          text: "Code " + error.code + ": " + error.message,
          status: error.code,
        });
        return;
      }
      const values = await response.json();
      return values;
    };

    let responses = await handleData(JSON.stringify(sendData));

    const userData = {
      ...user,
      responses: sendData,
      jobTasks: [],
      reqSteps: [],
      entries: [],
      responsesID: resID,
    };
    setUser(userData);
    navigate("/");
  };

  return (
    <div>
      <h1>Sign Up</h1>
      <Form
        style={{ margin: "0 auto", maxWidth: "30em" }}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            required
            type="text"
            name="name"
            placeholder="Name"
            onChange={(e) => onInput(e)}
            value={values.name}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            required
            type="email"
            name="email"
            placeholder="Enter email"
            onChange={(e) => onInput(e)}
            value={values.email}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid email address.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            name="password"
            onChange={(e) => onInput(e)}
            value={values.password}
            placeholder="Password"
          />
          <Form.Control.Feedback type="invalid">
            Please enter a password.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Retype Password</Form.Label>
          <Form.Control
            required
            type="password"
            name="repeatPassword"
            onChange={(e) => onInput(e)}
            value={values.repeatPassword}
            placeholder="Retype password"
            pattern={values.password}
          />
          <Form.Control.Feedback type="invalid">
            Passwords must match
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Label>
          <h2>Information about you</h2>
        </Form.Label>
        <Form.Label>
          We want to understand who uses the decision aid and who doesn't. You
          can skip any question that you do not want to answer.
        </Form.Label>

        <Form.Group className="mb-3">
          <Form.Label>Are you a...</Form.Label>
          <Form.Check
            type="checkbox"
            name="identity"
            id="identityDis"
            onChange={(e) => onInputCheck(e)}
            value="disability"
            checked={values.disability}
            label="Person with a disability"
          />
          <Form.Check
            type="checkbox"
            name="identity"
            id="identityFriend"
            onChange={(e) => onInputCheck(e)}
            value="relatives"
            checked={values.relatives}
            label="Friend or family of a person with a disability"
          />
          <Form.Check
            type="checkbox"
            name="identity"
            id="identityADA"
            onChange={(e) => onInputCheck(e)}
            value="adaCoordinator"
            checked={values.adaCoordinator}
            label="ADA coordinator"
          />
          <Form.Check
            type="checkbox"
            name="identity"
            id="identityEmployer"
            onChange={(e) => onInputCheck(e)}
            value="employer"
            checked={values.employer}
            label="Employer"
          />
          <Form.Check
            type="checkbox"
            name="identity"
            id="identityEmployee"
            onChange={(e) => onInputCheck(e)}
            value="employee"
            checked={values.employee}
            label="Employee"
          />
          <Form.Check
            type="checkbox"
            name="identity"
            id="identityServiceProvider"
            onChange={(e) => onInputCheck(e)}
            value="serviceProvider"
            checked={values.serviceProvider}
            label="Community service provider"
          />
          <Form.Check
            type="checkbox"
            name="identity"
            id="identityHR"
            onChange={(e) => onInputCheck(e)}
            value="hrProfessional"
            checked={values.hrProfessional}
            label="Human resources professional"
          />
          <Form.Check
            type="checkbox"
            name="identity"
            id="identityLegal"
            onChange={(e) => onInputCheck(e)}
            value="legalAdvocate"
            checked={values.legalAdvocate}
            label="Legal advocate"
          />
          <Form.Check
            type="checkbox"
            name="identity"
            id="identityGovernment"
            onChange={(e) => onInputCheck(e)}
            value="government"
            checked={values.government}
            label="Government employee"
          />
          <Form.Check
            type="checkbox"
            name="identity"
            id="identityEducator"
            onChange={(e) => onInputCheck(e)}
            value="educator"
            checked={values.educator}
            label="Educator"
          />
          <Form.Check
            type="checkbox"
            name="identity"
            id="identityOther"
            onChange={(e) => onInputCheck(e)}
            value="other"
            checked={values.other}
            label={
              <Form.Control
                size="sm"
                type="text"
                name="otherText"
                onChange={(e) => onInput(e)}
                value={values.otherText}
                placeholder="Other"
              />
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Which of the following best describes your ethnicity?
          </Form.Label>
          <Form.Check
            type="radio"
            name="hispanic"
            id="hispanicTrue"
            onChange={(e) => onInput(e)}
            value={true}
            label="Hispanic or Latino"
          />
          <Form.Check
            type="radio"
            name="hispanic"
            id="hispanicFalse"
            onChange={(e) => onInput(e)}
            value={false}
            label="Not Hispanic or Latino"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Which of the following best describes your race?
          </Form.Label>
          <Form.Check
            type="checkbox"
            name="race"
            id="raceAmInd"
            onChange={(e) => onInputCheck(e)}
            value="amInd"
            checked={values.amInd}
            label="American Indian or Alaska Native"
          />
          <Form.Check
            type="checkbox"
            name="race"
            id="raceAsian"
            onChange={(e) => onInputCheck(e)}
            value="asian"
            checked={values.asian}
            label="Asian"
          />
          <Form.Check
            type="checkbox"
            name="race"
            id="raceBlack"
            onChange={(e) => onInputCheck(e)}
            value="black"
            checked={values.black}
            label="Black or African American"
          />
          <Form.Check
            type="checkbox"
            name="race"
            id="racePacific"
            onChange={(e) => onInputCheck(e)}
            value="pacific"
            checked={values.pacific}
            label="Native Hawaiian or Other Pacific Islander"
          />
          <Form.Check
            type="checkbox"
            name="race"
            id="raceWhite"
            onChange={(e) => onInputCheck(e)}
            value="white"
            checked={values.white}
            label="White"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>What is your age?</Form.Label>
          <Form.Check
            type="radio"
            name="age"
            id="age18"
            onChange={(e) => onInput(e)}
            value={0}
            label="Under 18 years"
          />
          <Form.Check
            type="radio"
            name="age"
            id="age24"
            onChange={(e) => onInput(e)}
            value={1}
            label="19 to 24 years"
          />
          <Form.Check
            type="radio"
            name="age"
            id="age34"
            onChange={(e) => onInput(e)}
            value={2}
            label="25 to 34 years"
          />
          <Form.Check
            type="radio"
            name="age"
            id="age44"
            onChange={(e) => onInput(e)}
            value={3}
            label="35 to 44 years"
          />
          <Form.Check
            type="radio"
            name="age"
            id="age54"
            onChange={(e) => onInput(e)}
            value={4}
            label="45 to 54 years"
          />
          <Form.Check
            type="radio"
            name="age"
            id="age64"
            onChange={(e) => onInput(e)}
            value={5}
            label="55 to 64 years"
          />
          <Form.Check
            type="radio"
            name="age"
            id="age74"
            onChange={(e) => onInput(e)}
            value={6}
            label="65 to 74 years"
          />
          <Form.Check
            type="radio"
            name="age"
            id="age84"
            onChange={(e) => onInput(e)}
            value={7}
            label="75 to 84 years"
          />
          <Form.Check
            type="radio"
            name="age"
            id="age85Plus"
            onChange={(e) => onInput(e)}
            value={8}
            label="85 years or older"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Would you describe yourself as...</Form.Label>
          <Form.Check
            type="radio"
            name="gender"
            id="genderWoman"
            onChange={(e) => onInput(e)}
            value={0}
            label="A woman"
          />
          <Form.Check
            type="radio"
            name="gender"
            id="genderMan"
            onChange={(e) => onInput(e)}
            value={1}
            label="A man"
          />
          <Form.Check
            type="radio"
            name="gender"
            id="genderOther"
            onChange={(e) => onInput(e)}
            value={"Other"}
            label={
              <Form.Control
                size="sm"
                type="text"
                name="genderExtra"
                onChange={(e) => onInput(e)}
                value={values.genderExtra}
                placeholder="Another gender"
              />
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Do you identify as a gender or sexual minority (a member of the
            LGBTQ community)?
          </Form.Label>
          <Form.Check
            type="radio"
            name="genderMinority"
            id="genderMinorityTrue"
            onChange={(e) => onInput(e)}
            value={true}
            label="Yes"
          />
          <Form.Check
            type="radio"
            name="genderMinority"
            id="genderMinorityFalse"
            onChange={(e) => onInput(e)}
            value={false}
            label="No"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Create Account
        </Button>
      </Form>
      {feedback && (
        <Alert
          style={{ marginTop: "1em" }}
          variant={feedback.status >= 300 ? "danger" : "success"}
          onClose={() => setFeedback(null)}
          dismissible
        >
          <Alert.Heading>{feedback.heading}</Alert.Heading>
          <p>{feedback.text}</p>
        </Alert>
      )}
    </div>
  );
}

export default SignUp;
