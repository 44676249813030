import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import getValues from "../../GetValues.js";
import sendValues from "../../SendValues.js";
import { Link } from "react-router-dom";

function Reflect({ authToken, user, setUser, setFeedback }) {
  const onInput = ({ target: { value, name } }) => {
    setUser({ ...user, responses: { ...user.responses, [name]: value } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
  };
  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h3>Reflect: What if my request is denied?</h3>
      <p>
        Reflect on your situation and write out some notes answering the
        following questions:
      </p>
      {!authToken ? (
        <p>Sign in to complete this reflection!</p>
      ) : (
        <Form
          style={{ margin: "0 auto", maxWidth: "30em" }}
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-3">
            <Form.Label>
              What is going on? Why do I think my request was denied?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="a4rWhy"
              onChange={(e) => onInput(e)}
              value={user.responses.a4rWhy}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              What resources do I have available to me to help if I decide to
              continue to push for an accommodation? (Resources might include
              specific friends, colleagues, family members, professionals you
              know such as attorneys, members of your health team, support
              groups, etc.)
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="a4rResources"
              onChange={(e) => onInput(e)}
              value={user.responses.a4rResources}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              How am I feeling about this situation? Do I have the energy to
              push forward and request more information, make an appeal, and
              potentially follow up with legal or enforcement action?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="a4rFeeling"
              onChange={(e) => onInput(e)}
              value={user.responses.a4rFeeling}
            />
          </Form.Group>
        </Form>
      )}
    </div>
  );
}

export default Reflect;
