import React from "react";
import EntryEditor from "../../EntryEditor.js";
import SignUpAlert from "../../SignUpAlert.js";

import { Link } from "react-router-dom";

function Apply({ user, setUser }) {
  return (
    <div>
      <h3>
        Apply: Follow up with employer regarding accommodation implementation.
      </h3>
      <p>
        If you find that the accommodation isn’t meeting your workplace needs,
        read through the module of this tool called{" "}
        <Link to="/after/section5/learn">
          “What happens if later I need a different or additional RA?”
        </Link>
      </p>
      <p>
        If the accommodation is working well for you, it is important to follow
        up with your HR manager or employer to communicate this information as
        well. Mark your calendar! Plan on providing them with an update roughly
        3 months after your workplace accommodation has been implemented. Draw
        on your Workplace Journal to provide one or two examples of how the
        accommodation improves your ability to perform specific tasks. This
        follow up does not need to be long or elaborate – a short email or quick
        conversation with the HR manager will work.
      </p>
      <p>
        In general, we recommend that you discuss your accommodation during each
        performance review. This is a good opportunity to remind your supervisor
        that you are using an accommodation, provide information on how well it
        is (or is not) working, and potentially raise the issue of other or
        different accommodation needs, should they arise.
      </p>
      {!user ? <SignUpAlert /> : <EntryEditor user={user} setUser={setUser} />}
    </div>
  );
}

export default Apply;
