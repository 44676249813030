import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import sendValues from "../../SendValues.js";
import Table from "react-bootstrap/Table";
import SignUpAlert from "../../SignUpAlert.js";
import getValues from "../../GetValues.js";
import Card from "react-bootstrap/Card";

function Apply({ authToken, user, setUser, setFeedback }) {
  const onInput = ({ target: { value, name } }) => {
    if (value === "true" || value === "false") {
      setUser({
        ...user,
        responses: { ...user.responses, [name]: value === "true" },
      });
    } else {
      setUser({ ...user, responses: { ...user.responses, [name]: value } });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(user.jobTasks, authToken, "/records/jobTasks")
    );
    const nTasks = await getValues(authToken, "/records/jobTasks");
    setUser({
      ...user,
      jobTasks: nTasks,
    });
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
  };

  const handleTaskPropChange = (id, type, evt) => {
    if (evt) {
      const newTasks = user.jobTasks.map((task) => {
        if (id !== task.ID) return task;
        return {
          ...task,
          [type]: Number(evt.target.value),
        };
      });
      setUser({ ...user, jobTasks: newTasks });
    }
  };

  // Determines whether a task is an essential function
  const isEssential = (t) => {
    return (
      t.jobTasksRequired === true &&
      (t.jobTasksTime === true ||
        t.jobTasksOthers === true ||
        t.jobTasksExpertise === true)
    );
  };

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  // Returns a radio button assessment for whether users can complete a task with or without reasonable accommodations
  const taskSurvey = (t) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label>
          <h5>{t.jobTasksTask}</h5>
        </Form.Label>
        <Form.Check
          type="radio"
          name={"jobTasksAccommodation" + t.ID}
          id={"jobTasksAccommodation" + t.ID + "NoAccommodation"}
          onChange={(evt) => {
            handleTaskPropChange(t.ID, "jobTasksAccommodation", evt);
          }}
          value={2}
          checked={
            user.jobTasks.find((taskSaved) => {
              return taskSaved.ID === t.ID;
            }).jobTasksAccommodation === 2
          }
          label="I can perform this task without any accommodations"
        />
        <Form.Check
          type="radio"
          name={"jobTasksAccommodation" + t.ID}
          id={"jobTasksAccommodation" + t.ID + "Accommodation"}
          onChange={(evt) => {
            handleTaskPropChange(t.ID, "jobTasksAccommodation", evt);
          }}
          value={1}
          checked={
            user.jobTasks.find((taskSaved) => {
              return taskSaved.ID === t.ID;
            }).jobTasksAccommodation == 1
          }
          label="I could perform this task with accommodations"
        />
        <Form.Check
          type="radio"
          name={"jobTasksAccommodation" + t.ID}
          id={"jobTasksAccommodation" + t.ID + "Neither"}
          onChange={(evt) => {
            handleTaskPropChange(t.ID, "jobTasksAccommodation", evt);
          }}
          value={0}
          checked={
            user.jobTasks.find((taskSaved) => {
              return taskSaved.ID === t.ID;
            }).jobTasksAccommodation == 0
          }
          label="I could not perform this task with or without accommodations"
        />
      </Form.Group>
    );
  };

  return (
    <div>
      <h3>
        Apply: Confirm that you qualify as a person with a disability under the
        ADA
      </h3>
      <p>
        Now that you have assessed the prerequisites of your job answer the
        questions below to determine whether you can complete those functions
        with or without an accommodation.
      </p>
      {!user ? (
        <SignUpAlert />
      ) : (
        <>
          <Form
            style={{ margin: "0 auto", maxWidth: "30em" }}
            onSubmit={handleSubmit}
          >
            <Form.Label>
              <h3>Essential Functions</h3>
            </Form.Label>
            {user.jobTasks
              .filter((t) => {
                return isEssential(t);
              })
              .map((t) => {
                return taskSurvey(t);
              })}
            <Form.Label>
              <h3>Non-Essential Functions</h3>
            </Form.Label>
            {user.jobTasks
              .filter((t) => {
                return !isEssential(t);
              })
              .map((t) => {
                return taskSurvey(t);
              })}
            {/*<Form.Group className="mb-3">
              <Form.Label>
                <p>
                  <strong>Prerequisites of the Job</strong>
                </p>
                {user.responses.b3rPrerequisitesNotes && (
                  <p>
                    You said: <br />
                    <div className="examples">
                      {user.responses.b3rPrerequisitesNotes}
                    </div>
                  </p>
                )}
                Do you meet the prerequisites of your job?
              </Form.Label>
              <Form.Check
                type="radio"
                name="b3aPrerequisites"
                onChange={(e) => onInput(e)}
                value={true}
                label="Yes"
                checked={user.responses.b3aPrerequisites == true}
              />
              <Form.Check
                type="radio"
                name="b3aPrerequisites"
                onChange={(e) => onInput(e)}
                value={false}
                label="No"
                checked={user.responses.b3aPrerequisites == false}
              />
                </Form.Group>*/}
          </Form>

          {/* Ending feedback */}
          {!user.jobTasks
            .filter((t) => {
              return isEssential(t);
            })
            .every((t) => {
              return t.jobTasksAccommodation > 0;
            }) ? ( // Case: User cannot perform an essential function with or without accommodations
            <Card className="popout">
              <Card.Body>
                It appears that you may not be a full qualified individual with
                a disability. Since you cannot perform all essential functions
                of the job with or without reasonable accommodation. However, it
                is worth talking to your employer to see if they might be
                willing to adjust your job expectations and modify your
                essential functions. Some employers will be happy to do this to
                keep an employee; however, note that the law does not require
                them to do so.
              </Card.Body>
            </Card>
          ) : user.jobTasks
              .filter((t) => {
                return isEssential(t);
              })
              .every((t) => {
                return t.jobTasksAccommodation > 1;
              }) /* Branch: User can perform all essential functions without accommodations */ ? (
            !user.jobTasks
              .filter((t) => {
                return !isEssential(t);
              })
              .every((t) => {
                return t.jobTasksAccommodation == 2;
              }) ? ( // Case: User can perform all essential functions without accommodations but cannot perform all non-essential functions without accommodations
              <Card className="popout">
                <Card.Body>
                  It appears that you can perform all essential functions
                  without accommodations, but either require accommodations for
                  or cannot perform some non-essential functions. Since these
                  are non-essential functions, you are not required to be able
                  to perform them with or without accommodations. You're welcome
                  to request an accommodation, but your employer could also
                  change your duties to not include these tasks instead.
                </Card.Body>
              </Card>
            ) : (
              // Case: User can perform all essential and non-essential functions without accommodations.
              <Card className="popout">
                <Card.Body>
                  It appears that you can perform all essential and
                  non-essential functions without accommodations. An employer is
                  not required to provide accommodations for job functions that
                  you are able to perform without accommodations.
                </Card.Body>
              </Card>
            )
          ) : // Branch: User can perform all or some essential functions with accommodations
          !user.jobTasks
              .filter((t) => {
                return !isEssential(t);
              })
              .every((t) => {
                return t.jobTasksAccommodation == 2;
              }) ? ( // Case: User cannot perform all essential and non-essential functions without accommodations.
            <Card className="popout">
              <Card.Body>
                <p>
                  It appears that you are a qualified individual with a
                  disability as defined by the ADA. Next we will move on to
                  identify accommodations that may support you in employment.{" "}
                </p>
                <p>
                  It also appears that you require accommodations for or cannot
                  perform some non-essential functions. Since these are
                  non-essential functions, you are not required to be able to
                  perform them with or without accommodations. You're welcome to
                  request an accommodation, but your employer could also change
                  your duties to not include these tasks instead.
                </p>
              </Card.Body>
            </Card>
          ) : (
            // Case: User can perform all non-essential functions without accommodations but cannot perform all essential functions without accommodations
            <Card className="popout">
              <Card.Body>
                It appears that you are a qualified individual with a disability
                as defined by the ADA. Next we will move on to identify
                accommodations that may support you in employment.
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </div>
  );
}

export default Apply;
