import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import sendValues from "../../SendValues.js";
import SignUpAlert from "../../SignUpAlert.js";

function Apply({ authToken, user, setUser, setFeedback }) {
  const onInput = ({ target: { value, name } }) =>
    setUser({ ...user, responses: { ...user.responses, [name]: value } });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
  };

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h3>
        Apply: Write a statement defining how you qualify as a person with a
        disability under the ADA
      </h3>
      <p>
        Many disabilities are obvious, and you will not need to make the case to
        your employer that you qualify as a person with a disability under the
        ADA. Others may be less obvious to your employer. It can be helpful to
        write a statement about how you qualify using the language that is in
        the ADA. This prepares you to use that language when speaking with your
        employer or HR representative.
      </p>
      {!user ? (
        <SignUpAlert />
      ) : (
        <div>
          {user.responses.b2rImpair ? (
            <div>
              <h4>
                You said that you have an impairment that limits a major life
                activity or body system function
              </h4>
              <Form
                style={{ margin: "0 auto", maxWidth: "30em" }}
                onSubmit={handleSubmit}
              >
                <Form.Group className="mb-3">
                  <Form.Label>I have...</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="b2aImpairment"
                    onChange={(e) => onInput(e)}
                    placeholder="Name of impairment"
                    value={user.responses.b2aImpairment}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>This limits my...</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="b2aLimits"
                    onChange={(e) => onInput(e)}
                    placeholder="Identify major life activity or body system"
                    value={user.responses.b2aLimits}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    This limitation is substantial as evidenced by...
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="b2aEvidence"
                    onChange={(e) => onInput(e)}
                    value={user.responses.b2aEvidence}
                  />
                </Form.Group>
              </Form>
            </div>
          ) : user.responses.b2rRecord ? (
            <div>
              <h4>
                You said you have a record of having an impairment that limits a
                major life activity or body system function
              </h4>
              <Form
                style={{ margin: "0 auto", maxWidth: "30em" }}
                onSubmit={handleSubmit}
              >
                <Form.Group className="mb-3">
                  <Form.Label>I have a record of having:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="b2aImpairment"
                    onChange={(e) => onInput(e)}
                    placeholder="Name of impairment"
                    value={user.responses.b2aImpairment}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    People think that this limits me in the following ways:
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="b2aLimits"
                    onChange={(e) => onInput(e)}
                    placeholder="Identify major life activity or body system"
                    value={user.responses.b2aLimits}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    This limitation is considered substantial as evidenced by:
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="b2aEvidence"
                    onChange={(e) => onInput(e)}
                    value={user.responses.b2aEvidence}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>The impairment is considered:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="b2aPermanent"
                    onChange={(e) => onInput(e)}
                    placeholder="Describe whether it is permanent or long-term"
                    value={user.responses.b2aPermanent}
                  />
                </Form.Group>
                {/*<Button variant="outline-dark" type="submit">
                  Submit
          </Button>*/}
              </Form>
            </div>
          ) : user.responses.b2rRegarded ? (
            <div>
              <p>
                You said that you were regarded as having an impairment. People
                who are regarded as having an impairment do not have a right to
                reasonable accommodation under the law, but they do have
                protection from discrimination. The rest of this tool focuses on
                reasonable accommodations. If you need assistance understanding
                if you have been discriminated against based on being regarded
                as having an impairment, please contact the{" "}
                <a
                  href="https://adata.org/technical-assistance"
                  target="_blank"
                >
                  ADA National Network
                </a>
                .
              </p>
            </div>
          ) : (
            <div>
              <h4>You said that you did not have a disability</h4>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Apply;
