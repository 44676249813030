function Learn() {
  return (
    <div>
      <h1>After the request</h1>
      <h2>Section 8</h2>
      <h3>
        Learn: What are some challenges that can occur after requesting and/or
        receiving an RA?
      </h3>
      <p>
        After you have requested a reasonable accommodation in your workplace
        and discussed potential accommodations that might work for you, your
        employer will provide you with a decision about your request. Reasonable
        accommodations decisions commonly fall into one of three categories:
      </p>
      <ol>
        <li>The accommodation requested by the employee is approved.</li>
        <li>A different accommodation than the one requested is offered.</li>
        <li>
          The accommodation request is denied and no accommodation is provided.{" "}
        </li>
      </ol>
      <p>
        Challenges may arise with any of these decisions. The next sections of
        this decision aid offer guidance to help you deal with these challenges.
      </p>
      <h4>Managing expectations of others</h4>
      <p>
        Challenges may also arise with fellow colleagues, co-workers, or
        supervisors after an accommodation has been implemented, or even as a
        result of merely requesting a reasonable accommodation. The fact that
        you requested and/or have been provided an accommodation is private
        information shared only between you and your human resources manager or
        employer. However, co-workers and direct supervisors may become aware of
        your accommodation if the accommodation is observable to others (such as
        an assistive device or change in your schedule) or if the accommodation
        necessarily requires others to change the way they interact with you
        (for example, switching from providing a list of tasks to you verbally
        to providing a written list to accommodate cognitive fatigue).
      </p>
      <p>
        If you anticipate that others will become aware of your accommodation
        (or request for accommodation) at work, it can be helpful to prepare a
        few talking points to guide discussions with colleagues and co-workers.
        Preparing for those conversations before they occur will allow you to
        decide what information, if any, you want to share with your colleagues
        and help you to manage their expectations about your continued
        performance at work.
      </p>
      <h4>Recognizing discriminatory practices or hostile work environments</h4>
      <p>
        How others respond to knowledge of your accommodation will vary
        depending on a number of things, such as their individual experiences
        with, knowledge of, or assumptions about specific impairments or
        disability more broadly. Discrimination based on disability can take
        many forms, including creating a hostile work environment.
      </p>
      <p>
        There are laws against workplace discrimination, harassment and hostile
        work environments. The{" "}
        <a rel="noreferrer" target="_blank" href="https://www.eeoc.gov/">
          Equal Employment and Opportunity Commission
        </a>{" "}
        (EEOC) is the federal agency that enforces federal laws that make it
        illegal to discriminate against a job applicant or employee because of
        disability and other characteristics, like race or age. To be considered
        harassment, a person’s conduct must create a work environment that would
        be intimidating, hostile, or offensive to reasonable people.
      </p>
      <p>
        Offensive conduct may include, but is not limited to, offensive jokes,
        slurs, epithets or name calling, physical assaults or threats,
        intimidation, ridicule or mockery, insults or put-downs, offensive
        objects or pictures, and interference with work performance. Harassment
        can occur in a variety of circumstances, including, but not limited to,
        the following:
      </p>
      <ul>
        <li>
          The harasser can be the victim's supervisor, a supervisor in another
          area, an agent of the employer, a co-worker, or a non-employee.
        </li>
        <li>
          The victim does not have to be the person harassed but can be anyone
          affected by the offensive conduct.
        </li>
        <li>
          Unlawful harassment may occur without economic injury to, or discharge
          of, the victim.
        </li>
      </ul>
      <p>
        For more information about how workplace harassment is defined according
        to the EEOC,{" "}
        <a target="_blank" href="https://www.eeoc.gov/harassment">
          visit their website.
        </a>
      </p>
    </div>
  );
}

export default Learn;
