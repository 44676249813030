import React from "react";
import EntryEditor from "../../EntryEditor.js";
import SignUpAlert from "../../SignUpAlert.js";

function Reflect({ user, setUser }) {
  return (
    <div>
      <h3>Reflect: Is the accommodation working well for me?</h3>
      <p>
        If you have been provided an accommodation, it may take some time to get
        used to it. Any change in the way you perform your work can create
        unexpected challenges. It will be useful for you to keep a work diary or
        journal for the first few months of using a new accommodation to reflect
        on a daily basis how well it is working for you and identify if there
        are specific challenges to using the accommodation at work. Generate a
        short list of ways in which the accommodation is intended to help you
        with work tasks. Try to consistently jot down reflections like:
      </p>
      <ul>
        <li>
          Are any of my work tasks easier to complete (i.e., able to complete
          more quickly; able to complete with less pain, stress, worry, or
          exertion of effort; able to complete to my satisfaction) with the
          accommodation than without it?
        </li>
        <li>
          Are any of my tasks more difficult to complete with the accommodation?
        </li>
      </ul>
      <p>
        Jot down any moments you find yourself frustrated with the accommodation
        or feeling surprised at how easy something was with the accommodation
        compared to without it. By documenting concrete examples of what works
        well and what doesn’t for a few weeks or months, you will be able to
        more clearly explain to your employer (or future employers) the benefits
        of this accommodation. If helpful, use the Workplace Journal as a way to
        document this information.
      </p>
      <p>
        In the event you determine that the accommodation is not working well,
        your journal will also serve as documentation that you made an effort to
        use this accommodation and will enable you to more clearly explain why
        this accommodation is not meeting the needs you expected it to.
      </p>
      {!user ? <SignUpAlert /> : <EntryEditor user={user} setUser={setUser} />}
    </div>
  );
}

export default Reflect;
