import React from "react";
import EntryEditor from "../../EntryEditor.js";
import SignUpAlert from "../../SignUpAlert.js";

function Apply({ user, setUser }) {
  return (
    <div>
      <h3>
        Apply: Should I challenge the decision and request a different
        accommodation?
      </h3>
      <p>
        If after reflection you decide to the current accommodation works well
        enough, mark your calendar to come back and repeat this process in 6
        months. This will give you plenty of time to get used to the
        accommodation and to prepare what you plan to say about the
        accommodation during your next performance evaluation or annual review.
        Remember, you can ask for a different or additional accommodation at any
        time!
      </p>
      <h4>
        I do want to request a different accomodation. How should I challenge
        the decision?
      </h4>
      <p>
        If you have determined that the accommodation provided does not meet
        your needs and you want to challenge the decision made to provide this
        specific accommodation over the one you suggested, you will need to
        follow the same steps for challenging the decision that you did for
        making the initial accommodation request.
      </p>
      <p>
        Using the information from your Workplace Journal, write out a short
        statement outlining: first, the ways the accommodation works well for
        you and second, the limitations (or drawbacks) of this specific
        accommodation in completing your work. Providing specific examples of
        the limitations of the accommodation will best show why this
        accommodation is not as helpful as the one you originally requested or a
        different accommodation could be.
      </p>
      {!user ? <SignUpAlert /> : <EntryEditor user={user} setUser={setUser} />}
    </div>
  );
}

export default Apply;
