import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import sendValues from "../../SendValues.js";
import { useEffect } from "react";
import SignUpAlert from "../../SignUpAlert.js";
import Card from "react-bootstrap/Card";

function Reflect({ authToken, user, setUser, setFeedback }) {
  const onInput = ({ target: { value, name } }) => {
    setUser({ ...user, responses: { ...user.responses, [name]: value } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
  };

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h3>
        Reflect: What are the risks in my current or prospective workplace
        environment?
      </h3>
      <p>
        Spend some time now thinking about your current or prospective
        workplace. The questions below provide you with some guidance. You may
        want to ask for information from people you work with or who work at the
        company to which you are applying.
      </p>
      {!user ? (
        <SignUpAlert />
      ) : (
        <Form
          style={{ margin: "0 auto", maxWidth: "30em" }}
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-3">
            <Form.Label>
              Are there currently other employees who are clearly using
              disability accommodations?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="b5rOthers"
              onChange={(e) => onInput(e)}
              value={user.responses.b5rOthers}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Have you heard any stories from work colleagues about their own
              experiences negotiating accommodations in this workplace? If so,
              what information can you gather from their experience?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="b5rStories"
              onChange={(e) => onInput(e)}
              value={user.responses.b5rStories}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Are there any company-based affinity groups, or employee resource
              groups that bring together employees with similar backgrounds or
              interests, based on disability or health?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="b5rGroups"
              onChange={(e) => onInput(e)}
              value={user.responses.b5rGroups}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Can you find any company postings (on bulletin boards, company
              website, or pamphlets provided to everyone) with a clear company
              statement outlining how to request a reasonable accommodation
              (such as, who specifically to talk to, what information you should
              provide, a timeline or set of steps for the process)?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="b5rPostings"
              onChange={(e) => onInput(e)}
              value={user.responses.b5rPostings}
            />
          </Form.Group>
        </Form>
      )}
      <Card className="popout">
        <Card.Body>
          <Card.Title>LESSONS FROM LIVED EXPERIENCES</Card.Title>
          <Card.Text>
            <p>
              <q>
                This was kind of good luck for me, but bad luck for this other
                colleague. Another colleague was going through a similar
                situation with a different condition, where she also was seeking
                accommodations from the same department. And she … had not
                gotten a great response. …So, with that in mind, I kind of tried
                to bypass that person... I actually kind of put my ear to the
                ground and was trying to find, OK, who were the kind of
                [supervisors] who I know are, you know, a little more
                accommodating and welcoming. So, I was able to talk to one or
                two of the [supervisors] and they were able to kind of … mediate
                my concerns … For me, that was the first time I'd ever really
                kind of outed myself as someone with a disability. So
                fortunately, with these other people kind of helping me, it was
                great and they were understanding. I hate that I had to learn
                from someone who did not have such a clear path about who not to
                go to.
              </q>
            </p>
            <p>– Camille, living with anxiety and depression</p>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Reflect;
