import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import sendValues from "../../SendValues.js";
import { useEffect } from "react";
import SignUpAlert from "../../SignUpAlert.js";

function Apply({ authToken, user, setUser, setFeedback }) {
  const onInput = ({ target: { value, name } }) => {
    setUser({ ...user, responses: { ...user.responses, [name]: value } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
  };
  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h3>
        Apply: How do my assumptions and beliefs about disability affect my
        willingness to request a reasonable accommodation?
      </h3>
      <p>
        Regardless of how you view yourself, if you meet the legal definition of
        disability under the ADA (discussed in the next section), you have
        rights and protections in the workplace under the ADA. But how you view
        yourself may affect whether you want to disclose your disability and
        request an accommodation. Based on your responses in the Reflection
        section, write a response to the following prompts.
      </p>
      {!user ? (
        <SignUpAlert />
      ) : (
        <Form
          style={{ margin: "0 auto", maxWidth: "30em" }}
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-3">
            <Form.Label>
              What does my disability mean to me? How do I identify?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="b1aIdentity"
              onChange={(e) => onInput(e)}
              value={user.responses.b1aIdentity}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Am I prepared to disclose my disability, at least for the purpose
              or requesting accommodations?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="b1aDisclosure"
              onChange={(e) => onInput(e)}
              value={user.responses.b1aDisclosure}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              What do I need to do to make myself more comfortable with the idea
              of disability—who can I talk with, what group or groups could I
              connect with?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="b1aComfort"
              onChange={(e) => onInput(e)}
              value={user.responses.b1aComfort}
            />
          </Form.Group>
          {/*<Button variant="outline-dark" type="submit">
            Submit
      </Button>*/}
        </Form>
      )}
    </div>
  );
}

export default Apply;
