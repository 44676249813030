import { styles, ListItem } from "./StylesPDF.js";
import { Document, Page, Text, Font, StyleSheet } from "@react-pdf/renderer";

const MyFunctionsPDF = ({ user }) => (
  <Document>
    <Page style={styles.body} size="LETTER">
      <Text style={styles.title}>My Reasonable Accommodation Steps</Text>
      <Text style={styles.subtitle}>
        Here are the steps I will take to make my reasonable accommodation
        request:
      </Text>
      {user.reqSteps.map((step) => {
        if (step.reqStepsStep == "") {
          return;
        }
        return (
          <ListItem>
            {step.reqStepsStep}. {step.reqStepsStepDescription}
          </ListItem>
        );
      })}
    </Page>
  </Document>
);

export default MyFunctionsPDF;
