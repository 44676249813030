import React from "react";
import NextButton from "./modules/NextButton";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

const Intro = () => {
  return (
    <React.Fragment>
      <h1>Requesting Reasonable Accommodations in Employment</h1>
      <h2>A Decision Support Aid</h2>
      <h3>What are Reasonable Accommodations?</h3>
      <p>
        The Americans with Disabilities Act (ADA) requires employers to provide
        reasonable accommodations to qualified people with disabilities.
        Reasonable accommodations are changes in the work environment that are
        intended to ‘even the playing field’ and provide equal opportunity for
        people with disabilities. Many people with disabilities struggle to find
        work or maintain employment because unnecessary barriers block them from
        being fully productive or from gaining employment at all. Barriers in a
        workplace can be:
      </p>
      <ol>
        <li>Physical, like inaccessible buildings.</li>
        <li>Electronic, like software or websites.</li>
        <li>Procedural, like policies about work hours or timing of breaks.</li>
      </ol>
      <p>
        For example, someone who is blind may be an excellent technical writer
        but will not be able to use a computer that requires controlling a
        pointing device like a computer mouse. A reasonable accommodation would
        be to purchase a type of software that reads the screen out loud.
        Someone with multiple sclerosis may be an excellent architect but
        experience fatigue when working long hours. A reasonable accommodation
        would be an adjusted work schedule with more opportunities for breaks.
      </p>
      <p>
        Reasonable accommodations enable you to perform your work tasks more
        easily and efficiently than without them. Being more efficient at work
        may also help you better balance your work and personal life, which is
        likely to improve not only the quality of your work, but also your
        quality of life. It may increase the length of time you can remain in
        the workforce. On the other hand, working without a reasonable
        accommodation may require you to push beyond your limits, potentially
        making you less productive than other employees, more anxious about your
        ability to perform your work, and decrease the energy you have both in
        the workplace and at home. Working without a reasonable accommodation
        may also make your condition worsen, for example, increase your pain,
        fatigue, anxiety, or depression.
      </p>

      <Card className="popout">
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h4>
                Learn more: People with disabilities (person-first language)? Or
                disabled people (identity-first language)?
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Language is always changing, and that is true for language about
                disability as well. There are two primary ways people
                communicate about disability—person-first and identity-first. In
                person-first language, the person is the focus of attention, not
                the disability. Some examples include ‘people with disabilities’
                or ‘the man with paraplegia.’ Person-first language was
                developed as a response to older, negative language that made
                the disability the focus, like ‘the paraplegic man’ or ‘the
                stroke victim.’ More recently, some disability advocates have
                moved to identity-first language because they want to highlight
                their disability identity. This is most common in communities
                who are autistic or deaf, but many people may describe
                themselves as disabled, rather than a person with a disability.
                Many service providers like rehabilitation counselors or special
                education teachers will use person-first language because that
                is what they were taught was most respectful. But many people
                with disabilities will prefer identity-first language. As in
                other arenas, it is best to listen and use the preferred
                language of the person with whom you are interacting. In this
                decision aid, we use both! You can read more about
                disability-related language here:{" "}
                <a
                  href="https://adata.org/factsheet/ADANN-writing"
                  target="_blank"
                >
                  Guidelines for Writing About People with Disabilities
                </a>
                .
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>

      <h3>Why Might You Hesitate to Request a Reasonable Accommodation?</h3>
      <p>
        It can be difficult to make a request for an accommodation, and like
        many people, you may put it off and wait too long before making the
        request. Below we'll discuss why this happens and why it is important to
        start sooner than later.
      </p>
      <Accordion flush alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            You may not see how your condition is affecting your work
            performance until it is too late
          </Accordion.Header>
          <Accordion.Body>
            <p>
              You may know you are having trouble. Maybe you are fatigued all
              the time and your thinking is fuzzy. Or maybe you are having a lot
              of pain, which affects your ability to work long stretches of
              time. You may find yourself just 'pushing through' trying to make
              it work and hoping it will get better. And you may not realize
              that your supervisor is concerned about your work quality until
              you get a formal performance evaluation. This can be a wakeup call
              that shows you that something needs to change. For some people
              this wakeup call comes too late and their supervisor's view of
              them has already soured. Sometimes people are placed on notice and
              are subject to disciplinary procedures. Once this happens, it is
              difficult to change a supervisor's mind. It is much better to
              consider your needs and request accommodation before any formal HR
              proceedings have begun.
            </p>
            <Card className="popout">
              <Card.Body>
                <Card.Title>Lessons from Lived Experience</Card.Title>
                <Card.Text>
                  <p>
                    <q>
                      Because of my obvious mobility disability, if there's
                      extreme weather, I'm either going to be delayed getting
                      into work or may need to ask to work from home if there's
                      too much ice and snow. … But that was the limit of what I
                      would ask for. I could have got -- I mean honestly, I
                      could have asked for, and I should have asked for a
                      flexible schedule sooner, because aging with spasticity
                      has been quite the misadventure. And honestly, for
                      decades, I just pushed myself. I pushed myself and pushed
                      myself and 50 years of crutch use and using a backpack
                      [had] a significant impact on my shoulders and I've
                      developed carpal tunnel, bilaterally. And now, I'm much
                      more significantly functionally impaired than I was.
                    </q>
                  </p>
                  <p>–Beatrice, living and working with CP</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            You may not view your challenges as a disability issue, and you may
            not realize that you have the right under law to request
            accommodation
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Some people don't think about their condition as a disability;
              They may think "I'm just getting older, and my hearing isn't very
              good, but there isn't anything I can do about it—I'll just have to
              cope." Instead, they could think, "I'm getting older, and my
              hearing is becoming a problem—I have a right to accommodations
              that will give me access to audible information, so I can continue
              to work." We'll talk more about different views of disability
              later in this tool. It will be important for you to understand how
              disability is defined in the Americans with Disabilities Act
              (ADA), the law that grants you the right to reasonable
              accommodation and nondiscrimination in employment. The ADA
              requires that you disclose your disability to request an
              accommodation, so it will be important for you to think about your
              own beliefs and values related to disability.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            You may believe that requesting an accommodation is the same as
            requesting extra help or assistance
          </Accordion.Header>
          <Accordion.Body>
            <p>
              {" "}
              In the United States, we strongly value independence and may
              perceive help-seeking as a form of weakness. You may think that
              you should be able to do your work 'independently', and if you
              must ask for an accommodation, you are showing that you are not
              capable or competent. You may believe that if you ask for an
              accommodation, it isn't fair to your coworkers who will not have
              the same accommodations. So, instead of requesting an
              accommodation, you just try to work harder or find ways to get
              your work done without asking for anything. This is known as
              'self-accommodation' where people make changes on their own
              instead of requesting them from their employer. It is important to
              realize that if you have a disability, you have a right to request
              a reasonable accommodation. This right is designed to protect you
              from intentional or unintentional discrimination. Asking for an
              accommodation before you have problems is a proactive way of
              ensuring that you will be able to continue to work. It not only
              helps you, but also your employer because they are able to keep a
              valuable employee and avoid the costs associated with rehiring and
              training a new employee.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            You may fear that asking for an accommodation may lower your value
            in the eyes of your employer or coworkers
          </Accordion.Header>
          <Accordion.Body>
            <p>
              This is a reasonable fear because people with disabilities do
              experience discrimination and many of our society's cultural
              beliefs about disability result in bias and stigma. Later in this
              tool, we will talk about the risks associated with disability
              disclosure. Everyone needs to make their own risk assessment that
              includes an evaluation of their current workplace, their
              supervisor and coworkers, and their own beliefs about disability.
              Everyone with a disability who is qualified to do the work has the
              right to accommodation, but sometimes the barriers in a workplace
              may seem too high or not worth the trouble to fight. You will have
              to decide based on your own context what is the right choice for
              you now.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            You may wait too long to request an accommodation because the
            process of requesting an accommodation seems daunting
          </Accordion.Header>
          <Accordion.Body>
            <p>
              It requires you to understand and describe your disability,
              understand and evaluate your work setting, think about what kind
              of accommodations would be effective, understand the procedures
              used in your specific company, submit the request, and provide
              documentation. It is completely reasonable to feel intimidated by
              this process! And it is important to recognize that this process
              may take some time and energy. But the fact that you are here
              using this tool means that you have already started the work and
              will have the support and guidance you need to make a strong
              request for accommodation.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <NextButton text="Next: Before the Request" to="/before/section1/learn" />
    </React.Fragment>
  );
};

export default Intro;
