import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import sendValues from "../../SendValues.js";
import SignUpAlert from "../../SignUpAlert.js";
import Card from "react-bootstrap/Card";

function Reflect({ authToken, user, setUser, setFeedback }) {
  const onInput = ({ target: { value, name } }) => {
    if (value === "true" || value === "false") {
      setUser({
        ...user,
        responses: { ...user.responses, [name]: value === "true" },
      });
    } else {
      setUser({ ...user, responses: { ...user.responses, [name]: value } });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(
      await sendValues(
        user.responses,
        authToken,
        "/records/responses/" + user.responses.ID
      )
    );
  };

  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h3>Reflect: Do I have a disability as defined by the ADA?</h3>
      <p>
        Choose the definition that is true for you by answering the questions
        below and write a statement describing how you meet that definition.
      </p>
      {!user ? (
        <SignUpAlert />
      ) : (
        <Form
          style={{ margin: "0 auto", maxWidth: "30em" }}
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-3">
            <Form.Group className="mb-3">
              <Form.Label>
                Do you have an impairment that limits a major life activity or
                body system functions?
              </Form.Label>
              <Form.Check
                type="radio"
                name="b2rImpair"
                id="b2rImpairTrue"
                onChange={(e) => onInput(e)}
                value={true}
                label="Yes"
                checked={user.responses.b2rImpair}
              />
              <Form.Check
                type="radio"
                name="b2rImpair"
                id="b2rImpairFalse"
                onChange={(e) => onInput(e)}
                value={false}
                label="No"
                checked={user.responses.b2rImpair === false}
              />
            </Form.Group>
            {/*user.responses.b2rImpair && ( // Redudant too B2A
              <div>
                <Form.Label>I meet this definition because:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="b2rImpairNotes"
                  onChange={(e) => onInput(e)}
                  value={user.responses.b2rImpairNotes}
                />
              </div>
            )*/}
          </Form.Group>
          {user.responses.b2rImpair ? (
            <React.Fragment>
              <Form.Group className="mb-3">
                <Form.Label>
                  Is the impact on major life activities or body system
                  functions substantial?
                  <ul>
                    <li>
                      Based on the level of impact when not using mitigating
                      measures (e.g., medication, assistive device, receiving
                      treatment, etc.).
                    </li>
                    <li>Based on comparison with general population/peers.</li>
                  </ul>
                </Form.Label>
                <Form.Check
                  type="radio"
                  name="b2rSubstantial"
                  id="b2rSubstantialTrue"
                  onChange={(e) => onInput(e)}
                  value={true}
                  label="Yes"
                  checked={user.responses.b2rSubstantial}
                />
                <Form.Check
                  type="radio"
                  name="b2rSubstantial"
                  id="b2rSubstantialFalse"
                  onChange={(e) => onInput(e)}
                  value={false}
                  label="No"
                  checked={user.responses.b2rSubstantial === false}
                />
                {/*<Form.Label>Notes/Reflections</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="b2rSubstantialNotes"
                  onChange={(e) => onInput(e)}
                  value={user.responses.b2rSubstantialNotes}
                      />*/}
              </Form.Group>
              {user.responses.b2rSubstantial ? (
                <Form.Label>
                  <Card className="examples">
                    <Card.Body>
                      You said you have an impairment that limits a major life
                      activity or body system functions. This means you can move
                      on to the next step and learn about how the ADA defines a{" "}
                      <strong>qualified</strong> person with a disability and
                      how that relates to requesting reasonable accommodations.
                    </Card.Body>
                  </Card>
                </Form.Label>
              ) : (
                <Form.Label>
                  <Card className="examples">
                    <Card.Body>
                      The ADA covers people who have a disability that
                      substantially impacts a major life activity or body system
                      function.
                    </Card.Body>
                  </Card>
                </Form.Label>
              )}
            </React.Fragment>
          ) : user.responses.b2rImpair === false ? (
            <React.Fragment>
              <Form.Group className="mb-3">
                <Form.Label>
                  Do you have a record of having an impairment?
                </Form.Label>
                <Form.Check
                  type="radio"
                  name="b2rRecord"
                  id="b2rRecordTrue"
                  onChange={(e) => onInput(e)}
                  value={true}
                  label="Yes"
                  checked={user.responses.b2rRecord}
                />
                <Form.Check
                  type="radio"
                  name="b2rRecord"
                  id="b2rRecordFalse"
                  onChange={(e) => onInput(e)}
                  value={false}
                  label="No"
                  checked={user.responses.b2rRecord === false}
                />
                {user.responses.b2rRecord && (
                  <div>
                    <Form.Label>I meet this definition because:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="b2rRecordNotes"
                      onChange={(e) => onInput(e)}
                      value={user.responses.b2rRecordNotes}
                      style={{ marginBottom: "20px" }}
                    />
                    <Form.Label>
                      <Card className="examples">
                        <Card.Body>
                          You said you have a record of having an impairment.
                          People who have a record of having an impairment may
                          be eligible to request reasonable accommodation.
                          However, this is not common and it may be complicated
                          to determine your rights as a person with a record of
                          impairment. The rest of this tool focuses on
                          reasonable accommodations for people who have an
                          impairment that limits a major life activity or body
                          system. If you need assistance understanding if you
                          have been discriminated against based on having a
                          record of having an impairment, please contact the{" "}
                          <a
                            target="_blank"
                            href="https://adata.org/technical-assistance"
                          >
                            ADA National Network
                          </a>{" "}
                          for free assistance from a support specialist.
                        </Card.Body>
                      </Card>
                    </Form.Label>
                  </div>
                )}
              </Form.Group>
              {user.responses.b2rRecord === false && (
                <Form.Group className="mb-3">
                  <Form.Label>
                    Are you regarded as having an impairment?
                  </Form.Label>
                  <Form.Check
                    type="radio"
                    name="b2rRegarded"
                    id="b2rRegardedTrue"
                    onChange={(e) => onInput(e)}
                    value={true}
                    label="Yes"
                    checked={user.responses.b2rRegarded}
                  />
                  <Form.Check
                    type="radio"
                    name="b2rRegarded"
                    id="b2rRegardedFalse"
                    onChange={(e) => onInput(e)}
                    value={false}
                    label="No"
                    checked={user.responses.b2rRegarded === false}
                  />
                  {user.responses.b2rRegarded ? (
                    <div>
                      <Form.Label>I meet this definition because:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="b2rRegardedNotes"
                        onChange={(e) => onInput(e)}
                        value={user.responses.b2rRegardedNotes}
                        style={{ marginBottom: "20px" }}
                      />
                      <Form.Label>
                        <Card className="examples">
                          <Card.Body>
                            You said you are regarded as having an impairment.
                            People who are regarded as having an impairment do
                            not have a right to reasonable accommodation under
                            the law, but they do have protection from
                            discrimination. The rest of this tool focuses on
                            reasonable accommodations for people who have an
                            impairment that limits a major life activity or body
                            system. If you need assistance understanding if you
                            have been discriminated against based being regarded
                            as having an impairment, please contact the{" "}
                            <a
                              href="https://adata.org/technical-assistance"
                              target="_blank"
                            >
                              ADA National Network
                            </a>
                            .
                          </Card.Body>
                        </Card>
                      </Form.Label>
                    </div>
                  ) : (
                    <Card className="examples">
                      <Card.Body>
                        You do not appear to have a disability as defined by the
                        ADA. If you need assistance understanding the definition
                        of disability under the ADA, please contact the{" "}
                        <a
                          href="https://adata.org/technical-assistance"
                          target="_blank"
                        >
                          ADA National Network
                        </a>{" "}
                        for free assistance from a support specialist.”
                      </Card.Body>
                    </Card>
                  )}
                </Form.Group>
              )}
            </React.Fragment>
          ) : null}
        </Form>
      )}
    </div>
  );
}

export default Reflect;
