import React, { useEffect } from "react";

const Learn = ({ setFeedback }) => {
  // Clears feedback so it doesn't render in the next section.
  useEffect(() => {
    return () => setFeedback(null);
  }, []);

  return (
    <div>
      <h1>Before the request</h1>
      <h2>Section 6</h2>
      <h3>Learn: Personal risk tolerance and accommodations</h3>
      <p>
        As we noted in a previous section, disclosing a disability may expose
        you to stigma and discrimination that can lead to negative experiences.
        This isn’t fair, but it still happens, and it is important to carefully
        assess the risk and your ability to respond if things do go badly.
      </p>
      <p>
        The risks associated with disclosing a disability will vary depending on
        the individuals involved and the social environment of each workplace.
        In a previous section, you analyzed your workplace to assess how
        disability friendly it was. This is one important part of assessing
        risk. But anticipating how serious the potential risks of disability
        disclosure are to you depends on many personal factors as well. To put
        it another way, two people working in the same job at the same workplace
        may face the same level of “risk” by disclosing a disability, but the
        degree to which either of those two people have the energy, resources,
        and willingness to deal with that risk will be different depending on a
        number of unique factors.
      </p>
      <p>
        How big of a risk you are willing to take depends on your external and
        internal strengths. For example, if you have money in savings and could
        deal with a potential job loss, you may be more willing to take a risk.
        Similarly, if you have good friends and family who ‘have your back’ and
        can help you when things go badly at work, you may be more willing to
        take a risk. Finally, if you are an assertive person who likes to fight
        for their rights, who is self-confident, and has a positive self-image,
        you may feel that you are able to deal with negative energy that may
        come your way and be willing to take a risk.
      </p>
      <p>
        It is important to note that you have the <strong>right</strong> to
        request a reasonable accommodation. In an ideal world, there would be no
        barriers to making a request. But the ADA exists because discrimination
        against people with disabilities is real and it is important for you to
        be brave and determined, but also realistic as you make these decisions.
      </p>
      <p>
        In the next section, you will reflect on your strengths, resources, and
        risk tolerance.
      </p>
    </div>
  );
};

export default Learn;
