let Steps = {};

//Before

//Section 1
Steps["beforesection1learn"] = require("./before/section_1/Learn.js").default;
Steps["beforesection1reflect"] =
  require("./before/section_1/Reflect.js").default;
Steps["beforesection1apply"] = require("./before/section_1/Apply.js").default;

//Section 2
Steps["beforesection2learn"] = require("./before/section_2/Learn.js").default;
Steps["beforesection2reflect"] =
  require("./before/section_2/Reflect.js").default;
Steps["beforesection2apply"] = require("./before/section_2/Apply.js").default;

//Section 3
Steps["beforesection3learn"] = require("./before/section_3/Learn.js").default;
Steps["beforesection3reflect"] =
  require("./before/section_3/Reflect.js").default;
Steps["beforesection3apply"] = require("./before/section_3/Apply.js").default;

//Section 4
Steps["beforesection4learn"] = require("./before/section_4/Learn.js").default;
Steps["beforesection4reflect"] =
  require("./before/section_4/Reflect.js").default;
Steps["beforesection4apply"] = require("./before/section_4/Apply.js").default;

//Section 5
Steps["beforesection5learn"] = require("./before/section_5/Learn.js").default;
Steps["beforesection5reflect"] =
  require("./before/section_5/Reflect.js").default;
Steps["beforesection5apply"] = require("./before/section_5/Apply.js").default;

//Section 6
Steps["beforesection6learn"] = require("./before/section_6/Learn.js").default;
Steps["beforesection6reflect"] =
  require("./before/section_6/Reflect.js").default;
Steps["beforesection6apply"] = require("./before/section_6/Apply.js").default;

//Section 7
Steps["beforesection7decide"] =
  require("./before/section_7/Decision.js").default;

//During
//Section 1
Steps["duringsection1learn"] = require("./during/section_1/Learn.js").default;
Steps["duringsection1reflect"] =
  require("./during/section_1/Reflect.js").default;
Steps["duringsection1apply"] = require("./during/section_1/Apply.js").default;

//After
//Section 1
Steps["aftersection1learn"] = require("./after/section_1/Learn.js").default;
Steps["aftersection1reflect"] = require("./after/section_1/Reflect.js").default;
Steps["aftersection1apply"] = require("./after/section_1/Apply.js").default;

//Section 2
Steps["aftersection2learn"] = require("./after/section_2/Learn.js").default;
Steps["aftersection2reflect"] = require("./after/section_2/Reflect.js").default;
Steps["aftersection2apply"] = require("./after/section_2/Apply.js").default;

//Section 3
Steps["aftersection3learn"] = require("./after/section_3/Learn.js").default;
Steps["aftersection3reflect"] = require("./after/section_3/Reflect.js").default;
Steps["aftersection3apply"] = require("./after/section_3/Apply.js").default;

//Section 4
Steps["aftersection4learn"] = require("./after/section_4/Learn.js").default;
Steps["aftersection4reflect"] = require("./after/section_4/Reflect.js").default;
Steps["aftersection4apply"] = require("./after/section_4/Apply.js").default;

//Section 5
Steps["aftersection5learn"] = require("./after/section_5/Learn.js").default;
Steps["aftersection5reflect"] = require("./after/section_5/Reflect.js").default;
Steps["aftersection5apply"] = require("./after/section_5/Apply.js").default;

export default Steps;
