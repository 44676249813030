import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Navigate } from "react-router-dom";

const Settings = ({ user, setUser, token, setToken }) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    newpassword: "",
    repeatnewpassword: "",
  });

  const [feedback, setFeedback] = useState();
  const [validated, setValidated] = useState(false);

  const onInput = ({ target: { value, name } }) =>
    setValues((oldValues) => ({ ...oldValues, [name]: value }));

  const handleChangePassword = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);
    e.preventDefault();

    const sendData = {
      email: user.email,
      password: values.password,
      newpassword: values.newpassword,
    };

    let formData = new FormData();
    for (var key in sendData) {
      formData.append(key, sendData[key]);
    }

    const response = await fetch(
      "https://depts.washington.edu/adakt/ra-tool/url.php/change",
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.status >= 300) {
      const error = await response.json();
      console.log(error);
      setFeedback({
        heading: "Error changing password!",
        text: "Code " + error.code + ": " + error.message,
        status: error.code,
      });
      return;
    }
    const res = await response;
    const newUser = await res.json();

    if (!newUser.error) {
      setUser({ ...user, apikey: newUser.apikey });
      setToken(newUser.apikey);
    }
  };

  const handleDeleteAccount = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);
    e.preventDefault();

    const response = await fetch(
      "https://depts.washington.edu/adakt/ra-tool/api.php/records/users/" +
        user.ID,
      {
        method: "DELETE",
        headers: new Headers({
          "X-Api-Key": user.apikey,
        }),
      }
    );

    if (response.status >= 300) {
      const error = await response.json();
      console.log(error);
      setFeedback({
        heading: "Error deleting account!",
        text: "Code " + error.code + ": " + error.message,
        status: error.code,
      });
      return;
    }
    const res = await response;
    const userRes = await res.json();
    console.log(userRes);
    setUser();
    alert("Your account has been deleted");
  };

  return !user ? (
    <Navigate to="/signout" />
  ) : (
    <>
      <h2>Settings</h2>
      <Accordion flush>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Change Password</Accordion.Header>
          <Accordion.Body>
            <p>
              To change your password, input your old password and your new
              password twice.
            </p>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleChangePassword}
            >
              <Form.Group className="mb-3">
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  name="password"
                  onChange={(e) => onInput(e)}
                  value={values.password}
                  placeholder="Old Password"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a password.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  name="newpassword"
                  onChange={(e) => onInput(e)}
                  value={values.newpassword}
                  placeholder="New Password"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a password.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Retype Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  name="repeatnewpassword"
                  onChange={(e) => onInput(e)}
                  value={values.repeatnewpassword}
                  placeholder="Retype password"
                  pattern={values.newpassword}
                />
                <Form.Control.Feedback type="invalid">
                  Passwords must match
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                style={{ marginTop: "20px" }}
                variant="primary"
                type="submit"
              >
                Change Password
              </Button>
              {feedback && (
                <Alert
                  style={{ marginTop: "1em" }}
                  variant={feedback.status >= 300 ? "danger" : "success"}
                  onClose={() => setFeedback(null)}
                  dismissible
                >
                  <Alert.Heading>{feedback.heading}</Alert.Heading>
                  <p>{feedback.text}</p>
                </Alert>
              )}
            </Form>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Reset My Data</Accordion.Header>
          <Accordion.Body>
            <Button variant="outline-danger">Reset My Data</Button>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Delete My Account</Accordion.Header>
          <Accordion.Body>
            <Button
              variant="outline-danger"
              onClick={(e) => handleDeleteAccount(e)}
            >
              Delete My Account
            </Button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default Settings;
